import React from "react";
import firebase from "../../../services/firebase";
import HomeInmobiliarias from "../../../client/components/views/HomeInmobiliarias";
import { connect } from "react-redux";
import {
  // saveHotel,
  // saveHotelId,
  // saveLanguage,
} from "../../../store/actions/hotelAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
//import { hideLoader, showLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../../restaurant/components/FullPageLoader/FullPageLoader";

const DB = firebase.db;
const MySwal = withReactContent(Swal);

//let roomsOfHotel;
let hotelInfo;
let control;
//let controlPay;

const mapStateToProps = (state, ownprops) => {
  return {
    hotelId: state.hotel.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
    userLogin: state.user.loginUser,
    isAuth: state.user.isAuth,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    //  saveHotel: (hotel) => dispatch(saveHotel(hotel)),
    //  saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
    // saveLanguage: (HotelId) => dispatch(saveLanguage(HotelId)),
  };
};

class HomeInmobiliariasContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      room: {},
      hotel: {},
      loader: true,
      sizeScreen: 0,
    };
  }

  componentDidMount() {
    // console.log('probando')
    // if (this.props.isAuth) {
    //   this.props.history.replace("/home");
    // }
    //CUANDO SE INICIA EL COMPONENTE:
    //  let HotelId = this.props.match.params.idHotel;
    // 
    //0. preguntar primero por algo para verificar que ya cargo el state.
    // if (this.props.hotelId) {
    // if (this.props.hotelInfoRedux.name) {
    //console.log("llegue despues");
    //1. SI TIENE STATE HOTEL, SOLO MANDA AL STATE LOCAL LA INFO ESA.
    //   this.setState({ hotel: this.props.hotelInfoRedux, loader: false });
    //  } else {
    //console.log("llegue primero");
    //2. SI NO LA VA A BUSCAR Y LA ENVIA AL STATE DE REDUX.
    //    hotelInfo = DB.collection("hoteles").doc(
    //      `${this.props.match.params.idHotel}`
    //    );
    //    hotelInfo.get().then((doc) => {
    //      this.props.saveHotel(doc.data());
    //      this.setState({ hotel: doc.data(), loader: false });
    //    });
    this.setState({ loader: false, sizeScreen: window.screen.width });
    //console.log("size:", window.screen.width);
    // }
    // }
    // hotelInfo.onSnapshot((docSnapshot) => {
    //   this.setState({ hotel: docSnapshot.data(), loader: false });
    // });
    // this.props.dispatch(showLoader());
    // roomsOfHotel = DB.collection("hoteles")
    //   .doc(`${this.props.match.params.idHotel}`)
    //   .collection("rooms");
    //MEJORAR CON UN WHERE
    // roomsOfHotel.onSnapshot((docSnapshot) => {
    //   docSnapshot.forEach((doc) => {
    //     if (doc.id === this.props.match.params.idRoom) {
    //       this.setState({
    //         room: {
    //           clientActual: doc.data().clientActual,
    //           number: doc.data().number,
    //           orderActual: doc.data().orderActual,
    //           secretCode: doc.data().secretCode,
    //           state: doc.data().state,
    //           clean: doc.data().clean,
    //           pay: doc.data().pay,
    //           orderStatus: doc.data().orderStatus,
    //           noDisturb: doc.data().noDisturb,
    //           appInService: doc.data().appInService,
    //           id: doc.id,
    //         },
    //       });
    //     }
    //   });
    // });
    // setTimeout(() => {
    //   this.props.dispatch(hideLoader());
    // }, 500);
    //REVISAR ESTO SI NO ES REPETITIVO
    // controlPay = DB.collection("hoteles")
    //   .doc(this.props.match.params.idHotel)
    //   .collection("rooms")
    //   .doc(this.props.match.params.idRoom);
    // controlPay.get().then((data) => (control = data.data().pay));
  }

  render() {
    return (
      <div>
        <HomeInmobiliarias
          handleClick={this.handleClick}
          room={this.state.room}
          hotel={this.state.hotel}
          propsOfHotelId={this.props.match.params.idHotel}
          propsOfRoomId={this.props.match.params.idRoom}
          loader={this.state.loader}
          whatsapp={this.state.hotel.whatsappNumber}
          screenSize={window.screen.width}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

//export default ClientViewContainer;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeInmobiliariasContainer);
