import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import ScreenHome from "../../../restaurant/components/views/ScreenHome";
import { connect } from "react-redux";
import { userLogout, loginUserClient } from "../../../store/actions/loginAction";
const DB = firebase.db;

const mapStateToProps = (state) => {
    return {
        hotelId: state.user.loginUser.hotelId,
        rol: state.user.loginUser.rol,
        name: state.user.loginUser.name,
        isAuth: state.user.isAuth,
        userLogin: state.user.loginUser.hotelId,
        rooms: state.hotel.rooms,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        logoutFn: () => dispatch(userLogout()),
        // loggeado: (firstName, lastName, rol, liderOf) => dispatch(loginUserClient(firstName, lastName, rol, liderOf)),
    };
};



class ScreenHomeContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(type) {
        if (type === "close") {
            this.props.logoutFn();
            this.props.history.replace("/login");
        }
    }

    render() {
        return (
            <ScreenHome
                loader={this.state.loader}
                name={this.props.name}
                handleClick={this.handleClick}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenHomeContainer);
