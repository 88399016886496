import React from "react";
import { Link } from "react-router-dom";

const styles = {
  container: {
    backgroundColor: "white",
    marginLeft: "250px",
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "20px",
  },
  createMenu: {
    textDecoration: "none",
    color: "#ffffff",
    backgroundColor: "#ff2068",
    borderColor: "#ff2068",
    marginTop: "20px",
    marginBottom: "10px",
  },
};

export default ({ messages, menuObject, deleteFunc, rol }) => {
  return (
    <div style={styles.container}>
      <h1 className="font-weight-bold">Mensajes</h1>

      {rol === "admin" ? (
        <Link
          style={styles.createMenu}
          className="btn btn-primary"
          to="/messages/create"
        >
          Subir nuevo
        </Link>
      ) : (
        <></>
      )}

      <hr />

      <ul
        className="list-group"
        style={{
          marginTop: "30px",
          marginBottom:"100px"
        }}
      >
        {messages ? (
          messages.map((outlet) => {
            return (
              <li
                key={outlet.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <Link
                  style={{
                    display: "flex",
                    flex: 4,
                    marginRight: "30px",
                  }}
                //   to={`/outlets`}
                >

<iframe
                      width="100%"
                      height="100%"
                    //   style={styles.videoIframe}
                      style={{
                        display: "flex",
                        flex: 12,
                        width: "100%",
                      }}
                      src={`${outlet.youtube}`}
                      frameborder="0"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                </Link>
                <Link
                  style={{
                    display: "flex",
                    flex: 8,
                    //fontWeight: "bold",
                  }}
                //   to={`/outlets`}
                >
                  {outlet.title}
                </Link>

                {/* {rol === "admin" ? (
                  <div>
                    <Link to={`/outlet/${outlet.id}/editOutlet`}>
                      <button
                        style={{
                          backgroundColor: "#339AE7",
                          borderColor: "#339AE7",
                          marginRight: "20px",
                        }}
                        className="btn btn-primary btn-lg"
                      >
                        Edit
                      </button>
                    </Link>
                    <button
                      onClick={(e) => deleteFunc(e, outlet.id)}
                      style={{
                        backgroundColor: "#ff2068",
                        borderColor: "#ff2068",
                      }}
                      className="btn btn-primary btn-lg"
                    >
                      Delete
                    </button>
                  </div>
                ) : (
                  <></>
                )} */}
              </li>
            );
          })
        ) : (
          <li>Sin datos.</li>
        )}
      </ul>
    </div>
  );
};
