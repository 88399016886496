import React from "react";
import Sidebar from "../../../restaurant/components/general/Sidebar";
import { connect } from "react-redux";

const mapStateToProps = (state, ownprops) => {
  return {
    hotelInfoRedux: state.hotel.hotelInfo,
  };
};

class SidebarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: {},
    };
  }

  render() {
    return (
      <div>
        <Sidebar hotelInfo={this.props.hotelInfoRedux} />
      </div>
    );
  }
}

export default connect(mapStateToProps)(SidebarContainer);
