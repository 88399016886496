import React from "react";
import { Link } from "react-router-dom";

const styles = {
    container: {
        backgroundColor: "white",
        marginLeft: "250px",
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingTop: "20px",
    },
    createMenu: {
        textDecoration: "none",
        color: "#ffffff",
        backgroundColor: "#ff2068",
        borderColor: "#ff2068",
        marginTop: "20px",
        marginBottom: "10px",
    },
    textLatoBlack: {
        fontFamily: "LatoBlack",
    },
    textLato: {
        fontFamily: "Lato",
    },
};

export default ({ submit, input, actionType, actionState, handleClick }) => {
    return (
        <div style={styles.container}>
            {actionType === "movement" ? (<h1 style={styles.textLatoBlack} className="font-weight-bold">Nuevo Movimiento</h1>) : (<></>)}
            {actionType === "comment" ? (<h1 style={styles.textLatoBlack} className="font-weight-bold">Nuevo Comentario</h1>) : (<></>)}
            {actionType === "item" ? (<h1 style={styles.textLatoBlack} className="font-weight-bold">Nuevo Item</h1>) : (<></>)}
            <hr />
            <form
                onSubmit={(e) => submit(e)}
                style={{
                    margin: "30px",
                }}
            >

                {actionType === "movement" ? (
                    <>
                        <div style={{
                            marginBottom: "60px",
                        }}
                            className="form-group"

                        >
                            <label
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                Acción
                            </label>
                            <div >
                                {actionState === "Ingreso" ? (
                                    <div
                                        className="btn btn-primary"
                                        style={{
                                            backgroundColor: "#FF2068",
                                            borderColor: "#FF2068",
                                            color: "#fff",
                                            width: '180px',
                                            marginRight: "20px"
                                        }}
                                    >
                                        Ingreso
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => handleClick("Ingreso")}
                                        className="btn btn-primary"
                                        style={{
                                            backgroundColor: "#fff",
                                            borderColor: "#FF2068",
                                            color: "#ff2068",
                                            width: '180px',
                                            marginRight: "20px"
                                        }}
                                    >
                                        Ingreso
                                    </div>
                                )}

                                {actionState === "Retiro" ? (
                                    <div
                                        className="btn btn-primary"
                                        style={{
                                            backgroundColor: "#FF2068",
                                            borderColor: "#FF2068",
                                            color: "#fff",
                                            width: '180px'
                                        }}
                                    >
                                        Retiro
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => handleClick("Retiro")}
                                        className="btn btn-primary"
                                        style={{
                                            backgroundColor: "#fff",
                                            borderColor: "#FF2068",
                                            color: "#ff2068",
                                            width: '180px'
                                        }}
                                    >
                                        Retiro
                                    </div>
                                )}

                            </div>
                            <label
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                Comentario
                            </label>
                            <input
                                onChange={(e) => input(e)}
                                name="comments"
                                type="text"
                                className="form-control"
                                placeholder="Ingresar comentario..."
                            />
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                                backgroundColor: "#FF2068",
                                borderColor: "#FF2068",
                                width: '380px'
                            }}
                        >
                            Guardar Movimiento
</button>
                    </>
                ) : (<></>)}

                {actionType === "comment" ? (
                    <>
                        <div
                            className="form-group"
                            style={{
                                paddingBottom: "20px",
                            }}
                        >
                            <label
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                Comentario
</label>
                            <input
                                onChange={(e) => input(e)}
                                name="comments"
                                type="text"
                                className="form-control"
                                placeholder="Ingresar comentario..."
                                required
                            />

                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                                backgroundColor: "#FF2068",
                                borderColor: "#FF2068",
                            }}
                        >
                            Crear Comentario
</button></>
                ) : (<></>)}


                {actionType === "item" ? (
                    <>
                        <div
                            className="form-group"
                            style={{
                                paddingBottom: "20px",
                            }}
                        >
                            <label
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                Nombre del Item
</label>
                            <input
                                onChange={(e) => input(e)}
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Ingresar nombre..."
                                required
                            />
                            <label
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                Descripción
</label>
                            <input
                                onChange={(e) => input(e)}
                                name="description"
                                type="text"
                                className="form-control"
                                placeholder="Ingresar descripción..."
                            />
                            <label
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                Imagen
</label>
                            <input
                                onChange={(e) => input(e)}
                                name="photo"
                                type="text"
                                className="form-control"
                                placeholder="Ingresar URL..."
                            />
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                                backgroundColor: "#FF2068",
                                borderColor: "#FF2068",
                            }}
                        >
                            Crear Item
</button></>
                ) : (<></>)}


            </form>
        </div>
    );
};
