import React from "react";
import { Link } from "react-router-dom";

const styles = {
    container: {
        backgroundColor: "white",
        //  marginLeft: "250px",
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "20px",
    },
    createMenu: {
        textDecoration: "none",
        color: "#ffffff",
        backgroundColor: "#ff2068",
        borderColor: "#ff2068",
        marginTop: "20px",
        marginBottom: "10px",
    },
    textBold: {
        margin: 0,
        display: "flex",
        flex: 4,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },
    text4BoldCenter: {
        margin: 0,
        display: "flex",
        flex: 4,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
        justifyContent: 'center',
        alignItems: 'center'
    },
    text2Bold: {
        margin: 0,
        display: "flex",
        flex: 2,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },
    text2BoldCenter: {
        margin: 0,
        display: "flex",
        flex: 2,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    text3Bold: {
        margin: 0,
        display: "flex",
        flex: 3,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },

    text: {
        margin: 0,
        display: "flex",
        flex: 6,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
    },
    text2: {
        margin: 0,
        display: "flex",
        flex: 6,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
        margin: 0,
    },
    divAbsences: {
        display: "flex",
        flex: 2,
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    text4: {
        margin: 0,
        display: "flex",
        flex: 4,
        fontSize: 20,
        color: "#000",
        fontFamily: "LatoBlack",
        margin: "0px 0px 8px 0px",
    },

    text7: {
        margin: 0,
        display: "flex",
        flex: 4,
        fontSize: 20,
        color: "#000",
        fontFamily: "LatoBold",
    },

    text8: {
        margin: 0,
        display: "flex",
        flex: 6,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
    },

    text9: {
        margin: "0px 0px 10px",
        display: "flex",
        fontSize: 14,
        color: "#000",
        fontFamily: "Lato",
    },

    text10: {
        margin: 0,
        display: "flex",
        fontSize: 20,
        color: "#000",
        fontFamily: "LatoBold",
    },

    text5: {
        display: "flex",
        margin: 0,
        fontSize: 20,
        color: "#fff",
        fontFamily: "LatoBlack",
    },
    titleAnalytic: {
        color: "#000",
        //  fontWeight: "bold",
        textAlign: "center",
        fontSize: "35px",
        fontFamily: "LatoBlack",
    },
    titleDashboard: {
        color: "#000",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "25px",
    },
    descriptionAnalytic: {
        color: "#000",
        textAlign: "center",
        // fontWeight: "400",
        marginBottom: "0px",
        fontFamily: "Lato",
    },
    textLatoBlack: {
        fontFamily: "LatoBlack",
        fontSize: "30px",
        margin: '10px 0px',
        color: "#008477"
    },
    textLato: {
        fontFamily: "Lato",
    },
    textPerson: {
        fontFamily: "Lato",
        fontSize: "18px",
        margin: '10px 0px'
    },
    textLatoBold: {
        fontFamily: "LatoBold",
        fontSize: "25px",
        margin: 0
    },

    textLatoBold2: {
        fontFamily: "LatoBold",
        fontSize: "25px",
        // margin: 0,
        margin: "25px 0px 0px 0px"
    },
    divPhoto: {
        display: "flex",
        flex: 3,
    },
    photo: {
        border: "0.1 px solid #000",
        width: '50px',
    },

    inputComments: {
        margin: '30px 0px',
        fontSize: "18px",
        color: "#000",
        fontFamily: "Lato",
        height: '50px'
    },
};

export default ({ people, rol, handleClick, handlerChange }) => {

    return (
        <>
            {people.brand ? (
                <div style={styles.container}>
                    {people.type === "bag" ? (<h1 style={styles.textLatoBlack}>Bolsa {people.brand}</h1>) : (<h1 style={styles.textLatoBlack}>Carro {people.brand}</h1>)}

                    <h1 style={styles.textPerson}>{`${people.fullName} (${people.numberSocio})`}</h1>

                    <hr />

                    <h1 style={styles.textLatoBold}>Movimientos</h1>
                    <ul
                        className="list-group"
                        style={{
                            margin: "15px 0px 0px 0px"
                        }}
                    >
                        {people.history && people.history.length > 0 ? (

                            people.history.map((person, index) => {
                                if (index < 3) {
                                    return (
                                        <li
                                            className="list-group-item d-flex justify-content-between align-items-center"
                                        >
                                            <h1 style={styles.text7}>{person.actionType}</h1>
                                            <h1 style={styles.text8}>{person.date}</h1>
                                        </li>
                                    );
                                }
                            })
                        ) : (
                            <li
                                className="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <h1 style={styles.text2}>Sin movimientos</h1>
                            </li>
                        )}
                    </ul>
                    <h1 style={styles.textLatoBold2}>Comentarios</h1>
                    <ul
                        className="list-group"
                        style={{
                            margin: "15px 0px 0px 0px"
                        }}
                    >
                        {people.comments && people.comments.length > 0 ? (
                            people.comments.map((person, index) => {
                                if (index < 3) {
                                    return (
                                        <li
                                            className="list-group-item d-flex justify-content-between align-items-center"
                                        >
                                            <div
                                                style={{
                                                    margin: "0px 0px 10px",
                                                    display: "flex",
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <h1 style={styles.text9}>{person.date}</h1>
                                                <h1 style={styles.text10}>{person.comments}</h1>
                                            </div>
                                        </li>
                                    );
                                }
                            })
                        ) : (
                            <li
                                className="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <h1 style={styles.text2}>Sin comentarios</h1>
                            </li>
                        )}
                    </ul>
                    {people.items ? (<h1 style={styles.textLatoBold2}>{`Items (${people.items.length})`}</h1>) : (<h1 style={styles.textLatoBold2}>Items</h1>)}
                    <ul
                        className="list-group"
                        style={{
                            margin: "15px 0px 0px 0px"
                        }}
                    >
                        {people.items ? (
                            people.items.map((person) => {
                                return (
                                    <li
                                        //  key={person.id}
                                        className="list-group-item d-flex justify-content-between align-items-center"
                                        style={{
                                            padding: "12px 10px",
                                            border: 'none'
                                        }}
                                    >
                                        <button
                                            style={{
                                                display: "flex",
                                                flex: 12,
                                                backgroundColor: "white",
                                                textDecoration: "none",
                                                color: "inherit",
                                                border: 'none',
                                                outline: '0',
                                                padding: 0,
                                                alignItems: 'center'
                                            }}
                                        //onClick={() => handleClick(person.id)}
                                        //   to={`/outlets`}
                                        >
                                            <div style={styles.divPhoto}>
                                                {person.photo !== "" ? (<img
                                                    style={styles.photo}
                                                    src={person.photo}
                                                    alt="..."
                                                />) : (
                                                    <img
                                                        style={styles.photo}
                                                        src="https://i.postimg.cc/ZqdN2Pr7/golfstick-green.png"
                                                        alt="..."
                                                    />
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flex: 8,
                                                    flexDirection: 'column'
                                                }}>
                                                <h1 style={styles.text4}>{person.name}</h1>
                                                <h1 style={styles.text2}>{person.description}</h1>
                                            </div>
                                        </button>
                                    </li>
                                );
                            })
                        ) : (
                            <li
                                className="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <h1 style={styles.text2}>Sin items</h1>
                            </li>
                        )}
                    </ul>

                    <input
                        name="comments"
                        type="text"
                        className="form-control"
                        style={styles.inputComments}
                        onChange={handlerChange}
                        placeholder="Agregar comentarios..."
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            margin: '20px 0px 40px'
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                backgroundColor: "#008477",
                                borderRadius: '10px',
                                padding: "15px",
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '47%'
                            }}
                            onClick={() => handleClick("Ingreso")}
                        >
                            <h1 style={styles.text5}>Ingresar</h1>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                backgroundColor: "#008477",
                                borderRadius: '10px',
                                padding: "15px",
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '47%'
                            }}
                            onClick={() => handleClick("Retiro")}
                        >
                            <h1 style={styles.text5}>Retirar</h1>
                        </div>
                    </div>
                </div>
            ) : (<></>)}
        </>
    );
}