import React from "react";

export default ({ submit, input }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        marginLeft: "250px",
        paddingBottom: "400px",
      }}
    >
      <div
        className="container"
        style={{
          paddingLeft: "35px",
          paddingRight: "35px",
          paddingTop: "20px",
        }}
      >
        <h1 className="font-weight-bold">Subir mensaje</h1>
        <div className="col">
          <form
            onSubmit={(e) => submit(e)}
            style={{
              padding: "40px",
              marginRight: "20px",
            }}
          >
            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Título
              </label>
              <input
                onChange={(e) => input(e)}
                name="title"
                type="text"
                className="form-control"
                placeholder="Nombre..."
                required
              />
              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Link de Youtube
              </label>
              <input
                onChange={(e) => input(e)}
                name="youtube"
                type="text"
                className="form-control"
                placeholder="URL"
                required
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              style={{
                backgroundColor: "#FF2068",
                borderColor: "#FF2068",
              }}
            >
              Subir
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
