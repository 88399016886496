import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//const Background =
//  "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Falmuerzo-cover.png?alt=media&token=720f0451-55d2-481b-a401-282fd2d34fcf";

const styles = {
    container: {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: '#white',
        overflow: "auto",
        // flex:"12",
    },
    containerFlex: {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        justifyContent: "center",
        alignItems: "center",
    },


    head: {
        flex: 2,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        //  paddingLeft: "30px",
        backgroundColor: "white",
        borderBottom: "3px solid white",
    },
    // headLink: {
    //     display: "flex",
    //     flexFlow: "column",
    //     justifyContent: "center",
    //     alignItems: "flex-start",
    //     //  paddingLeft: "30px",
    //     backgroundColor: "white",
    //     borderBottom: "3px solid white",
    // },

    headTitles: {
        // flex: 2,
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        //  paddingLeft: "30px",
        backgroundColor: "white",
        borderBottom: "3px solid white",
    },

    title: {
        margin: 0,
        marginTop: 30,
        fontSize: 30,
        // fontWeight: "bold",
        color: "#000",
        // fontSize: 65,
        // fontFamily: "SimpleJoys",
        fontFamily: "LatoBlack",
    },

    subtitle: {
        margin: 0,
        marginRight: 20,
        marginTop: "5px",
        marginBottom: "20px",
        fontSize: 14,
        fontWeight: 400,
        color: "grey",
        //fontSize: 65,
        // fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },
    propertiesContainer: {
        flex: 10,
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "white",
        overflow: "auto",
        marginBottom: "20px"
    },


    propertie: {
        display: "flex",
        flex: 12,
        flexFlow: "column",
        margin: 0,
        width: "100%",
        borderRadius: "25px",
    },



    cover: {
        display: "flex",
        flex: 4,
        margin: 0,
        // maxHeight: "140px",
        height: "140px",
    },



    imageCover: {
        width: "100%",
        borderRadius: "15px",
        objectFit: "cover",
    },


    imageRightNow: {
        //width: "100%",
        borderRadius: "15px",
        objectFit: "cover",
        height: '30px',
        margin: '10px'
    },

    imageCoverWeb: {
        width: "100%",
        // height:"auto",
        borderRadius: "20px",
        objectFit: "cover",
    },

    imagePointersWeb: { margin: 0, height: "25px" },

    imagePointers: { margin: 0, height: "15px" },

    info: {
        display: "flex",
        flex: 4,
        margin: 0,
        width: "100%",
        backgroundColor: "white",
        borderRadius: "25px",
        padding: "5px",
    },

    left: {
        display: "flex",
        flex: 8,
        flexFlow: "column",
        margin: 0,
    },

    right: {
        display: "flex",
        flex: 4,
        flexFlow: "column",
        margin: 0,
        padding: "5px",
        justifyContent: "center",
        alignItems: "flex-end",
    },

    rightWeb: {
        display: "flex",
        flex: 4,
        flexFlow: "column",
        margin: 0,
        padding: "5px",
        justifyContent: "center",
        alignItems: "flex-end",
    },



    numbers: {
        flex: 12,
        display: "flex",
        margin: 0,
        fontSize: 11,
        // fontWeight: "bold",
        color: "#000",
        padding: "5px",
        paddingTop: "15px",
        //fontFamily: "LoewMedium",
        fontFamily: "LatoBlack",
    },

    leftNumbersWeb: {
        flex: 12,
        display: "flex",
        alignItems: "center",
        margin: 0,
        padding: "15px 0 15px 5px",
    },

    leftNumbers: {
        flex: 12,
        display: "flex",
        alignItems: "center",
        margin: 0,
        padding: "5px 0 5px 5px",
    },

    numbersWeb: {
        margin: 0,
        fontSize: 18,
        // fontWeight: "bold",
        color: "#000",
        padding: "0 10px",
        marginRight: "20px",
        //fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },

    numbersCell: {
        margin: 0,
        fontSize: 16,
        //fontWeight: "bold",
        color: "#000",
        padding: "0 10px",
        marginRight: "5px",
        //fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },

    supplierTitle: {
        display: "flex",
        //  flex: 12,
        margin: 0,
        fontSize: 20,
        // color: "grey",
        padding: "10px 5px",
        fontFamily: "LatoBold",
    },

    supplierDescription: {
        display: "flex",
        // flex: 12,
        margin: 0,
        fontSize: 16,
        color: "grey",
        padding: "0px 5px 10px 5px",
        fontFamily: "Lato",
    },

    envivo: {
        flex: 12,
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        margin: 0,
        fontSize: 20,
        color: "#008477",
        padding: "13px",
        // fontFamily: "LoewMedium",
        fontFamily: "LatoBold",
    },

    directionWeb: {
        flex: 12,
        display: "flex",
        margin: 0,
        fontSize: 16,
        color: "grey",
        padding: "5px",
        paddingTop: "5px",
        paddingBottom: "15px",
        // fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },

    price: {
        margin: 0,
        fontSize: 15,
        //fontWeight: "bold",
        color: "#000",
        // fontFamily: "LoewMedium",
        fontFamily: "LatoBlack",

    },

    priceWeb: {
        margin: 0,
        fontSize: 22,
        // fontWeight: "bold",
        color: "#000",
        // fontFamily: "LoewMedium",
        fontFamily: "LatoBold",
    },

    buttonFloat: {
        position: "absolute",
        bottom: 10,
        right: 10,
        display: "flex",
        justifyContent: "flex-end",
        borderRadius: "50px",
    },

    downScreen: {
        display: "flex",
        flex: 1.5,
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor:'rgba(180,180,180)',
        backgroundColor: 'white',
        width: "100%",
    },

    buttonsBox: {
        display: "flex",
        flex: 12,
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: '#339AE7',
        // backgroundColor:'#000',
        height: "100%",
        width: "100%",
        borderRadius: "25px 0 0 0",
    },

    textBotones: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
        margin: '0'
    },


    imageFinal: { margin: 0, height: "35px" },
    textFinal: {
        margin: 0,
        fontSize: 14,
        color: "#fff",
        paddingTop: "15px",
    },

    icons: {
        color: '#000'
    },
    menu: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        fontSize: 30,
        fontFamily: "LatoBold",
        borderRadius: "25px",
        padding: "5px",
        height: "100%",
        width: "100%",
        position: "relative",
        background: "rgba(0, 0, 0, 0.5)",
        color: "#fff",
    },
};

export default ({ restaurants, loader, screenSize }) => {
    return (
        <>
            {loader == false ? (
                <>
                    {screenSize > 800 ? (
                        <>

                        </>
                    ) : (

                        <div style={styles.container}>

                            <div>
                                <div style={styles.head}>

                                    <Link
                                        className="nav-link active"
                                        style={styles.headLink}
                                        to="/home"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="40" height="40" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                        </svg>
                                    </Link>
                                    <div style={styles.headTitles}>
                                        <h1 style={styles.title}>Restaurantes</h1>
                                        <h6 style={styles.subtitle}>
                                            Elige dónde comer.
                </h6>
                                    </div>
                                </div>

                                <div style={styles.propertiesContainer}>

                                    {restaurants
                                        ? restaurants.map((singleRestaurant) => (
                                            <>
                                                {/* <div
                                                    key={singleRestaurant.id}
                                                    style={{
                                                        display: "flex",
                                                        //height: "231px",
                                                        width: "90%",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderRadius: "25px",
                                                        margin: "10px",
                                                        marginTop: "10px",
                                                        marginBottom: "10px",
                                                        //  backgroundColor: "blue",
                                                        textDecoration: "none",
                                                        color: "inherit",
                                                    }}
                                                // to={`/news/${singleNew.id}/`}
                                                >
                                                    <div style={styles.propertie}>
                                                        <div style={styles.cover}>
                                                            <img
                                                                style={styles.imageCover}
                                                                src={singleRestaurant.image}
                                                                alt="..."
                                                            />
                                                        </div>

                                                        <div style={styles.info}>
                                                            <div style={styles.left}>
                                                                <h4 style={styles.supplierTitle}>
                                                                    {`${singleRestaurant.name}`}
                                                                </h4>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div
                                                    key={singleRestaurant.id}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        textAlign: "center",
                                                        height: "160px",
                                                        width: "90%",
                                                        borderRadius: "25px",
                                                        margin: "10px",
                                                        backgroundImage: `url(${singleRestaurant.image})`,
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: "cover",
                                                        textDecoration: "none",
                                                        color: "inherit",
                                                    }}
                                                //  to={`/${idHotel}/${idLanguage}/${outlet.id}/menu`}
                                                //to={`/${idHotel}/menu/${menu.id}/${idRoom}`}
                                                >
                                                    <div style={styles.menu}>
                                                        {singleRestaurant.name}
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                        : null}
                                </div>
                            </div>


                        </div>
                    )}
                </>
            ) : (
                <div style={styles.containerFlex}>
                    <Loader
                        type="TailSpin"
                        //color="#ff2068"
                        color="#008477"
                        height={100}
                        width={100}
                        timeout={10000} //3 secs
                    />
                </div>
            )
            }
        </>
    );
};
