import React from "react";

export default ({ inputs, submit, hotel }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        marginLeft: "250px",
        paddingBottom: "30px",
      }}
    >
      <div
        className="container"
        style={{
          paddingLeft: "35px",
          paddingRight: "35px",
          paddingTop: "20px",
        }}
      >
        <h1 className="font-weight-bold">Edit Configurations</h1>
        <div className="col">
          <form
            onSubmit={(e) => submit(e)}
            style={{
              padding: "40px",
              marginRight: "20px",
            }}
          >
            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Name</label>
              <input
                onChange={(e) => inputs(e)}
                name="name"
                type="text"
                className="form-control"
                placeholder="Restaurant name..."
                required
                value={hotel.name}
              />
            </div>
            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Email</label>
              <input
                onChange={(e) => inputs(e)}
                name="mail"
                type="email"
                className="form-control"
                placeholder="hi@email.com"
                required
                value={hotel.mail}
              />
            </div>
            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Phone</label>
              <input
                onChange={(e) => inputs(e)}
                name="phone"
                type="number"
                className="form-control"
                placeholder="+54 305 ..."
                required
                value={hotel.phone}
              />
            </div>

            {hotel.solutionWhatsapp === true ? (
              <div
                className="form-group"
                style={{
                  paddingBottom: "20px",
                }}
              >
                <label>WhatsApp Number</label>
                <input
                  onChange={(e) => inputs(e)}
                  name="whatsappNumber"
                  type="text"
                  className="form-control"
                  placeholder="54 305 ..."
                  required
                  value={hotel.whatsappNumber}
                />
              </div>
            ) : (
              <></>
            )}

            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Logo Image</label>
              <input
                onChange={(e) => inputs(e)}
                name="logoImage"
                type="text"
                className="form-control"
                placeholder="https://..."
                required
                value={hotel.logoImage}
              />
            </div>
            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Background Image</label>
              <input
                onChange={(e) => inputs(e)}
                name="backgroundImage"
                type="text"
                className="form-control"
                placeholder="ttps://..."
                required
                value={hotel.backgroundImage}
              />
            </div>

            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Color</label>
              <input
                onChange={(e) => inputs(e)}
                name="primaryColor"
                type="text"
                className="form-control"
                placeholder="#ff2068"
                required
                value={hotel.primaryColor}
              />
            </div>

            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Title App</label>
              <input
                onChange={(e) => inputs(e)}
                name="titleApp"
                type="text"
                className="form-control"
                required
                value={hotel.titleApp}
              />
            </div>

            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Subtitle App</label>
              <input
                onChange={(e) => inputs(e)}
                name="subtitleApp"
                type="text"
                className="form-control"
                required
                value={hotel.subtitleApp}
              />
            </div>

            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Marketing Info</label>
              <input
                onChange={(e) => inputs(e)}
                name="infoApp"
                type="text"
                className="form-control"
                required
                value={hotel.infoApp}
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              style={{
                backgroundColor: "#FF2068",
                borderColor: "#FF2068",
              }}
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
