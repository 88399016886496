import React from "react";
import Profile from "../../components/views/Profile";
import firebase from "../../../services/firebase";
import { connect } from "react-redux";
import { userLogout } from "../../../store/actions/loginAction";

const DB = firebase.db;



const mapStateToProps = (state) => {
  return {
    userLogin: state.user.loginUser,
    // hotelId: state.user.loginUser.hotelId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // saveHotel: (hotel) => dispatch(saveHotel(hotel)),
    // saveRooms: (rooms) => dispatch(saveRooms(rooms)),
    logoutFn: () => dispatch(userLogout()),
  };
};




class ProfileContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      messages: [],
      loader: true,

      oneAction: false,
      user: {},
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({
      loader: false,
    });
  }

  componentDidUpdate() {
    if (this.state.oneAction === false) {
      if (this.props.userLogin.firstNameRedux) {
        this.setState({ user: this.props.userLogin, oneAction: true, })
      }
    }
  }



  handleClick(e) {
    this.props.logoutFn();
    this.props.history.replace("/");
  }

  render() {
    return (
      <div>
        <Profile
          handleClick={this.handleClick}
          messages={this.state.messages}
          loader={this.state.loader}
          screenSize={window.screen.width}
          user={this.state.user}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
