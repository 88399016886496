import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import SimpleBagPhone from "../../components/views/SimpleBagPhone";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import {
    saveHotelId,
} from "../../../store/actions/hotelAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const DB = firebase.db;


const mapStateToProps = (state) => {
    return {
        hotelId: state.user.loginUser.hotelId,
        rol: state.user.loginUser.rol,
        isAuth: state.user.isAuth,
        userLogin: state.user.loginUser.hotelId,
        absences: state.hotel.absences,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
        saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
    };
};

class SimpleBagPhoneContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hotel: {},
            people: {},
            loader: true,
            oneAction: false,
            lideresCant: [],
            actualPending: "",
            comments: ""
        };
        this.handleClick = this.handleClick.bind(this);
        this.handlerChange = this.handlerChange.bind(this);
    }

    componentDidMount() {

        let dateTotal = new Date().getTime()

        Swal.fire({
            title: 'Ingresar código',
            //  text: "Envianos tu agradecimiento para disfrutar con vos.",
            input: 'text',
            showCloseButton: true,
            confirmButtonColor: "#008477",
            confirmButtonText: "Enviar",
        }).then((result) => {
            if (result.value === "1234") {

                let simpleBagDoc = DB.collection("countrys")
                    .doc(`lVGwoAyzUnN1ygmvEE3T`).collection("golfbags").doc(`${this.props.match.params.idBag}`)

                simpleBagDoc.get().then((simpleBag) => {
                    this.setState({ people: simpleBag.data() }, () => {
                        DB.collection("countrys")
                            .doc(`lVGwoAyzUnN1ygmvEE3T`).collection("moveBags").add({
                                idBag: this.props.match.params.idBag,
                                realDate: dateTotal,
                                brand: this.state.people.brand,
                                type: this.state.people.type,
                                fullName: this.state.people.fullName,
                                numberSocio: this.state.people.numberSocio,
                                status: "pending",
                                personAdmin: "Administrador",
                            }).then((docRef) => {
                                this.setState({ actualPending: docRef.id })
                            })
                    });
                });
            }
        })
    }


    handleClick(action) {

        this.props.showLoader();

        let year = new Date().getFullYear()
        let month = new Date().getMonth() + 1
        const date = new Date().getDate()
        let hours = new Date().getHours()
        let minutes = new Date().getMinutes()
        let dateAbsences = (date + "/" + month + "/" + year + " " + hours + ":" + minutes)
        let dateTotal = new Date().getTime()

        let singleRquestToDelete = DB.collection("countrys")
            .doc(`lVGwoAyzUnN1ygmvEE3T`).collection("moveBags").doc(`${this.state.actualPending}`)
        singleRquestToDelete.delete()

        let singleHistoryToUpdate = DB.collection("countrys")
            .doc(`lVGwoAyzUnN1ygmvEE3T`).collection("moveBagsHistory").doc("vZSnYrYxnycxb5n8Etk0")
        singleHistoryToUpdate.get().then((simpleBag) => {
            let historyArrayBags = simpleBag.data().history
            let historyArrayBagsToPush = historyArrayBags.slice()
            if (historyArrayBagsToPush.length > 50) {
                historyArrayBagsToPush.unshift({
                    date: dateAbsences,
                    actionType: action,
                    comments: this.state.comments,
                    fullName: this.state.people.fullName,
                    idBag: this.props.match.params.idBag,
                })
                historyArrayBagsToPush.pop()
            } else {
                historyArrayBagsToPush.unshift({
                    date: dateAbsences,
                    actionType: action,
                    comments: this.state.comments,
                    fullName: this.state.people.fullName,
                    idBag: this.props.match.params.idBag,
                })
            }
            singleHistoryToUpdate.update({ history: historyArrayBagsToPush })
        })

        let singleBagToUpdate = DB.collection("countrys")
            .doc(`lVGwoAyzUnN1ygmvEE3T`).collection("golfbags").doc(`${this.props.match.params.idBag}`)
        singleBagToUpdate.get().then((simpleBag) => {
            if (this.state.comments !== "" && this.state.comments !== " " && this.state.comments !== "  ") {
                let commentsArray = simpleBag.data().comments
                let commentsArrayToPush = commentsArray.slice()
                if (commentsArrayToPush.length > 4) {
                    commentsArrayToPush.unshift({
                        date: dateAbsences,
                        comments: this.state.comments,
                    })
                    commentsArrayToPush.pop()
                } else {
                    commentsArrayToPush.unshift({
                        date: dateAbsences,
                        comments: this.state.comments,
                    })
                }
                singleBagToUpdate.update({ comments: commentsArrayToPush })
            }

            let historyArray = simpleBag.data().history
            let historyArrayToPush = historyArray.slice()
            if (historyArrayToPush.length > 4) {
                historyArrayToPush.unshift({
                    date: dateAbsences,
                    actionType: action,
                })
                historyArrayToPush.pop()
            } else {
                historyArrayToPush.unshift({
                    date: dateAbsences,
                    actionType: action,
                })
            }
            singleBagToUpdate.update({ history: historyArrayToPush }).then((creado) => {
                this.props.hideLoader();
                this.props.history.push(`/home`);
                firebase.succesfullMsg("Movimiento registrado!");
            });

        });

    }

    handlerChange(e) {
        this.setState({ comments: e.target.value })
    }

    render() {
        return (
            <SimpleBagPhone
                people={this.state.people}
                handleClick={this.handleClick}
                handlerChange={this.handlerChange}
                rol={this.props.rol}
                lideresCant={this.state.lideresCant}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleBagPhoneContainer);
