import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import News from "../../../restaurant/components/views/News";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const DB = firebase.db;

const mapStateToProps = (state) => {
  return {
    hotelId: state.user.loginUser.hotelId,
    rol: state.user.loginUser.rol,
    isAuth: state.user.isAuth,
    userLogin: state.user.loginUser.hotelId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

class NewsAdminContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: {},
      newsArray: [],
      idNewsArray: ""
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.props.showLoader()
    if (this.props.hotelId) {
      let newsGeneral = DB.collection("countrys")
        .doc(`${this.props.hotelId}`)
        .collection("news")

      newsGeneral.onSnapshot((peopleBack) => {
        let peopleArray = [];
        peopleBack.forEach((people) => {
          peopleArray = people.data().news
          this.setState({ idNewsArray: people.id })
        });
        this.setState({
          newsArray: peopleArray,
        }, () => this.props.hideLoader());
      })

      // newsGeneral.get().then((peopleBack) => {
      //   let peopleArray = [];
      //   peopleBack.forEach((people) => {
      //     peopleArray = people.data().news
      //     this.setState({ idNewsArray: people.id })
      //   });
      //   this.setState({
      //     newsArray: peopleArray,
      //   }, () => this.props.hideLoader());
      // })
    }

  }

  handleClick(actionType, info1, info2) {

    MySwal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción no se puede revertir.",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        if (actionType === "news") {
          let arrayOfItems = this.state.newsArray;
          let found = arrayOfItems.findIndex(
            (element) =>
              element.title === info1 &&
              element.description === info2
          );
          if (found !== -1) {
            if (this.props.hotelId) {
              let newsDoc = DB.collection("countrys")
                .doc(`${this.props.hotelId}`)
                .collection("news").doc(this.state.idNewsArray)
              let newsArrayFromState = this.state.newsArray
              newsArrayFromState.splice(found, 1);
              newsDoc.update({ news: newsArrayFromState })
            }
          }
        }
      }
    })
  }

  render() {
    return (
      <div>
        <Sidebar />
        <News
          news={this.state.newsArray}
          handleClick={this.handleClick}
          rol={this.props.rol}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsAdminContainer);
