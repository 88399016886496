import React from "react";

export default ({ submit, input }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        marginLeft: "250px",
        paddingBottom: "400px",
      }}
    >
      <div
        className="container"
        style={{
          paddingLeft: "35px",
          paddingRight: "35px",
          paddingTop: "20px",
        }}
      >
        <h1 className="font-weight-bold">Crear Propiedad</h1>
        <div className="col">
          <form
            onSubmit={(e) => submit(e)}
            style={{
              padding: "40px",
              marginRight: "20px",
            }}
          >
            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <h3 className="font-weight-bold">General</h3>

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Operación
              </label>
              <input
                onChange={(e) => input(e)}
                name="operation"
                type="text"
                className="form-control"
                placeholder="Venta o Alquiler"
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Precio
              </label>
              <input
                onChange={(e) => input(e)}
                name="price"
                type="text"
                className="form-control"
                placeholder="u$S 215.000"
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Tipo
              </label>
              <input
                onChange={(e) => input(e)}
                name="type"
                type="text"
                className="form-control"
                placeholder="Casa, Departamento, Oficina, Lote o Cochera"
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Condición
              </label>
              <input
                onChange={(e) => input(e)}
                name="condition"
                type="text"
                className="form-control"
                placeholder="Nuevo o Usado"
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Etiqueta
              </label>
              <input
                onChange={(e) => input(e)}
                name="label"
                type="text"
                className="form-control"
                placeholder="Nuevo, Oportunidad, etc."
              />

              <h3
                style={{
                  marginTop: "30px",
                }}
                className="font-weight-bold"
              >
                Descripción
              </h3>
              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Dirección
              </label>
              <input
                onChange={(e) => input(e)}
                name="direction"
                type="text"
                className="form-control"
                placeholder="Av. Segurola 4310"
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Ciudad
              </label>
              <input
                onChange={(e) => input(e)}
                name="city"
                type="text"
                className="form-control"
                placeholder="Villa Devoto, Capital Federal"
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Mt2 Totales
              </label>
              <input
                onChange={(e) => input(e)}
                name="mtTotales"
                type="text"
                className="form-control"
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Mt2 Cubiertos
              </label>
              <input
                onChange={(e) => input(e)}
                name="mtCubiertos"
                type="text"
                className="form-control"
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Ambientes
              </label>
              <input
                onChange={(e) => input(e)}
                name="ambientes"
                type="text"
                className="form-control"
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Habitaciones
              </label>
              <input
                onChange={(e) => input(e)}
                name="bedrooms"
                type="text"
                className="form-control"
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Baños
              </label>
              <input
                onChange={(e) => input(e)}
                name="bathrooms"
                type="text"
                className="form-control"
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Bauleras
              </label>
              <input
                onChange={(e) => input(e)}
                name="baulera"
                type="text"
                className="form-control"
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Cocheras
              </label>
              <input
                onChange={(e) => input(e)}
                name="car"
                type="text"
                className="form-control"
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Cocinas
              </label>
              <input
                onChange={(e) => input(e)}
                name="kitchen"
                type="text"
                className="form-control"
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Salas de Reunión
              </label>
              <input
                onChange={(e) => input(e)}
                name="meet"
                type="text"
                className="form-control"
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Oficinas
              </label>
              <input
                onChange={(e) => input(e)}
                name="office"
                type="text"
                className="form-control"
              />

              <h3
                style={{
                  marginTop: "30px",
                }}
                className="font-weight-bold"
              >
                Imagenes & Videos
              </h3>
              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Imagen de Portada
              </label>
              <input
                onChange={(e) => input(e)}
                name="cover"
                type="text"
                className="form-control"
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Imagen de Listado
              </label>
              <input
                onChange={(e) => input(e)}
                name="coverList"
                type="text"
                className="form-control"
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                URL 360
              </label>
              <input
                onChange={(e) => input(e)}
                name="url360"
                type="text"
                className="form-control"
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                URL Video
              </label>
              <input
                onChange={(e) => input(e)}
                name="youtubeURL"
                type="text"
                className="form-control"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              style={{
                backgroundColor: "#FF2068",
                borderColor: "#FF2068",
              }}
            >
              Crear
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
