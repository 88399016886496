import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const styles = {
  container: {
    backgroundColor: "white",
    marginLeft: "250px",
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "20px",
  },
  containerFlex: {
    marginLeft: "250px",
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  titleAnalytic: {
    color: "#000",
    //fontWeight: "bold",
    textAlign: "center",
    fontSize: "35px",
    fontFamily: "LatoBlack",
  },
  titleDashboard: {
    color: "#000",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "25px",
    margin: 0,
  },
  descriptionAnalytic: {
    color: "#000",
    textAlign: "center",
    //fontWeight: "400",
    marginBottom: "0px",
    fontFamily: "Lato",
  },
  shadow: {
    boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },
  textLatoBlack: {
    fontFamily: "LatoBlack",
  },
  textLato: {
    fontFamily: "Lato",
  },
  card2: {
    margin: '0px'
  }
};

export default ({
  hotel,
  rol,
  loader,
  hotelTotalMenues,
  name,
  hotelTotalCategories,
  hotelTotalProducts,
  hotelTotalDisableProducts,
  people,
  lideresCant,
  bags,
  bagsNoActive
}) => {
  let productsWithStock = hotelTotalProducts - hotelTotalDisableProducts;
  //console.log(hoteles);
  return (
    <>
      <div style={styles.container}>
        <h1 style={styles.textLatoBlack} className="font-weight-bold">{`Hola ${name}!`}</h1>
        <h6 style={styles.textLato} className="font-weight-light">{`Esperamos que tengas un buen día. Y gracias por todo lo que haces!`}</h6>
        {/* <Link
          style={{
            textDecoration: "none",
            color: "#ffffff",
            backgroundColor: "#ff2068",
            borderColor: "#ff2068",
            marginTop: "20px",
            marginBottom: "10px",
          }}
          className="btn btn-primary"
          to="/create/propertie"
        >
          Crear Propiedad
        </Link> */}
        <hr style={{
          marginBottom: "30px",
          // marginBottom: "20px",
        }} />

        <div
          className="row row-cols-1 row-cols-md-3"
        >
          <div className="col mb-4">
            <div className="card h-100" style={styles.shadow}>
              <img
                src="https://i.postimg.cc/zGK7LWVk/dashboard-person.png"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 style={styles.titleAnalytic}>{people.length}</h5>
                <p style={styles.descriptionAnalytic}>Personas Totales</p>
              </div>
            </div>
          </div>
          <div className="col mb-4" style={styles.card2}>
            <div className="card h-100" style={styles.shadow}>
              <img
                src="https://i.postimg.cc/QCC8gtFV/dashboard-person.png"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 style={styles.titleAnalytic}>{lideresCant.length}</h5>

                <p style={styles.descriptionAnalytic}>Personas Activas</p>
              </div>
            </div>
          </div>
          <div className="col mb-4" style={styles.card2}>
            <div className="card h-100" style={styles.shadow}>
              <img
                src="https://i.postimg.cc/66b5WdPZ/dashboard-noperson.png"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 style={styles.titleAnalytic}>{people.length - lideresCant.length}</h5>

                <p style={styles.descriptionAnalytic}>Personas Inactivas</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row row-cols-1 row-cols-md-3"
          style={{
            marginTop: "10px"
          }}
        >
          <div className="col mb-4" style={styles.card2}>
            <div className="card h-100" style={styles.shadow}>
              <img
                src="https://i.postimg.cc/T2DH1x7c/dashboard-bags.png"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 style={styles.titleAnalytic}>{bags.length}</h5>
                <p style={styles.descriptionAnalytic}>Bolsos Totales</p>
              </div>
            </div>
          </div>
          <div className="col mb-4" style={styles.card2}>
            <div className="card h-100" style={styles.shadow}>
              <img
                src="https://i.postimg.cc/cH8Tv7mJ/dashboard-bag-png.png"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 style={styles.titleAnalytic}>{bagsNoActive.length}</h5>

                <p style={styles.descriptionAnalytic}>Bolsos Activos</p>
              </div>
            </div>
          </div>
          <div className="col mb-4" style={styles.card2}>
            <div className="card h-100" style={styles.shadow}>
              <img
                src="https://i.postimg.cc/pLGBkccT/dashboard-nobag.png"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 style={styles.titleAnalytic}>{bags.length - bagsNoActive.length}</h5>

                <p style={styles.descriptionAnalytic}>Bolsos Inactivos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
