import React from "react";
import Wallpapers from "../../components/views/Wallpapers";
import firebase from "../../../services/firebase";
import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
const DB = firebase.db;
//const MySwal = withReactContent(Swal);

class MessagesContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            wallpapers: [],
            peopleToContact: [],
            loader: true,

            selectedImage: "",
            popUp: false,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        let countryDoc = DB.collection("countrys")
            .doc("lVGwoAyzUnN1ygmvEE3T")
            .collection("maps")
            .doc("nNhOBWD95wFKMKAqVczT")
        //    .orderBy("order", "desc");
        countryDoc.get()
            .then((screenshot) => {
                this.setState({
                    wallpapers: screenshot.data().maps,
                    loader: false,
                });
            })

    }




    // handleClick(e) {

    //     Swal.fire({
    //         // title: `Descarga`,
    //         showConfirmButton: true,
    //         confirmButtonText: `Descargar`,
    //         reverseButtons: true,
    //         showCloseButton: true,
    //         imageUrl: `${e}`,
    //         imageWidth: "100%",
    //         // imageHeight: 450,
    //         imageAlt: 'image',

    //     }).then((result) => {

    //         if (result.value) {
    //             return (<a href={e + "?force=true"}>Download</a>
    //             )


    //         }
    //     })


    // }


    handleClick(e, categImage) {
        e.preventDefault();
        if (categImage === "close") {
            this.setState({ selectedImage: "", popUp: false });
        } else {
            this.setState({ selectedImage: categImage, popUp: true });
        }
    }

    render() {
        return (
            <div>
                <Wallpapers
                    wallpapers={this.state.wallpapers}
                    loader={this.state.loader}
                    screenSize={window.screen.width}
                    handleClick={this.handleClick}
                    popUp={this.state.popUp}
                    selectedImage={this.state.selectedImage}
                />
            </div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(PropiedadesCliente);

export default MessagesContainer;
