import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Hotel from "../../../restaurant/components/create/Hotel";
const DB = firebase.db;

class CreateHotelContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      operation: "",
      price: "",
      type: "",
      condition: "",
      label: "",
      date: "",

      direction: "",
      city: "",
      mtTotales: "",
      mtCubiertos: "",

      ambientes: "",
      bedrooms: "",
      bathrooms: "",
      baulera: "",
      car: "",
      kitchen: "",
      meet: "",
      office: "",

      cover: "",
      coverList: "",
      images: [],
      url360: "",
      youtubeURL: "",

      description: "",
      active: true,
      order: "1",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    let doc = DB.collection("properties").doc();

    doc.set(this.state);

    firebase.succesfullMsg("Propertie successfully created!");
    this.props.history.push("/dashboard");
  }

  handleInput(e) {
    let key = e.target.name;
    let input = e.target.value;
    this.setState({
      [key]: input,
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Hotel submit={this.handleSubmit} input={this.handleInput} />
      </div>
    );
  }
}

export default CreateHotelContainer;
