import React from "react";
import firebase from "../../../services/firebase";
import ScreenNews from "../../../restaurant/components/views/ScreenNews";
import { connect } from "react-redux";
import {
    saveHotelId,
} from "../../../store/actions/hotelAction";
const DB = firebase.db;

const mapStateToProps = (state) => {
    return {
        hotelId: state.user.loginUser.hotelId,
        rol: state.user.loginUser.rol,
        name: state.user.loginUser.name,
        isAuth: state.user.isAuth,
        userLogin: state.user.loginUser.hotelId,
        rooms: state.hotel.rooms,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
    };
};


class ScreenNewsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            newsArray: [],
        };
    }

    componentDidMount() {
        if (this.props.hotelId) {
            let newsDoc = DB.collection("countrys").doc(`${this.props.hotelId}`).collection("news")
            newsDoc.onSnapshot((peopleBack) => {
                peopleBack.forEach((people) => {
                    this.setState({ newsArray: people.data().news })
                });
            })
        }
    }

    render() {
        return (
            <ScreenNews
                loader={this.state.loader}
                name={this.props.name}
                news={this.state.newsArray}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenNewsContainer);
