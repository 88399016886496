import React from "react";
import axios from "axios";
import firebase from "../../../services/firebase";
import SingleRoom from "../../components/views/SingleRoom";
import Sidebar from "../general/Sidebar";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
//import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import { connect } from "react-redux";

const DB = firebase.db;

let singleRoom;
let orderQuery;
let productsTable;
//let tableId;
let orderId;

//REVISAR ESTO
// let local = JSON.parse(window.localStorage.getItem("persist:lovableLogin"));
// let userLS;
// if (local) {
//   userLS = JSON.parse(local.user);
// }
//HASTA ACA

const mapStateToProps = (state) => {
  return {
    userLogin: state.user.loginUser.hotelId,
    isAuth: state.user.isAuth,
  };
};

class SingleRoomContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      room: {},
      tableOrder: {},
      productArray: [],
      order: 0,
    };
    this.handlerButton = this.handlerButton.bind(this);
    this.orderHandler = this.orderHandler.bind(this);
    this.roomHandler = this.roomHandler.bind(this);
  }

  componentDidMount() {
    //REVISAR ESTO
    // if (userLS.isAuth === false) {
    //   this.props.history.push("/");
    // } else {
    //REVISAR ESTO
    if (this.props.userLogin) {
      // this.props.dispatch(showLoader());
      singleRoom = DB.collection("hoteles")
        .doc(`${this.props.userLogin}`)
        .collection("rooms")
        .doc(this.props.match.params.idTable);
      singleRoom.onSnapshot((roomDoc) => {
        this.setState({
          room: {
            clientActual: roomDoc.data().clientActual,
            number: roomDoc.data().number,
            orderActual: roomDoc.data().orderActual,
            secretCode: roomDoc.data().secretCode,
            state: roomDoc.data().state,
            clean: roomDoc.data().clean,
            pay: roomDoc.data().pay,
            orderStatus: roomDoc.data().orderStatus,
            noDisturb: roomDoc.data().noDisturb,
            id: roomDoc.id,
          },
        });

        if (roomDoc.data().orderActual !== 0) {
          orderId = roomDoc.data().orderActual.toString();
          this.setState({ order: orderId });
          orderQuery = DB.collection("hoteles")
            .doc(`${this.props.userLogin}`)
            .collection("orders")
            .doc(orderId);
          orderQuery.onSnapshot((docSnapshot) => {
            this.setState({
              tableOrder: {
                status: docSnapshot.data().status,
                totalPrice: docSnapshot.data().totalPrice,
                mail: docSnapshot.data().mail,
              },
            });
            productsTable = orderQuery.collection("products");
            productsTable.onSnapshot((prodDoc) => {
              let arrayHelper = [];
              prodDoc.forEach((singleProd) => {
                arrayHelper.push({
                  comments: singleProd.data().comments,
                  name: singleProd.data().name,
                  price: singleProd.data().price,
                  quantity: singleProd.data().quantity,
                });
              });
              this.setState({
                productArray: arrayHelper,
              });
            });
          });
        }
      });
      // setTimeout(() => {
      //   this.props.dispatch(hideLoader());
      // }, 500);
    }
  }

  orderHandler(e, id, string, numTable) {
    e.preventDefault();
    let stringy = id.toString();
    let doc = DB.collection("hoteles")
      .doc(`${this.props.userLogin}`)
      .collection("orders")
      .doc(stringy);
    doc.update({ status: string });

    //REVISAR PARA AHORRAR PERFORMANCE Y AGREGAR DESPUES

    // let tableDoc = DB.collection("hoteles")
    //   .doc(this.props.userLogin)
    //   .collection("rooms");

    // tableDoc
    //   .get()
    //   .then((data) => {
    //     let mesaID;
    //     data.forEach((res) => {
    //       if (res.data().number === numTable) {
    //         mesaID = res.id;
    //       }
    //     });
    //     return mesaID;
    //   })
    //   .then((mesaID) => {
    //     let idTable = DB.collection("hoteles")
    //       .doc(this.props.userLogin)
    //       .collection("rooms")
    //       .doc(mesaID);

    //     idTable.update({ orderStatus: "accepted" });
    //   });
    // firebase.succesfullMsg(`Order ${string}`);
  }

  roomHandler(e, id, string) {
    e.preventDefault();
    let roomChange = DB.collection("hoteles")
      .doc(`${this.props.userLogin}`)
      .collection("rooms")
      .doc(id);
    if (string === "clean") {
      roomChange.update({
        clean: false,
      });
    } else {
      roomChange.update({
        pay: false,
      });
    }
  }

  handlerButton(e, string) {
    e.preventDefault();
    let SingleRoom = DB.collection("hoteles")
      .doc(`${this.props.userLogin}`)
      .collection("rooms")
      .doc(this.props.match.params.idTable);
    if (orderId) {
      let orderChange = DB.collection("hoteles")
        .doc(`${this.props.userLogin}`)
        .collection("orders")
        .doc(orderId);
      SingleRoom.get().then((table) => {
        if (table.data().mail) {
          orderChange.update({ status: string, mail: table.data().mail });
          axios({
            headers: { "Access-Control-Allow-Origin": "*" },
            method: "post",
            data: { mail: table.data().mail },
            //url: "http://localhost:5000/lovable-qr/us-central1/app/api/mail",
            url: "https://lovable-qr.web.app/api/mail",
          })
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
        } else {
          orderChange.update({ status: string });
        }
      });
    } else {
      SingleRoom.get().then((table) => {
        if (table.data().mail) {
          axios({
            headers: { "Access-Control-Allow-Origin": "*" },
            method: "post",
            data: { mail: table.data().mail },
            // url: "http://localhost:5000/lovable-qr/us-central1/app/api/mail",
            url: "https://lovable-qr.web.app/api/mail",
          })
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
        }
      });
    }
    SingleRoom.update({
      clientActual: 0,
      orderActual: 0,
      orderStatus: "",
      secretCode: 0,
      state: "free",
      pay: false,
      clean: false,
      noDisturb: false,
      mail: "",
    });
    this.props.history.push(`/dashboard`);
  }

  render() {
    return (
      <div>
        <Sidebar />
        <SingleRoom
          room={this.state.room}
          order={this.state.tableOrder}
          orderId={this.state.order}
          productArray={this.state.productArray}
          buttonClick={this.handlerButton}
          orderHandler={this.orderHandler}
          roomHandler={this.roomHandler}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SingleRoomContainer);
