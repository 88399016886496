import React from "react";
import { Link } from "react-router-dom";

const styles = {
  container: {
    backgroundColor: "white",
    marginLeft: "250px",
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "20px",
  },
  createMenu: {
    textDecoration: "none",
    color: "#ffffff",
    backgroundColor: "#ff2068",
    borderColor: "#ff2068",
    marginTop: "20px",
    marginBottom: "10px",
  },
  textBold: {
    margin: 0,
    display: "flex",
    flex: 4,
    fontSize: 18,
    color: "#000",
    fontFamily: "LatoBold",
  },
  text: {
    margin: 0,
    display: "flex",
    flex: 12,
    fontSize: 16,
    color: "#000",
    fontFamily: "Lato",
  },
};

export default ({ absences }) => {
  return (
    <div style={styles.container}>
      <h1 className="font-weight-bold">Inasistencias</h1>

      <hr />

      {absences ? (
        absences.map((absence) => {
          return (
            <ul
              className="list-group"
              style={{
                marginTop: "30px",
              }}
            >
              <li
                key={absence.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <div
                  style={{
                    display: "flex",
                    flex: 12,
                  }}
                >
                  <h1 style={styles.textBold}>{absence.date}</h1>
                  <h1 style={styles.textBold}>Lider: {absence.lider}</h1>
                  <h1 style={styles.textBold}>Clase: {absence.class}</h1>
                </div>
              </li>
              {absence.personsNo ? (
                absence.personsNo.map((person) => {
                  return (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <h1 style={styles.text}>{person.fullName}</h1>
                    </li>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
          );
        })
      ) : (
        <></>
      )}

    </div>
  );
};
