import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default ({ buttonClick, rooms, isOpen, dropdown }) => {
  const menuClass = `dropdown-menu${dropdown ? " show" : ""}`;
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
          </ul>
          <div className="form-inline my-2 my-lg-0">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#ff2068",
                    borderColor: "#ff2068",
                  }}
                  onClick={(e) => buttonClick(e)}
                >
                  Cerrar sesión
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
