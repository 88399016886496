import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Menu from "../../../restaurant/components/edit/Menu";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
const DB = firebase.db;
// let local = JSON.parse(window.localStorage.getItem("persist:lovableLogin"));
// let userLS;
// if (local) {
//   userLS = JSON.parse(local.user);
// }

const mapStateToProps = (state) => {
  return {
    hotelId: state.user.loginUser.hotelId,
    isAuth: state.user.isAuth,
  };
};

class EditMenuContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameOfMenu: "",

      nameOfMenuSpanish: "",
      nameOfMenuEnglish: "",
      nameOfMenuPortuguese: "",
      nameOfMenuFrench: "",

      imageMenu: "",
      hours: "",
      orderMenu: 1,

      aclarationSpanish: "",
      aclarationEnglish: "",
      aclarationPortuguese: "",
      aclarationFrench: "",
    };
    this.handleInputs = this.handleInputs.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  menuId = this.props.match.params.id;
  componentDidMount() {
    if (this.props.hotelId) {
      this.props.dispatch(showLoader());
      let doc = DB.collection("hoteles")
        .doc(`${this.props.hotelId}`)
        .collection("menues")
        .doc(this.menuId);
      doc.get().then((menu) => {
        this.setState({
          nameOfMenu: menu.data().nameOfMenu,

          nameOfMenuSpanish: menu.data().nameOfMenuSpanish,
          nameOfMenuEnglish: menu.data().nameOfMenuEnglish,
          nameOfMenuPortuguese: menu.data().nameOfMenuPortuguese,
          nameOfMenuFrench: menu.data().nameOfMenuFrench,

          imageMenu: menu.data().imageMenu,
          hours: menu.data().hours,
          orderMenu: menu.data().orderMenu,

          aclarationSpanish: menu.data().aclarationSpanish,
          aclarationEnglish: menu.data().aclarationEnglish,
          aclarationPortuguese: menu.data().aclarationPortuguese,
          aclarationFrench: menu.data().aclarationFrench,
        });
        setTimeout(() => {
          this.props.dispatch(hideLoader());
        });
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.dispatch(showLoader());
    let doc = DB.collection("hoteles")
      .doc(`${this.props.hotelId}`)
      .collection("menues")
      .doc(this.menuId);
    doc.update(this.state).then((actualizado) => {
      this.props.dispatch(hideLoader());

      this.props.history.push("/menu");
      firebase.succesfullMsg("Menu updated!");
    });
  }

  handleInputs(e) {
    let key = e.target.name;
    let input = e.target.value;
    this.setState({
      [key]: input,
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Menu
          inputs={this.handleInputs}
          submit={this.handleSubmit}
          menu={this.state}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(EditMenuContainer);
