import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import People from "../../components/views/People";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import {
    saveHotelId,
} from "../../../store/actions/hotelAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const DB = firebase.db;


const mapStateToProps = (state) => {
    return {
        hotelId: state.user.loginUser.hotelId,
        rol: state.user.loginUser.rol,
        isAuth: state.user.isAuth,
        rooms: state.hotel.rooms,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
        saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
    };
};

class PeopleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hotel: {},
            people: [],
            loader: true,
            oneAction: false,
            lideresCant: []
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.props.showLoader();
    }

    componentDidUpdate() {
        if (this.state.oneAction === false) {
            if (this.props.rooms.lenth !== 0) {
                this.setState({
                    people: this.props.rooms,
                    loader: false,
                    oneAction: true
                });
                let lideres = this.props.rooms
                let lideresFilter = lideres.filter(lider => lider.active === true)
                this.setState({
                    lideresCant: lideresFilter
                }, () => this.props.hideLoader());
            }
        }
    }

    handleClick(e) {

        // Swal.fire({
        //     title: `${e.firstName} ${e.lastName}`,
        //     footer: `Usuario desde ${e.contactSince}`,
        //     grow: false,
        //     showConfirmButton: true,
        //     showCancelButton: true,
        //     confirmButtonText: `Ver Faltas`,
        //     cancelButtonText: `Hacer Líder`,
        //     reverseButtons: true,
        //     showCloseButton: true,
        //     iconColor: "#ff2068",
        //     inputAttributes: {
        //         autocapitalize: 'off'
        //     },
        //     imageUrl: `${e.photo}`,
        //     // imageWidth: 300,
        //     imageHeight: 150,
        //     imageAlt: 'Profile image',

        //     html:
        //         `<div  style="display:flex; flex-direction:column; align-items:flex-start; font-family:Lato;">
        //         <h1  style="font-size:18; color:#000; font-family:Lato;"><b>Fecha de Nacimiento:</b> ${e.birthday}</h1>
        //         <h1 style="font-size:18; color:#000; font-family:Lato;"><b>Email:</b> ${e.mail}</h1>
        //         <h1 style="font-size:18; color:#000; font-family:Lato;"><b>Teléfono:</b> ${e.phone}</h1>
        //         <h1 style="font-size:18; color:#000; font-family:Lato;"><b>Clase:</b> ${e.class}</h1>
        //         <h1 style="font-size:18; color:#000; font-family:Lato;"><b>Rol:</b> ${e.rol}</h1>
        //         <h1 style="font-size:18; color:#000; font-family:Lato;"><b>Lider de:</b> ${e.liderOf}</h1>
        //         </div>`

        // }).then((result) => {

        //     if (result.value) {


        //         // Swal.fire({
        //         //     title: "Gracias!",
        //         //     text: "Lo estaremos contactando.",
        //         //     icon: "success",
        //         //     showCancelButton: false,
        //         //     confirmButtonColor: "#339AE7",
        //         //     confirmButtonText: "Continuar",
        //         // });
        //     }
        // })
    }

    render() {
        return (
            <div>
                <Sidebar />
                <People
                    people={this.state.people}
                    handleClick={this.handleClick}
                    rol={this.props.rol}
                    lideresCant={this.state.lideresCant}
                />
                <div>
                    <FullPageLoader />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleContainer);
