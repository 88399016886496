import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Bags from "../../components/views/Bags";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import {
    saveHotelId,
} from "../../../store/actions/hotelAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const DB = firebase.db;


const mapStateToProps = (state) => {
    return {
        hotelId: state.user.loginUser.hotelId,
        rol: state.user.loginUser.rol,
        isAuth: state.user.isAuth,
        absences: state.hotel.absences,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
        saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
    };
};

class BagsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hotel: {},
            people: [],
            loader: true,
            oneAction: false,
            lideresCant: [],
            lastMoves: []
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        //  this.props.saveHotelId("1");
        this.props.showLoader();

        if (this.props.hotelId) {

            let lastMovesDoc = DB.collection("countrys")
                .doc(`${this.props.hotelId}`).collection("moveBagsHistory")

            lastMovesDoc.onSnapshot((peopleBack) => {
                peopleBack.forEach((people) => {
                    this.setState({ lastMoves: people.data().history })
                });
            })
        }
    }

    componentDidUpdate() {
        if (this.state.oneAction === false) {
            if (this.props.absences.length !== 0) {
                this.setState({
                    people: this.props.absences,
                    loader: false,
                    oneAction: true
                });
                let lideres = this.props.absences
                let lideresFilter = lideres.filter(lider => lider.type === "bag")
                this.setState({
                    lideresCant: lideresFilter
                }, () => this.props.hideLoader());
            }
        }
    }

    handleClick(e) {
        this.props.history.push(`/bags/${e}`);
    }

    handleDelete(e) {
        MySwal.fire({
            title: "¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {

                let bagRequested = DB.collection("countrys")
                    .doc(`${this.props.hotelId}`)
                    .collection("moveBags")

                bagRequested.get().then((bagRequestedSingle) => {
                    bagRequestedSingle.forEach((singleRequested) => {
                        let bagRequestedToDelete = DB.collection("countrys")
                            .doc(`${this.props.hotelId}`)
                            .collection("moveBags").doc(`${singleRequested.id}`)
                        bagRequestedToDelete.delete()
                    });
                    firebase.succesfullMsg("Movimientos pendientes eliminados!");
                })
            }
        })



    }

    render() {
        return (
            <div>
                <Sidebar />
                <Bags
                    people={this.state.people}
                    handleClick={this.handleClick}
                    handleDelete={this.handleDelete}
                    rol={this.props.rol}
                    lideresCant={this.state.lideresCant}
                    lastMoves={this.state.lastMoves}
                />
                <div>
                    <FullPageLoader />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BagsContainer);
