import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import firebase from "./services/firebase";
import Login from "./restaurant/containers/views/Login";
import Configurations from "./restaurant/containers/views/Configurations";
import HomeInmobiliarias from "./client/containers/views/HomeInmobiliarias";
import ComingSoon from "./client/containers/views/ComingSoon";
import LoginClient from "./client/containers/views/Login";
import LoginNoUser from "./client/containers/views/LoginNoUser";
import Register from "./client/containers/views/Register";
import Orders from "./restaurant/containers/views/Orders";
import RecoverPassword from "./restaurant/components/views/RecoverPassword";
import Menues from "./restaurant/containers/views/Menues";
import Outlets from "./restaurant/containers/views/Outlets";
import Absence from "./restaurant/containers/views/Absence";
import NewsAdmin from "./restaurant/containers/views/News";
import People from "./restaurant/containers/views/People";
import Bags from "./restaurant/containers/views/Bags";
import SimpleBag from "./restaurant/containers/views/SimpleBag";
import MessagesAdmin from "./restaurant/containers/views/Messages";
import Dashboard from "./restaurant/containers/views/Dashboard";
import ScreenHome from "./restaurant/containers/views/ScreenHome";
import ScreenNews from "./restaurant/containers/views/ScreenNews";
import ScreenTournaments from "./restaurant/containers/views/ScreenTournaments";
import ScreenWinners from "./restaurant/containers/views/ScreenWinners";
import ScreenBags from "./restaurant/containers/views/ScreenBags";
import Categories from "./restaurant/containers/views/Categories";
import Products from "./restaurant/containers/views/Products";
import SingleRoom from "./restaurant/containers/views/SingleRoom";
import CreateHotel from "./restaurant/containers/create/Hotel";
import CreateNews from "./restaurant/containers/create/News";
import CreateMoveCommentItem from "./restaurant/containers/create/MoveCommentItem";
import CreateBags from "./restaurant/containers/create/Bags";
import CreateMessages from "./restaurant/containers/create/Messages";
import CreateMenu from "./restaurant/containers/create/Menu";
import CreateCategory from "./restaurant/containers/create/Category";
import CreateProduct from "./restaurant/containers/create/Product";
import EditItems from "./restaurant/containers/edit/ItemsBags";
import EditProduct from "./restaurant/containers/edit/Product";
import EditCategory from "./restaurant/containers/edit/Category";
import EditMenu from "./restaurant/containers/edit/Menu";
import EditOutlet from "./restaurant/containers/edit/Outlet";
import EditNews from "./restaurant/containers/edit/News";
import EditBags from "./restaurant/containers/edit/Bags";
import EditConfigurations from "./restaurant/containers/edit/Configurations";
import OutletsClient from "./client/containers/views/Outlets";
import HomeGeneral from "./client/containers/views/HomeGolf";
import Suppliers from "./client/containers/views/Suppliers";
import GolfBalls from "./client/containers/views/GolfBalls";
import News from "./client/containers/views/Propiedades";
import Messages from "./client/containers/views/Messages";
import Restaurants from "./client/containers/views/Restaurants";
import Emergency from "./client/containers/views/Emergency";
import Claims from "./client/containers/views/Claims";
import AddClaim from "./client/containers/views/AddClaim";
import Wallpapers from "./client/containers/views/Wallpapers";
import TakeAssistant from "./client/containers/views/TakeAssistant";
import Actions from "./client/containers/views/Actions";
import Ministries from "./client/containers/views/Ministries";
import Donations from "./client/containers/views/Donations";
import Profile from "./client/containers/views/Profile";
import PropiedadIndividual from "./client/containers/views/PropertieIndividual";
import ProductsClient from "./client/containers/views/PropertieIndividual";
import Cart from "./client/containers/views/Cart";
import Information from "./client/components/views/Information";
import ChatDashboard from "./restaurant/containers/views/Chat";
import Chat from "./client/components/views/Chat";
import Password from "./restaurant/components/edit/Password";
import Mail from "./client/components/views/Mail";
import Navbar from "./restaurant/containers/general/Navbar";
import OrdersHistory from "./restaurant/containers/views/OrdersHistory";
import SimpleBagPhone from "./restaurant/containers/views/SimpleBagPhone";
import BagsMovements from "./restaurant/containers/views/BagsMovements";


const mapStateToProps = (state) => {
  return {
    userLogin: state.user.loginUser,
    isAuth: state.user.isAuth,
  };
};

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      firebaseInitialized: false,
    };
  }

  componentDidMount() {
    firebase.isInitialized().then((val) => {
      this.setState({
        firebaseInitialized: val,
      });
    });
  }
  // componentDidUpdate(prevState) {
  //   prevState.isAuth !== this.props.isAuth &&
  //     this.props.userLogin.name &&
  //     this.props.history.push("/dashboard");
  // }

  render() {
    return this.props.userLogin.name ? (
      <div>
        <Navbar />
        <Switch>
          {/* Views */}
          <Route path="/chat" component={ChatDashboard} />
          <Route path="/orders/history" component={OrdersHistory} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/screen" component={ScreenHome} />
          <Route path="/screen-news" component={ScreenNews} />
          <Route path="/screen-tournaments" component={ScreenTournaments} />
          <Route path="/screen-winners" component={ScreenWinners} />
          <Route path="/screen-bags" component={ScreenBags} />
          <Route path="/configuration/edit" component={EditConfigurations} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/configurations" component={Configurations} />
          <Route path="/configurations/resetpassword" component={Password} />
          <Route path="/recover" component={RecoverPassword} />
          {/* Create */}
          <Route path="/create/propertie" component={CreateHotel} />
          <Route path="/menu/createMenu" component={CreateMenu} />
          <Route path="/news/create" component={CreateNews} />
          <Route path="/bags/create" component={CreateBags} />
          <Route path="/messages/create" component={CreateMessages} />
          <Route path="/menu/:id/createCategory" component={CreateCategory} />
          <Route
            path="/menu/:id/:categoryId/createProduct"
            component={CreateProduct}
          />
          {/* Edit */}
          <Route
            path="/menu/:id/:categoryId/editProduct/:productId"
            component={EditProduct}
          />
          <Route
            path="/menu/:id/:categoryId/editCategory"
            component={EditCategory}
          />
          <Route path="/outlet/:id/editOutlet" component={EditOutlet} />
          <Route path="/menu/:id/editMenu" component={EditMenu} />
          {/* Rutas a Menu/Categorias/Productos */}
          <Route exact path="/menu" component={Menues} />
          <Route exact path="/outlets" component={Outlets} />
          <Route exact path="/menu/:id/:categoryId" component={Products} />
          <Route path="/menu/:id" component={Categories} />
          <Route path="/configurations" component={Configurations} />
          <Route path="/absence" component={Absence} />
          <Route path="/news-admin/:idNew" component={EditNews} />
          <Route path="/news-admin" component={NewsAdmin} />
          <Route path="/people" component={People} />
          <Route path="/bag/:idBag" component={SimpleBagPhone} />
          <Route path="/bags/edit/:idBag" component={EditBags} />
          <Route path="/bags/lastmovements" component={BagsMovements} />
          <Route path="/bags/:idBag/edit/:idItem" component={EditItems} />
          <Route path="/bags/:idBag/:idAction" component={CreateMoveCommentItem} />
          <Route path="/bags/:idBag" component={SimpleBag} />
          <Route path="/bags" component={Bags} />
          <Route path="/messages-admin" component={MessagesAdmin} />
          <Route
            path="/forms"
            component={() => {
              window.location.href = "https://lovable.typeform.com/to/Y9pVsV";
              return null;
            }}
          />
          <Route
            path="/whatsapp"
            component={() => {
              window.location.href =
                "https://api.whatsapp.com/send?phone=+5491136926953&text=Hola%21%20Me%20gustar%C3%ADa%20conocer%20más%20acerca%20de%20...&source=&data=&app_absent=";
              return null;
            }}
          />
          <Route
            exact
            path="/tables/:idTable"
            component={withRouter(SingleRoom)}
          />
          <Route exact path="/login" component={Login}></Route>

          {/* Cliente */}

          <Route
            path="/propiedades/:idPropiedad"
            component={PropiedadIndividual}
          />
          <Route path="/home" component={HomeGeneral} />
          <Route path="/news" component={News} />
          <Route path="/suppliers" component={Suppliers} />
          <Route path="/hours" component={Messages} />
          <Route path="/maps" component={Wallpapers} />
          <Route path="/restaurants" component={Restaurants} />
          <Route path="/emergency" component={Emergency} />
          <Route path="/claims" component={Claims} />
          <Route path="/golfballs" component={GolfBalls} />
          <Route path="/add-claim" component={AddClaim} />
          <Route path="/take-assistant" component={TakeAssistant} />
          <Route path="/actions" component={Actions} />
          <Route path="/ministries" component={Ministries} />
          <Route path="/donations" component={Donations} />
          <Route path="/profile" component={Profile} />
          <Route path="/register" component={Register} />
          <Route path="/login-app" component={LoginClient} />
          <Route path="/coming-soon" component={ComingSoon} />
          {/* <Route path="/comprar" component={Comprar} /> */}
          <Route exact path="/" component={HomeInmobiliarias}></Route>
          <Route path="/lugares/:idLugar" component={News} />


          <Route
            path="/:idHotel/:idLanguage/:idMenu/:idCategoria/:idProduct/client"
            component={ProductsClient}
          />
          {/* <Route
            path="/:idHotel/:idMenu/:idCategoria/:idProduct/:idRoom/client"
            component={ProductsClient}
          /> */}

          {/* <Route
            path="/:idHotel/menu/:idMenu/:idRoom"
            component={CategoriesClient}
          /> */}

          <Route
            path="/:idHotel/:idLanguage/outlets"
            component={OutletsClient}
          />

          {/* <Route path="/:idHotel/:idRoom/menu" component={MenuesClient} /> */}

          {/* <Route path="/:idHotel/language" component={HomeHilton} /> */}
          <Route path="/:idHotel/cart/:idRoom" component={Cart} />
          <Route path="/:idHotel/rooms" component={LoginClient} />
          {/* <Route path="/:idHotel/login" component={LoginNoUser} /> */}
          {/* <Route
            path="/:idHotel/menu/:idMenu/:idRoom"
            component={CategoriesClient}
          /> */}
          <Route path="/:idHotel/menu/:idMenu/" component={News} />

          {/* <Route
            path="/:idHotel/:idMenu/:idCategoria/:idProduct/:idRoom/client"
            component={ProductsClient}
          /> */}
          <Route
            path="/:idHotel/:idMenu/:idCategoria/:idProduct/client"
            component={ProductsClient}
          />
          <Route path="/:idRestaurant/:idTable/mail" component={Mail} />
          <Route path="/:idHotel/:idRoom/information" component={Information} />
          <Route path="/:idHotel/:idRoom/chat" component={Chat} />
        </Switch>
      </div>
    ) : (
      <div>
        <Switch>
          {/* Views */}
          <Route path="/chat" component={ChatDashboard} />
          <Route path="/orders/history" component={OrdersHistory} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/screen" component={ScreenHome} />
          <Route path="/screen-news" component={ScreenNews} />
          <Route path="/screen-tournaments" component={ScreenTournaments} />
          <Route path="/screen-winners" component={ScreenWinners} />

          <Route path="/screen-bags" component={ScreenBags} />
          <Route path="/configuration/edit" component={EditConfigurations} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/configurations" component={Configurations} />
          <Route path="/configurations/resetpassword" component={Password} />
          <Route path="/recover" component={RecoverPassword} />
          {/* Create */}
          <Route path="/create/propertie" component={CreateHotel} />
          <Route path="/menu/createMenu" component={CreateMenu} />
          <Route path="/news/create" component={CreateNews} />
          <Route path="/bags/create" component={CreateBags} />
          <Route path="/messages/create" component={CreateMessages} />
          <Route path="/menu/:id/createCategory" component={CreateCategory} />
          <Route
            path="/menu/:id/:categoryId/createProduct"
            component={CreateProduct}
          />
          {/* Edit */}
          <Route
            path="/menu/:id/:categoryId/editProduct/:productId"
            component={EditProduct}
          />
          <Route
            path="/menu/:id/:categoryId/editCategory"
            component={EditCategory}
          />
          <Route path="/menu/:id/editMenu" component={EditMenu} />
          <Route path="/outlet/:id/editOutlet" component={EditOutlet} />
          {/* Rutas a Menu/Categorias/Productos */}
          <Route exact path="/menu" component={Menues} />
          <Route exact path="/outlets" component={Outlets} />
          <Route exact path="/menu/:id/:categoryId" component={Products} />
          <Route path="/menu/:id" component={Categories} />

          <Route path="/configurations" component={Configurations} />
          <Route path="/absence" component={Absence} />
          <Route path="/news-admin/:idNew" component={EditNews} />
          <Route path="/news-admin" component={NewsAdmin} />
          <Route path="/people" component={People} />
          <Route path="/bag/:idBag" component={SimpleBagPhone} />
          <Route path="/bags/edit/:idBag" component={EditBags} />
          <Route path="/bags/lastmovements" component={BagsMovements} />
          <Route path="/bags/:idBag/edit/:idItem" component={EditItems} />
          <Route path="/bags/:idBag/:idAction" component={CreateMoveCommentItem} />
          <Route path="/bags/:idBag" component={SimpleBag} />
          <Route path="/bags" component={Bags} />
          <Route path="/messages-admin" component={MessagesAdmin} />
          <Route
            path="/forms"
            component={() => {
              window.location.href = "https://lovable.typeform.com/to/Y9pVsV";
              return null;
            }}
          />
          <Route
            path="/whatsapp"
            component={() => {
              window.location.href = "https://wa.me/%2B5491136926953";
              return null;
            }}
          />

          <Route
            exact
            path="/tables/:idTable"
            component={withRouter(SingleRoom)}
          />

          <Route exact path="/login" component={Login}></Route>
          {/* Cliente */}
          <Route
            path="/propiedades/:idPropiedad"
            component={PropiedadIndividual}
          />
          <Route path="/home" component={HomeGeneral} />
          <Route path="/news" component={News} />
          <Route path="/suppliers" component={Suppliers} />
          <Route path="/hours" component={Messages} />
          <Route path="/maps" component={Wallpapers} />
          <Route path="/restaurants" component={Restaurants} />
          <Route path="/emergency" component={Emergency} />
          <Route path="/claims" component={Claims} />
          <Route path="/golfballs" component={GolfBalls} />
          <Route path="/add-claim" component={AddClaim} />
          <Route path="/take-assistant" component={TakeAssistant} />
          <Route path="/actions" component={Actions} />
          <Route path="/ministries" component={Ministries} />
          <Route path="/donations" component={Donations} />
          <Route path="/profile" component={Profile} />
          <Route path="/register" component={Register} />
          <Route path="/login-app" component={LoginClient} />
          <Route path="/coming-soon" component={ComingSoon} />
          {/* <Route path="/comprar" component={Comprar} /> */}
          <Route exact path="/" component={HomeInmobiliarias}></Route>
          <Route path="/lugares/:idLugar" component={News} />

          <Route path="/:idHotel/cart/:idRoom" component={Cart} />
          <Route path="/:idHotel/tables" component={LoginClient} />
          <Route path="/:idHotel/login" component={LoginNoUser} />
          <Route
            path="/:idHotel/:idLanguage/:idMenu/:idCategoria/:idProduct/client"
            component={ProductsClient}
          />
          {/* <Route
            path="/:idHotel/:idMenu/:idCategoria/:idProduct/:idRoom/client"
            component={ProductsClient}
          /> */}

          {/* <Route
            path="/:idHotel/menu/:idMenu/:idRoom"
            component={CategoriesClient}
          /> */}

          <Route
            path="/:idHotel/:idLanguage/outlets"
            component={OutletsClient}
          />

          {/* <Route path="/:idHotel/:idRoom/menu" component={MenuesClient} /> */}

          <Route path="/:idRestaurant/:idTable/mail" component={Mail} />
          <Route path="/:idHotel/:idRoom/information" component={Information} />
          <Route path="/:idHotel/:idRoom/chat" component={Chat} />
        </Switch>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Main);
