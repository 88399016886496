//constants of Restaurants
export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";

//constants of Clients
export const SET_LOGIN_CLIENT = "SET_LOGIN_CLIENT";
export const SET_NEW_PRODUCT = "SET_NEW_PRODUCT";
export const SET_EMPTY_CART = "SET_EMPTY_CART";

//constants of HOTEL
export const SET_HOTEL_ID = "SET_HOTEL_ID";
export const SET_HOTEL_INFO = "SET_HOTEL_INFO";
export const SET_LANGUAGE_INFO = "SET_LANGUAGE_INFO";
export const SET_ROOMS_INFO = "SET_ROOMS_INFO";
export const SET_ABSENCES_INFO = "SET_ABSENCES_INFO";

export const ADD_MENU_QUANTITY = "ADD_MENU_QUANTITY";
export const DOWN_MENU_QUANTITY = "DOWN_MENU_QUANTITY";

export const ADD_CATEGORY_QUANTITY = "ADD_CATEGORY_QUANTITY";
export const DOWN_CATEGORY_QUANTITY = "DOWN_CATEGORY_QUANTITY";

export const ADD_PRODUCT_QUANTITY = "ADD_PRODUCT_QUANTITY";
export const DOWN_PRODUCT_QUANTITY = "DOWN_PRODUCT_QUANTITY";

export const ADD_DISABLE_QUANTITY = "ADD_DISABLE_QUANTITY";
export const DOWN_DISABLE_QUANTITY = "DOWN_DISABLE_QUANTITY";

//constants of MENUES Clients
export const SET_OUTLETS_INFO = "SET_OUTLETS_INFO";
export const SET_MENUES_INFO = "SET_MENUES_INFO";

//constants of CATEGORIES + PRODUCTS Clients
export const SET_CATEGORIES_INFO = "SET_CATEGORIES_INFO";
export const SET_CATEGORIES_BACKUP = "SET_CATEGORIES_BACKUP";
export const SET_PRODUCTS_INFO = "SET_PRODUCTS_INFO";
export const SET_CATEGORIES_HISTORY = "SET_CATEGORIES_HISTORY";
