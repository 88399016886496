import React from "react";
import Suppliers from "../../components/views/Suppliers";
import firebase from "../../../services/firebase";

const DB = firebase.db;

class ProveedoresCliente extends React.Component {
    constructor(props) {
        super();
        this.state = {
            suppliers: [],
            loader: true,
        };
        this.handleClick = this.handleClick.bind(this);

    }

    componentDidMount() {
        window.scrollTo(0, 0);

        let suppliersDoc = DB.collection("countrys")
            .doc("lVGwoAyzUnN1ygmvEE3T")
            .collection("suppliers")
            .doc("e64TB170lo7ZKpPVjFcZ")

        suppliersDoc.get()
            .then((supplier) => {
                this.setState({
                    suppliers: supplier.data().suppliers,
                    loader: false,
                });
            })

    }

    handleClick(type) {
        if (type === "add") {
            //agregar accion de proveedores.
        }
    }

    render() {
        return (
            <div>
                <Suppliers
                    suppliers={this.state.suppliers}
                    loader={this.state.loader}
                    screenSize={window.screen.width}
                    handleClick={this.handleClick}
                />
            </div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(PropiedadesCliente);

export default ProveedoresCliente;
