import React from "react";
import Actions from "../../components/views/Actions";
import firebase from "../../../services/firebase";
const DB = firebase.db;

class ActionsContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      messages: [],
      loader: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // let arrayMessages = [];
    // let messagesDoc = DB.collection("church")
    // .doc("02NyPwGGExwDiTMeI70m")
    //    .collection("messages")
    //   .orderBy("order", "desc");

    // messagesDoc.get()
    //   .then((messages) => {
    //     messages.forEach((messageDoc) => {
    //         arrayMessages.push({
    //         id: messageDoc.id,
    //         youtube: messageDoc.data().youtube,
    //         title: messageDoc.data().title,
    //       });
    //     });
    //   })
    //   .then(() => {
    //     this.setState({
    //         messages: arrayMessages,
    //       loader: false,
    //     });
    //   });

      this.setState({
      loader: false,
    });
    }

  render() {
    return (
      <div>
        <Actions
          messages={this.state.messages}
          loader={this.state.loader}
          screenSize={window.screen.width}
        />
      </div>
    );
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(PropiedadesCliente);

export default ActionsContainer;
