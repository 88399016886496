import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const styles = {
    container: {
        backgroundColor: "white",
        marginLeft: "250px",
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingTop: "20px",
    },
    containerFlex: {
        marginLeft: "250px",
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        justifyContent: "center",
        alignItems: "center",
    },
    titleAnalytic: {
        color: "#000",
        //fontWeight: "bold",
        textAlign: "center",
        fontSize: "35px",
        fontFamily: "LatoBlack",
    },
    titleDashboard: {
        color: "#000",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "25px",
        margin: 0,
    },
    descriptionAnalytic: {
        color: "#000",
        textAlign: "center",
        //fontWeight: "400",
        marginBottom: "0px",
        fontFamily: "Lato",
    },
    shadow: {
        boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
    },
    textLatoBlack: {
        fontFamily: "LatoBlack",
        paddingBottom: "30px",
    },
    textLato: {
        fontFamily: "Lato",
    },
    buttonScreen: {
        backgroundColor: "#fff",
        border: "1px #ff2068 solid",
        padding: "8px 30px 8px 65px",
        color: "#ff2068",
        borderRadius: "25px",
        margin: 10,
        fontSize: 18,
        fontFamily: "Lato",
        width: '250px',
        display: "flex",
        alignItems: "center",
        // justifyContent: 'center'
    },
    buttonScreen2: {
        backgroundColor: "#ff2068",
        border: "1px #ff2068 solid",
        padding: "8px 30px 8px 30px",
        color: "#fff",
        borderRadius: "25px",
        margin: 10,
        fontSize: 18,
        fontFamily: "Lato",
        width: '250px',
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        border: 'none',
        outline: 'none',
        textDecoration: "none",
    },
    icons: {
        marginRight: "20px",
    },
};

export default ({ handleClick }) => {
    return (
        <div>
            <div
                className="row align-items-center"
                style={{
                    backgroundColor: "#ffffff",
                    margin: "0px",
                }}
            >
                <div
                    className="col"
                    style={{
                        width: "100%",
                        margin: 0,
                        padding: 0,
                        height: "100vh",
                        backgroundImage: `url(${"https://i.postimg.cc/YSwMT0xd/background-mygolf.jpg"})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <img
                        src="https://i.postimg.cc/9M91vgg5/Artboard-1.png"
                        alt="..."
                        //  height="22"
                        style={{
                            height: "10%",
                            width: "auto",
                            position: "relative",
                            left: "10%",
                            top: "80%",
                        }}
                    />
                </div>
                <div
                    className="col"
                    style={{
                        padding: "0px",
                        margin: "0px",
                    }}
                >
                    <div
                        style={{
                            padding: "40px",
                            display: "flex",
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <h1 style={styles.textLatoBlack}>Seleccione la pantalla</h1>
                        <Link className="nav-link" style={styles.buttonScreen} to="/screen-news">
                            <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
                                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                            </svg>
                            Noticias
                          </Link>
                        <Link className="nav-link" style={styles.buttonScreen}
                            to="/screen-tournaments"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16">
                                <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z" />
                            </svg>
                           Torneos
                         </Link>
                        <Link className="nav-link" style={styles.buttonScreen}
                            to="/screen-winners"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-award" viewBox="0 0 16 16">
                                <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z" />
                                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
                            </svg>
                              Ganadores
                          </Link>
                        <Link className="nav-link" style={styles.buttonScreen} to="/screen-bags">
                            <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                            </svg>
                            Bolsas
                        </Link>
                        <button className="nav-link" style={styles.buttonScreen2}
                            onClick={() => handleClick("close")}
                        >
                            Cerrar Sesión
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};
