import React from "react";
//import { Link } from "react-router-dom";

const Background =
  "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbackground-app.png?alt=media&token=18234069-f4d0-46c7-b98e-6978e6ef68e3";

const styles = {
  container: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  top: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    marginLeft: "30px",
    //  backgroundColor: "green",
  },
  logoSection: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "40px 40px 0 0",

    border: "2px solid white",
    // paddingBottom: "50px",
  },
  logo: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    // flexFlow: "column",
    // height: "180px",
    //widht: "auto",
    alignItems: "center",
    backgroundColor: "#ff2068",
    paddingTop: "12px",
    paddingBottom: "12px",
    borderRadius: "50px",
  },
  information: {
    flex: 7,
    //display: "flex",
    padding: "20px",
    paddingTop: "0px",
    //justifyContent: "flex-start",
    //alignItems: "flex-start",
    flexWrap: "wrap",
    //margin: "0 30px 0 30px",
    backgroundColor: "white",

    overflow: "auto",
  },

  roomTitle: {
    margin: 0,
    fontSize: 45,
    fontWeight: "bold",
    textAlign: "center",
    color: "#ffffff",
  },

  roomSubtitle: {
    margin: 0,
    marginTop: "10px",
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
    color: "#ffffff",
  },
  informationTitle: {
    margin: 0,
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
    color: "black",
    marginTop: "20px",
    marginBottom: "20px",
  },
  informationSubtitle: {
    //margin: 20,
    marginTop: "10px",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",

    color: "black",
  },

  optionsTitle: {
    flex: 1,
    margin: 0,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
  },
  buttonText: {
    flex: 1,
    margin: 0,
    fontSize: 15,
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  roomNumber: {
    margin: 0,
    //fontFamily: "Lato",
    fontSize: 35,
    fontWeight: 900,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
  },
};

export default ({
  handleClick,
  room,
  hotel,
  propsOfHotelId,
  propsOfRoomId,
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.top}>
        {/* {restaurant.backgroundImage ? (
          <img src={restaurant.logoImage} height="50" alt="Logo" />
        ) : (
          <img
            src="https://insideone.s3-sa-east-1.amazonaws.com/logo-lovable.png"
            height="50"
            alt="Logo"
          />
        )} */}
        <h1 style={styles.roomTitle}>Información</h1>
        <h6 style={styles.roomSubtitle}>Para ayudarte en tu estadía.</h6>
      </div>
      <div style={styles.logoSection}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fhoward-johnson-logo-color.png?alt=media&token=240c641b-5ad2-47a0-8a40-889014e8f2f6"
          height="50"
          alt="Logo"
        />
        {/* <p style={styles.roomNumber}>{table.number}</p> */}
      </div>

      <div style={styles.information}>
        <h1 style={styles.informationTitle}>Horarios</h1>
        <h6 style={styles.informationSubtitle}>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Labore,
          adipisci! Pariatur repudiandae, tempore necessitatibus eaque suscipit
          rem? Provident sed odit debitis quisquam, voluptatem enim incidunt id
          a vel molestiae ducimus.
        </h6>
        <h1 style={styles.informationTitle}>Servicios</h1>
        <h6 style={styles.informationSubtitle}>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Labore,
          adipisci! Pariatur repudiandae, tempore necessitatibus eaque suscipit
          rem? Provident sed odit debitis quisquam, voluptatem enim incidunt id
          a vel molestiae ducimus. Lorem ipsum, dolor sit amet consectetur
          adipisicing elit. Dolor tenetur ipsum omnis neque optio eaque sunt
          deserunt. Omnis vel aperiam non consequatur aliquam, maiores fuga
          fugiat beatae magni dolorem laboriosam.
        </h6>
        <h1 style={styles.informationTitle}>Restaurantes</h1>
        <h6 style={styles.informationSubtitle}>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Labore,
          adipisci! Pariatur repudiandae, tempore necessitatibus eaque suscipit
          rem? Provident sed odit debitis quisquam, voluptatem enim incidunt id
          a vel molestiae ducimus.
        </h6>
      </div>
    </div>
  );
};
