import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Product from "../../../restaurant/components/edit/Product";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
const DB = firebase.db;

const mapStateToProps = (state) => {
  return {
    hotelId: state.user.loginUser.hotelId,
    isAuth: state.user.isAuth,
  };
};

class EditProductContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nameSpanish: "",
      nameEnglish: "",
      namePortuguese: "",
      nameFrench: "",
      description: "",
      descriptionSpanish: "",
      descriptionEnglish: "",
      descriptionPortuguese: "",
      descriptionFrench: "",
      imageProduct: "",
      price: "",
      stock: true,
      orderProduct: 1,

      glutenFree: false,
      vegetarian: false,
      favourite: false,

      productsArray: [],
    };
    this.handleInputs = this.handleInputs.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  menuId = this.props.match.params.id;
  categoryId = this.props.match.params.categoryId;
  productId = this.props.match.params.productId;

  componentDidMount() {
    if (this.props.hotelId) {
      let productId = this.props.match.params.productId;
      this.props.dispatch(showLoader());
      let doc = DB.collection("hoteles")
        .doc(`${this.props.hotelId}`)
        .collection("menues")
        .doc(this.menuId)
        .collection("categories")
        .doc(this.categoryId);

      doc.get().then((product) => {
        this.setState({
          name: product.data().products[productId].name,
          nameSpanish: product.data().products[productId].nameSpanish,
          nameEnglish: product.data().products[productId].nameEnglish,
          namePortuguese: product.data().products[productId].namePortuguese,
          nameFrench: product.data().products[productId].nameFrench,
          description: product.data().products[productId].description,
          descriptionSpanish: product.data().products[productId]
            .descriptionSpanish,
          descriptionEnglish: product.data().products[productId]
            .descriptionEnglish,
          descriptionPortuguese: product.data().products[productId]
            .descriptionPortuguese,
          descriptionFrench: product.data().products[productId]
            .descriptionFrench,
          imageProduct: product.data().products[productId].imageProduct,
          price: product.data().products[productId].price,
          stock: product.data().products[productId].stock,

          productsArray: product.data().products,
        });

        if (product.data().products[productId].glutenFree) {
          this.setState({
            glutenFree: product.data().products[productId].glutenFree,
          });
        }

        if (product.data().products[productId].vegetarian) {
          this.setState({
            vegetarian: product.data().products[productId].vegetarian,
          });
        }

        if (product.data().products[productId].orderProduct) {
          this.setState({
            orderProduct: product.data().products[productId].orderProduct,
          });
        }

        if (product.data().products[productId].favourite) {
          this.setState({
            favourite: product.data().products[productId].favourite,
          });
        }

        setTimeout(() => {
          this.props.dispatch(hideLoader());
        }, 500);
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.dispatch(showLoader());
    let productId = this.props.match.params.productId;

    let doc = DB.collection("hoteles")
      .doc(`${this.props.hotelId}`)
      .collection("menues")
      .doc(this.menuId)
      .collection("categories")
      .doc(this.categoryId);

    let productsState = this.state.productsArray;

    productsState[productId] = {
      name: this.state.name,
      nameSpanish: this.state.nameSpanish,
      nameEnglish: this.state.nameEnglish,
      namePortuguese: this.state.namePortuguese,
      nameFrench: this.state.nameFrench,
      description: this.state.description,
      descriptionSpanish: this.state.descriptionSpanish,
      descriptionEnglish: this.state.descriptionEnglish,
      descriptionPortuguese: this.state.descriptionPortuguese,
      descriptionFrench: this.state.descriptionFrench,
      imageProduct: this.state.imageProduct,
      price: this.state.price,
      stock: this.state.stock,
      orderProduct: this.state.orderProduct,
      glutenFree: this.state.glutenFree,
      vegetarian: this.state.vegetarian,
      favourite: this.state.favourite,
    };

    this.setState({ productsArray: productsState });

    doc
      .update({
        products: this.state.productsArray,
      })
      .then((actualizado) => {
        this.props.dispatch(hideLoader());
        this.props.history.push(`/menu/${this.menuId}/${this.categoryId}`);
        firebase.succesfullMsg("Product updated!");
      });
  }

  handleInputs(e) {
    let key = e.target.name;
    let input = e.target.value;
    if (
      (key === "name" && input.length < 30) ||
      (key === "nameSpanish" && input.length < 30) ||
      (key === "nameEnglish" && input.length < 30) ||
      (key === "namePortuguese" && input.length < 30) ||
      (key === "nameFrench" && input.length < 30) ||
      // (key === "description" && input.length < 125) ||
      // (key === "descriptionSpanish" && input.length < 125) ||
      // (key === "descriptionEnglish" && input.length < 125) ||
      // (key === "descriptionPortuguese" && input.length < 125) ||
      // (key === "descriptionFrench" && input.length < 125)

      (key === "description" && input.length < 145) ||
      (key === "descriptionSpanish" && input.length < 145) ||
      (key === "descriptionEnglish" && input.length < 145) ||
      (key === "descriptionPortuguese" && input.length < 145) ||
      (key === "descriptionFrench" && input.length < 145)
    ) {
      this.setState({
        [key]: input,
      });
    }

    if (key === "imageProduct" || key === "price" || key === "orderProduct") {
      this.setState({
        [key]: input,
      });
    }
  }

  handleOptionChange(e) {
    if (e.target.name === "glutenFree") {
      this.setState({
        glutenFree: !this.state.glutenFree,
      });
    }

    if (e.target.name === "vegetarian") {
      this.setState({
        vegetarian: !this.state.vegetarian,
      });
    }

    if (e.target.name === "favourite") {
      this.setState({
        favourite: !this.state.favourite,
      });
    }
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Product
          inputs={this.handleInputs}
          submit={this.handleSubmit}
          product={this.state}
          valueState={this.state}
          handleOptionChange={this.handleOptionChange}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(EditProductContainer);
