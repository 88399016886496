import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Absence from "../../../restaurant/components/views/Absence";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import {
  saveHotelId,
} from "../../../store/actions/hotelAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const DB = firebase.db;

// let local = JSON.parse(window.localStorage.getItem("persist:lovableLogin"));
// let userLS;
// if (local) {
//   userLS = JSON.parse(local.user);
// }
const mapStateToProps = (state) => {
  return {
    hotelId: state.user.loginUser.hotelId,
    rol: state.user.loginUser.rol,
    isAuth: state.user.isAuth,
    userLogin: state.user.loginUser.hotelId,
    absences: state.hotel.absences,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
  };
};

class AbsenceContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: {},
      peopleToContact: [],

      absences: [],
      loader: true,
      oneAction: false,
    };
    // this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.props.saveHotelId("1");

    // let churchDoc = DB.collection("church")
    //   .doc("02NyPwGGExwDiTMeI70m")

    // churchDoc.get()
    //   .then((persons) => {
    //     this.setState({
    //       peopleToContact: persons.data().peopleToContact,
    //     });
    //   })

  }


  componentDidUpdate() {
    if (this.state.oneAction === false) {
      if (this.props.absences.lenth !== 0) {
        this.setState({
          absences: this.props.absences,
          loader: false,
          oneAction: true
        });
      }

    }
  }


  render() {
    return (
      <div>
        <Sidebar />
        <Absence
          peopleToContact={this.state.peopleToContact}
          absences={this.state.absences}
          deleteFunc={this.handleDelete}
          rol={this.props.rol}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceContainer);
