import React from "react";

export default ({
  submit,
  inputs,
  category,
  handleOptionChange,
  optionSelected,
}) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        marginLeft: "250px",
        paddingBottom: "100px",
      }}
    >
      <div
        className="container"
        style={{
          paddingLeft: "35px",
          paddingRight: "35px",
          paddingTop: "20px",
        }}
      >
        <h1 className="font-weight-bold">Edit Category</h1>
        <div className="col">
          <form
            onSubmit={(e) => submit(e)}
            style={{
              padding: "40px",
              marginRight: "20px",
            }}
          >
            <div className="form-group">
              <h3 className="font-weight-bold">Names</h3>
              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Name
              </label>
              <input
                onChange={(e) => inputs(e)}
                name="name"
                type="text"
                className="form-control"
                placeholder="Category name..."
                required
                value={category.name}
              />
              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇪🇸 - Spanish Name
              </label>
              <input
                onChange={(e) => inputs(e)}
                name="nameSpanish"
                type="text"
                className="form-control"
                placeholder="Pizza, burgers, etc..."
                value={category.nameSpanish}
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇺🇸 - English Name
              </label>
              <input
                onChange={(e) => inputs(e)}
                name="nameEnglish"
                type="text"
                className="form-control"
                placeholder="Pizza, burgers, etc..."
                value={category.nameEnglish}
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇧🇷 - Portuguese Name
              </label>
              <input
                onChange={(e) => inputs(e)}
                name="namePortuguese"
                type="text"
                className="form-control"
                placeholder="Pizza, burgers, etc..."
                value={category.namePortuguese}
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇫🇷 - French Name
              </label>
              <input
                onChange={(e) => inputs(e)}
                name="nameFrench"
                type="text"
                className="form-control"
                placeholder="Pizza, burgers, etc..."
                value={category.nameFrench}
              />
            </div>
            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <h3
                style={{
                  marginTop: "30px",
                }}
                className="font-weight-bold"
              >
                Details
              </h3>
              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Image URL
              </label>
              <input
                onChange={(e) => inputs(e)}
                name="imageCategory"
                type="text"
                className="form-control"
                placeholder="Image URL..."
                value={category.imageCategory}
              />
            </div>

            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Order Number</label>
              <input
                onChange={(e) => inputs(e)}
                name="orderCategory"
                type="text"
                className="form-control"
                placeholder="1, 2, 3..."
                value={category.orderCategory}
              />
            </div>

            <div
              className="form-check"
              style={{
                paddingBottom: "10px",
              }}
            >
              <input
                onChange={(e) => handleOptionChange(e)}
                className="form-check-input"
                type="radio"
                name="imagesTrue"
                id="radioImagesTrue"
                value="option1"
                checked={optionSelected === "option1"}
              />
              <label className="form-check-label" for="imagesTrue">
                Category with images of products
              </label>
            </div>

            <div
              className="form-check"
              style={{
                paddingBottom: "30px",
              }}
            >
              <input
                onChange={(e) => handleOptionChange(e)}
                className="form-check-input"
                type="radio"
                name="imagesFalse"
                id="radioImagesFalse"
                value="option2"
                checked={optionSelected === "option2"}
              />
              <label className="form-check-label" for="imagesFalse">
                Category without images of products
              </label>
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              style={{
                backgroundColor: "#FF2068",
                borderColor: "#FF2068",
              }}
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
