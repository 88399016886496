import React from "react";
import firebase from "../../../services/firebase";
import Cart from "../../../client/components/views/Cart";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { connect } from "react-redux";
import { emptyCart } from "../../../store/actions/cartClient";
const MySwal = withReactContent(Swal);

const DB = firebase.db;
//let order;
//let orderPrice;
//let total;
//let productArray;
let orderToCreate;
//let productProps;
let HotelId;
let RoomId;

const mapStateToProps = (state, ownprops) => {
  return {
    carrito: state.cart.cartData,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    emptyCart: (product) => dispatch(emptyCart(product)),
  };
};

class ViewCartContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productos: [],
      priceTotal: 0,
      idOrder: "",
      numberOfTable: 0,
    };
    //this.deleteClick = this.deleteClick.bind(this);
    this.handlerSubmit = this.handlerSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      productos: this.props.carrito,
    });
    HotelId = this.props.match.params.idHotel;
    RoomId = this.props.match.params.idRoom;

    // productArray = [];
    // productProps=this.props.carrito
    // productProps.forEach((product) => {
    //   productArray.push({
    //     id: product.id,
    //     imageProduct: product.data().imageProduct,
    //     name: product.data().name,
    //     price: product.data().price,
    //     description: product.data().description,
    //     quantity: product.data().quantity,
    //   });
    // });

    // const doc = DB.collection("restaurants")
    //   .doc(this.props.match.params.idRestaurant)
    //   .collection("tables")
    //   .doc(this.props.match.params.idTable);

    // doc.get().then((data) => {
    //   let orderId = data.data().orderActual;
    //   order = DB.collection("restaurants")
    //     .doc(this.props.match.params.idRestaurant)
    //     .collection("orders")
    //     .doc(`${orderId}`)
    //     .collection("products");

    //   order.onSnapshot((result) => {
    //     total = this.state.priceTotal;
    //     productArray = [];
    //     result.forEach((product) => {
    //       orderPrice = DB.collection("restaurants")
    //         .doc(this.props.match.params.idRestaurant)
    //         .collection("orders")
    //         .doc(`${orderId}`);

    //       productArray.push({
    //         id: product.id,
    //         imageProduct: product.data().imageProduct,
    //         name: product.data().name,
    //         price: product.data().price,
    //         description: product.data().description,
    //         quantity: product.data().quantity,
    //       });
    //     });
    //     if (this.state.priceTotal !== 0) total = 0;
    //     for (let i = 0; i < productArray.length; i++) {
    //       total += parseInt(productArray[i].price) * productArray[i].quantity;
    //     }
    //     orderPrice.update({ totalPrice: total });
    //     this.setState({
    //       productos: productArray,
    //       priceTotal: total,
    //       idOrder: orderId,
    //     });
    //   });
    // });
  }

  // deleteClick(e, id) {
  //   e.preventDefault();

  //   const order = DB.collection("restaurants")
  //     .doc(this.props.match.params.idRestaurant)
  //     .collection("orders")
  //     .doc(`${this.state.idOrder}`)
  //     .collection("products");
  //   order
  //     .doc(`${id}`)
  //     .delete()
  //     .then(() => {
  //       console.log("Document successfully deleted!");
  //     })
  //     .catch((error) => console.error("Error removing document: ", error));
  //}

  handlerSubmit(e) {
    e.preventDefault();

    //ir a hotel. buscar el ultimo pedido y guardarlo. Incrementar y guardar el otro.

    let HotelDoc = DB.collection("hoteles").doc(HotelId);
    let RoomHotel = DB.collection("hoteles")
      .doc(HotelId)
      .collection("rooms")
      .doc(RoomId);
    RoomHotel.get().then((result) => {
      this.setState({
        numberOfTable: result.data().number,
      });
    });
    HotelDoc.get().then((result) => {
      orderToCreate = result.data().orderTotalNumber;
      HotelDoc.update({ orderTotalNumber: orderToCreate + 1 });
      RoomHotel.update({
        orderActual: orderToCreate,
        orderStatus: "pending",
      });
      let newOrder = HotelDoc.collection("orders").doc(`${orderToCreate}`);
      let ordenParaGuardar = {
        orden: this.state.productos,
        status: "pending",
        numberOfTable: this.state.numberOfTable,
        numberOfOrder: orderToCreate,
        notify: false,
        date: `${new Date()}`.slice(0, 15),
      };
      newOrder.set(ordenParaGuardar);
      //Eliminar cosas de redux cuando se envia orden.
      this.props.emptyCart({});

      MySwal.fire({
        title: "Bien hecho!",
        text: "Tu pedido está en preparación.",
        icon: "success",
        confirmButtonColor: "#ff2068",
        confirmButtonText: "Continuar",
      });
      this.props.history.push(
        `/${this.props.match.params.idHotel}/${this.props.match.params.idRoom}`
      );
    });
  }

  render() {
    return (
      <div>
        <Cart
          // deleteClick={this.deleteClick}
          handlerSubmit={this.handlerSubmit}
          productos={this.state.productos}
          // priceTotal={this.state.priceTotal}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCartContainer);
