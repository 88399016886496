import React from "react";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import Register from "../../../client/components/views/Register";
import { connect } from "react-redux";
import firebase from "../../../services/firebase";
import Compress from "react-image-file-resizer";


const DB = firebase.db;
const STORAGE = firebase.storage;

class ClientRegisterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      mail: '',
      phone: '',
      // age: '',
      birthday: "2020-12-31",
      rol: 'user',
      active: true,
      contactSince: "",
      comments: '',
      photo: "",
      selectedFile: null,
      nameOfFile: null,
      groups: [],
      class: "",
      password: "",

      loader: false,
      userBoolean: false,
    };
    this.handlerChange = this.handlerChange.bind(this);
    this.handlerSubmit = this.handlerSubmit.bind(this);
    this.onFileResize = this.onFileResize.bind(this);
  }


  componentDidMount() {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    const date = new Date().getDate()
    let hours = new Date().getHours()
    let minutes = new Date().getMinutes()
    let contactSinceInfo = (date + "/" + month + "/" + year + " " + hours + ":" + minutes)

    this.setState({ contactSince: contactSinceInfo })
  }


  handlerChange(e) {
    const key = e.target.name;
    const value = e.target.value;

    this.setState({
      [key]: value,
    });
  }

  onFileResize = e => {
    const file = e.target.files[0];
    this.setState({ nameOfFile: file.name })

    Compress.imageFileResizer(
      file, // the file from input
      400, // width
      400, // height
      "JPEG", // compress format WEBP, JPEG, PNG
      70, // quality
      0, // rotation
      (uri) => {
        // console.log(uri);
        // You upload logic goes here
        this.setState({ selectedFile: uri })
        // this.setState({ selectedFile.name: uri })
      },
      "blob" // blob or base64 default base64
    );
  }

  handlerSubmit(e) {
    e.preventDefault();

    this.setState({
      loader: true,
      userBoolean: false
    });

    let personRegister = DB.collection("church").doc("02NyPwGGExwDiTMeI70m").collection("people").where('mail', '==', this.state.mail)


    personRegister.get().then((result) => {

      result.forEach((resultDoc) => {

        this.setState({ userBoolean: true })


        //aca mensaje popup de ya se registro con ese mail.
        Swal.fire({
          title: "Mail registrado",
          text: "Ya te registraste con ese mail anteriormente.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#339AE7",
          confirmButtonText: "Continuar",
        });

        this.setState({
          loader: false,
        });

      })


      //si el userBoolean esta en false decir que no esta en la base.
      if (this.state.userBoolean === false) {





        const file = this.state.selectedFile
        const nameOfFile = `${this.state.firstName}-${this.state.lastName}-${this.state.mail}-${this.state.nameOfFile}`
        const fileRef = STORAGE.child(nameOfFile)


        // console.log(this.state)

        this.setState({
          loader: true,
        });

        fileRef.put(file).then(() => {
          // console.log("Uploaded a file")
          STORAGE.child(nameOfFile).getDownloadURL().then(url => {
            //this.setState({ photo: url })

            //     //logica de asignar a una clase  
            let ageToAsign = this.state.birthday
            let ageOk = ageToAsign.replaceAll('-', '')
            let ageNumber = parseInt(ageOk)

            if (ageNumber < 19980701 && ageNumber >= 19910701) {
              this.setState({ class: '23+' })
            }
            if (ageNumber < 19990701 && ageNumber >= 19980701) {
              this.setState({ class: '22' })
            }
            if (ageNumber < 20000701 && ageNumber >= 19990701) {
              this.setState({ class: '21' })
            }
            if (ageNumber < 20010701 && ageNumber >= 20000701) {
              this.setState({ class: '20' })
            }
            if (ageNumber < 20020701 && ageNumber >= 20010701) {
              this.setState({ class: '19' })
            }
            if (ageNumber < 20030701 && ageNumber >= 20020701) {
              this.setState({ class: '18' })
            }
            if (ageNumber < 20040701 && ageNumber >= 20030701) {
              this.setState({ class: '17' })
            }
            if (ageNumber < 20050701 && ageNumber >= 20040701) {
              this.setState({ class: '16' })
            }
            if (ageNumber < 20060701 && ageNumber >= 20050701) {
              this.setState({ class: '15' })
            }
            if (ageNumber < 20070701 && ageNumber >= 20060701) {
              this.setState({ class: '14' })
            }
            if (ageNumber < 20080701 && ageNumber >= 20070701) {
              this.setState({ class: '13' })
            }

            let churchDoc = DB.collection("church")
              .doc("02NyPwGGExwDiTMeI70m").collection("people").doc();

            churchDoc.set(
              {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                mail: this.state.mail,
                phone: this.state.phone,
                // age: this.state.age,
                rol: this.state.rol,
                active: this.state.active,
                contactSince: this.state.contactSince,
                comments: this.state.comments,
                photo: url,
                groups: this.state.groups,
                class: this.state.class,
                birthday: this.state.birthday,
                password: this.state.password,
              }
            );

            this.props.history.push("/coming-soon");
          })
        })




      }




    })

  }

  render() {
    return (
      <div>

        <Register
          handleClick={this.handleClick}
          handlerChange={this.handlerChange}
          handlerSubmit={this.handlerSubmit}
          handlerPhoto={this.handlerPhoto}
          onFileResize={this.onFileResize}
          birthday={this.state.birthday}
          loader={this.state.loader}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, state) => {
  return {};
};

export default withRouter(
  connect(null, mapDispatchToProps)(ClientRegisterContainer)
);
