import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//const Background =
//  "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Falmuerzo-cover.png?alt=media&token=720f0451-55d2-481b-a401-282fd2d34fcf";

const styles = {
    container: {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: '#008477',
        // flex:"12",
    },
    containerFlex: {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        justifyContent: "center",
        alignItems: "center",
    },


    upScreen: {
        display: "flex",
        flex: 10,
        flexDirection: "column",
        backgroundColor: "white",
        overflow: "auto",
        //     borderRadius: "0 0 25px 0",
    },


    head: {
        flex: 2,
        display: "flex",
        justifyContent: "center",
        flexFlow: "column",
        alignItems: "flex-start",
        paddingLeft: "30px",
        backgroundColor: "white",
        borderBottom: "3px solid white",

        // margin: "30px",
    },

    headWeb: {
        flex: 2,
        display: "flex",
        justifyContent: "center",
        flexFlow: "column",
        alignItems: "flex-start",
        padding: "50px",
        paddingBottom: "0px",
        backgroundColor: "white",
        borderBottom: "3px solid white",
    },
    title: {
        margin: 0,
        marginTop: 30,
        fontSize: 30,
        // fontWeight: "bold",
        color: "#000",
        // fontSize: 65,
        // fontFamily: "SimpleJoys",
        fontFamily: "LatoBlack",
    },

    text: {
        margin: 0,
        marginTop: 30,
        fontSize: 22,
        color: "rgb(100,100,100)",
        fontFamily: "LatoBold",
    },

    titleWeb: {
        margin: 0,
        fontSize: 40,
        // fontWeight: "bold",
        color: "#000",
        fontFamily: "LatoBlack",
        //fontSize: 65,
        //fontFamily: "SimpleJoys",
    },
    subtitle: {
        margin: 0,
        marginRight: 20,
        marginTop: "5px",
        marginBottom: "20px",
        fontSize: 14,
        fontWeight: 400,
        color: "grey",
        //fontSize: 65,
        // fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },
    subtitleWeb: {
        margin: 0,
        marginTop: "10px",
        marginBottom: "10px",
        fontSize: 18,
        //fontWeight: 400,
        color: "grey",
        //fontSize: 65,
        //fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },
    actionsContainer: {
        display: "flex",
        flex: 2,
        justifyContent: 'space-around',
        alignItems: "center",
        backgroundColor: "white",
        margin: 0,
        padding: '0 15px'
        //  overflow: "auto",
    },

    propertiesContainer: {
        flex: 8,
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "white",
        overflow: "auto",
        // margin: '30px 0'
        margin: '0px 0px 30px 0px'
    },

    imageCover: {
        display: "flex",
        margin: 0,
        height: "140px",
        width: "100%",
        borderRadius: "15px",
        objectFit: "cover",
    },

    publicCover: {
        display: "flex",
        margin: 0,
        height: "120px",
        width: "100%",
        objectFit: "cover",
    },


    imageRightNow: {
        //width: "100%",
        borderRadius: "15px",
        objectFit: "cover",
        height: '30px',
        margin: '10px'
    },

    imageCoverWeb: {
        width: "100%",
        // height:"auto",
        borderRadius: "20px",
        objectFit: "cover",
    },

    imagePointersWeb: { margin: 0, height: "25px" },

    imagePointers: { margin: 0, height: "15px" },

    info: {
        display: "flex",
        flex: 4,
        margin: 0,
        width: "100%",
        backgroundColor: "white",
        borderRadius: "25px",
        padding: "5px",
        flexDirection: "column"
    },

    newsTitle: {
        margin: 0,
        fontSize: 20,
        padding: "10px 5px 0px 5px",
        paddingTop: "10px",
        fontFamily: "LatoBold",
    },
    newsDescription: {
        margin: 0,
        fontSize: 16,
        padding: "5px",
        paddingTop: "10px",
        paddingBottom: "15px",
        fontFamily: "Lato",
    },

    right: {
        display: "flex",
        flex: 4,
        flexFlow: "column",
        margin: 0,
        padding: "5px",
        justifyContent: "center",
        alignItems: "flex-end",
    },

    rightWeb: {
        display: "flex",
        flex: 4,
        flexFlow: "column",
        margin: 0,
        padding: "5px",
        justifyContent: "center",
        alignItems: "flex-end",
    },



    numbers: {
        flex: 12,
        display: "flex",
        margin: 0,
        fontSize: 11,
        // fontWeight: "bold",
        color: "#000",
        padding: "5px",
        paddingTop: "15px",
        //fontFamily: "LoewMedium",
        fontFamily: "LatoBlack",
    },

    leftNumbersWeb: {
        flex: 12,
        display: "flex",
        alignItems: "center",
        margin: 0,
        padding: "15px 0 15px 5px",
    },

    leftNumbers: {
        flex: 12,
        display: "flex",
        alignItems: "center",
        margin: 0,
        padding: "5px 0 5px 5px",
    },

    numbersWeb: {
        margin: 0,
        fontSize: 18,
        // fontWeight: "bold",
        color: "#000",
        padding: "0 10px",
        marginRight: "20px",
        //fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },

    numbersCell: {
        margin: 0,
        fontSize: 16,
        //fontWeight: "bold",
        color: "#000",
        padding: "0 10px",
        marginRight: "5px",
        //fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },



    envivoActive: {
        flex: 12,
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        margin: 0,
        fontSize: 20,
        color: "#008477",
        padding: "13px",
        // fontFamily: "LoewMedium",
        fontFamily: "LatoBold",
    },

    envivo: {
        flex: 12,
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        margin: 0,
        fontSize: 20,
        color: "#fff",
        padding: "13px",
        // fontFamily: "LoewMedium",
        fontFamily: "LatoBold",
    },

    directionWeb: {
        flex: 12,
        display: "flex",
        margin: 0,
        fontSize: 16,
        color: "grey",
        padding: "5px",
        paddingTop: "5px",
        paddingBottom: "15px",
        // fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },

    price: {
        margin: 0,
        fontSize: 15,
        //fontWeight: "bold",
        color: "#000",
        // fontFamily: "LoewMedium",
        fontFamily: "LatoBlack",

    },

    priceWeb: {
        margin: 0,
        fontSize: 22,
        // fontWeight: "bold",
        color: "#000",
        // fontFamily: "LoewMedium",
        fontFamily: "LatoBold",
    },

    buttonFloat: {
        position: "absolute",
        bottom: 10,
        right: 10,
        display: "flex",
        justifyContent: "flex-end",
        borderRadius: "50px",
    },

    downScreen: {
        display: "flex",
        flex: 1.5,
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor:'rgba(180,180,180)',
        backgroundColor: 'white',
        width: "100%",
    },

    buttonsBox: {
        display: "flex",
        flex: 12,
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: '#008477',
        // backgroundColor:'#000',
        height: "100%",
        width: "100%",
        borderRadius: "25px 0 0 0",
    },

    textBotones: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
        margin: '0'
    },


    imageFinal: { margin: 0, height: "35px" },
    textFinal: {
        margin: 0,
        fontSize: 14,
        color: "#fff",
        paddingTop: "15px",
    },


    link: {
        //padding: "20px",
    },
    icons: {
        color: '#fff'
    },

    iconsUp: {
        color: '#008477'
    },

    actions: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: "center",
        width: "20%",
    },

    actionsBox: {
        display: "flex",
        height: "60px",
        width: "100%",
        justifyContent: 'center',
        alignItems: "center",
        borderRadius: "15px",
        margin: "10px",
        backgroundColor: "#fff",
        textDecoration: "none",
        color: "inherit",
        boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)"
    },

    actionsText: {
        margin: 0,
        fontSize: 10,
        color: "#008477",
        fontFamily: "LatoBold",
    },

};

export default ({ news, loader, screenSize, handleClick, date, hour, user }) => {
    return (
        <>
            {loader == false ? (
                <>
                    {screenSize > 800 ? (
                        <>

                        </>
                    ) : (

                        <div style={styles.container}>
                            <div style={styles.upScreen}>
                                <div>
                                    <div style={styles.head}>
                                        {user.firstNameRedux ? (<h1 style={styles.title}>Hola {user.firstNameRedux}!</h1>) : (<h1 style={styles.title}>Bienvenido!</h1>)}

                                        <h6 style={styles.subtitle}>
                                            Que bueno saber de vos.
                </h6>
                                    </div>

                                    <div style={styles.propertiesContainer}>
                                        {news
                                            ? news.map((singleNew) => (
                                                <>
                                                    <Link
                                                        key={singleNew.id}
                                                        style={{
                                                            display: "flex",
                                                            //  height: "50px",
                                                            width: "90%",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            borderRadius: "25px",
                                                            margin: "10px",
                                                            textDecoration: "none",
                                                            color: "inherit",
                                                        }}
                                                    // to={`/news/${singleNew.id}/`}
                                                    >
                                                        <div style={styles.propertie}>
                                                            {singleNew.image !== "" ? (
                                                                <img
                                                                    style={styles.imageCover}
                                                                    src={singleNew.image}
                                                                    alt="..."
                                                                />
                                                            ) : (
                                                                <img
                                                                    style={styles.imageCover}
                                                                    src="https://i.postimg.cc/xC2cXrXW/image.png"
                                                                    alt="..."
                                                                />

                                                            )}
                                                            <div style={styles.info}>
                                                                <h4 style={styles.newsTitle}>
                                                                    {`${singleNew.title}`}
                                                                </h4>
                                                                <h4 style={styles.newsDescription}>
                                                                    {`${singleNew.description}`}
                                                                </h4>

                                                            </div>
                                                        </div>
                                                    </Link>

                                                </>
                                            ))
                                            : null}

                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                </>
            ) : (
                <div style={styles.containerFlex}>
                    <Loader
                        type="TailSpin"
                        //color="#ff2068"
                        color="#008477"
                        height={100}
                        width={100}
                        timeout={10000} //3 secs
                    />
                </div>
            )
            }
        </>
    );
};
