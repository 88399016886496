import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Products from "../../../restaurant/components/views/Products";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { hideLoader, showLoader } from "../../../store/actions/loginAction";
import {
  saveDownProductQuantity,
  saveAddDisableQuantity,
  saveDownDisableQuantity,
} from "../../../store/actions/hotelAction";
const DB = firebase.db;
let doc;

const MySwal = withReactContent(Swal);
// let local = JSON.parse(window.localStorage.getItem("persist:lovableLogin"));
// let userLS;
// if (local) {
//   userLS = JSON.parse(local.user);
// }

const mapStateToProps = (state) => {
  return {
    userLogin: state.user.loginUser.hotelId,
    rol: state.user.loginUser.rol,
    isAuth: state.user.isAuth,
    hotelInfoRedux: state.hotel.hotelInfo,
    hotelTotalMenues: state.hotel.totalMenues,
    hotelTotalCategories: state.hotel.totalCategories,
    hotelTotalProducts: state.hotel.totalProducts,
    hotelTotalDisableProducts: state.hotel.totalDisableProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveDownProductQuantity: (hotel) =>
      dispatch(saveDownProductQuantity(hotel)),
    saveAddDisableQuantity: (hotel) => dispatch(saveAddDisableQuantity(hotel)),
    saveDownDisableQuantity: (hotel) =>
      dispatch(saveDownDisableQuantity(hotel)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};


class ProductsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productsArray: [],
      withImages: true,

      productsStockBackUpState: [],

      hotel: {},
      hotelTotalMenues: 0,
      hotelTotalCategories: 0,
      hotelTotalProducts: 0,
      hotelTotalDisableProducts: 0,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleStock = this.handleStock.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }
  menuId = this.props.match.params.id;
  categoryId = this.props.match.params.categoryId;

  componentDidMount() {
    // if (userLS.isAuth === false) {
    //   this.props.history.push("/");
    // } else {
    if (this.props.hotelInfoRedux.name) {
      this.setState({
        hotelTotalMenues: this.props.hotelTotalMenues,
        hotelTotalCategories: this.props.hotelTotalCategories,
        hotelTotalProducts: this.props.hotelTotalProducts,
        hotelTotalDisableProducts: this.props.hotelTotalDisableProducts,
      });
    }
    if (this.props.userLogin) {
      this.props.showLoader();

      doc = DB.collection("hoteles")
        .doc(`${this.props.userLogin}`)
        .collection("menues")
        .doc(this.menuId)
        .collection("categories")
        .doc(this.categoryId);
      //Doc en este container es un array de IDS en referencia a todos los productos que van a haber
      doc.onSnapshot((productDocument) => {
        //hacer una copia de los productos original en el state
        this.setState({
          productsStockBackUpState: productDocument.data().products,
        });

        // cuando cambio stock o cuando  elimino, o cuando edito, compara nombre con state y poner index real.

        //ACA ORDENA LOS PRODUCTOS
        let productsWithOrder = productDocument.data().products;
        let ordenar = function (arr) {
          arr.sort(function (a, b) {
            return a.orderProduct - b.orderProduct;
          });
        };

        let ejecutandoFuntion = ordenar(productsWithOrder);
        //HASTA ACA
        this.setState({
          productsArray: productsWithOrder,
          withImages: productDocument.data().withImages,
        });
        setTimeout(() => {
          this.props.hideLoader();
        }, 500);
      });
    }
  }

  handleStock(e, id) {
    e.preventDefault();

    //aca comparar y generar el nuevo id.
    let productsWithOrderBackup = this.state.productsArray;
    let productsWithoutOrderBackup = this.state.productsStockBackUpState;

    let found = productsWithoutOrderBackup.findIndex(
      (element) =>
        element.name === productsWithOrderBackup[id].name &&
        element.price === productsWithOrderBackup[id].price
    );
    //

    let productsState = this.state.productsStockBackUpState;
    let boolean = productsState[found].stock;

    if (boolean) {
      let hotelInfo = DB.collection("hoteles").doc(`${this.props.userLogin}`);
      //let hotelInfo = DB.collection("hoteles").doc("wbKs9LDjm0SpFBZnqvst");
      let restaStock = this.state.hotelTotalDisableProducts + 1;
      hotelInfo.update({ productsWithoutStock: restaStock });
      this.setState({ hotelTotalDisableProducts: restaStock });
      //2. agregar en Redux una cantidad mas de menu.
      this.props.saveAddDisableQuantity(restaStock);

      productsState[found].stock = !boolean;

      this.setState({ productsStockBackUpState: productsState });

      doc.update({
        products: this.state.productsStockBackUpState,
      });
    } else {
      let hotelInfo = DB.collection("hoteles").doc(`${this.props.userLogin}`);
      //let hotelInfo = DB.collection("hoteles").doc("wbKs9LDjm0SpFBZnqvst");
      let sumaStock = this.state.hotelTotalDisableProducts - 1;
      hotelInfo.update({ productsWithoutStock: sumaStock });
      this.setState({ hotelTotalDisableProducts: sumaStock });
      //2. agregar en Redux una cantidad mas de menu.
      this.props.saveDownDisableQuantity(sumaStock);

      productsState[found].stock = !boolean;

      this.setState({ productsStockBackUpState: productsState });

      doc.update({
        products: this.state.productsStockBackUpState,
      });
    }
  }

  handleDelete(e, id) {
    // SI CANCELA EL DELETE DEBERIA QUEDARSE EN LA PAGINA
    e.preventDefault();

    //aca comparar y generar el nuevo id.
    let productsWithOrderBackup = this.state.productsArray;
    let productsWithoutOrderBackup = this.state.productsStockBackUpState;

    let found = productsWithoutOrderBackup.findIndex(
      (element) =>
        element.name === productsWithOrderBackup[id].name &&
        element.price === productsWithOrderBackup[id].price
    );
    //

    let doc = DB.collection("hoteles")
      .doc(this.props.userLogin)
      .collection("menues")
      .doc(this.menuId)
      .collection("categories")
      .doc(this.categoryId);

    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.value) {
        let hotelInfo = DB.collection("hoteles").doc(`${this.props.userLogin}`);
        //let hotelInfo = DB.collection("hoteles").doc("wbKs9LDjm0SpFBZnqvst");
        let restaProducts = this.state.hotelTotalProducts - 1;
        hotelInfo.update({ totalProducts: restaProducts });
        //2. agregar en Redux una cantidad mas de menu.
        this.props.saveDownProductQuantity(restaProducts);
        MySwal.fire("Deleted!", `Your product has been deleted.`, "success");
        //accion de 'eliminar'
        let productsState = this.state.productsStockBackUpState;
        productsState.splice(found, 1);
        this.setState({ productsStockBackUpState: productsState });
        doc.update({
          products: this.state.productsStockBackUpState,
        });
      }
      this.props.history.push(`/menu/${this.menuId}`);
    });
  }

  handleEdit(e, id) {
    e.preventDefault();

    //aca comparar y generar el nuevo id.
    let productsWithOrderBackup = this.state.productsArray;
    let productsWithoutOrderBackup = this.state.productsStockBackUpState;

    let found = productsWithoutOrderBackup.findIndex(
      (element) =>
        element.name === productsWithOrderBackup[id].name &&
        element.price === productsWithOrderBackup[id].price
    );
    console.log("encontrado:", found);
    //

    this.props.history.push(
      `/menu/${this.props.match.params.id}/${this.props.match.params.categoryId}/editProduct/${found}`
    );
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Products
          products={this.state.productsArray}
          images={this.state.withImages}
          menuId={this.props.match.params.id}
          catId={this.props.match.params.categoryId}
          deleteFunc={this.handleDelete}
          handleStock={this.handleStock}
          rol={this.props.rol}
          handleEdit={this.handleEdit}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer);
