import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import SimpleBag from "../../components/views/SimpleBag";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import {
    saveHotelId,
} from "../../../store/actions/hotelAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const DB = firebase.db;


const mapStateToProps = (state) => {
    return {
        hotelId: state.user.loginUser.hotelId,
        rol: state.user.loginUser.rol,
        isAuth: state.user.isAuth,
        userLogin: state.user.loginUser.hotelId,
        absences: state.hotel.absences,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
        saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
    };
};

class SimpleBagContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hotel: {},
            people: [],
            loader: true,
            oneAction: false,
            lideresCant: []
        };
        this.handleClick = this.handleClick.bind(this);
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.showLoader();
        if (this.props.hotelId) {
            let simpleBagDoc = DB.collection("countrys")
                .doc(`${this.props.hotelId}`)
                .collection("golfbags").doc(this.props.match.params.idBag)

            simpleBagDoc.onSnapshot((peopleBack) => {
                this.setState({
                    people: peopleBack.data()
                }, () => this.props.hideLoader())
            })
        }
    }

    handleClick(actionType, info1, info2) {
        MySwal.fire({
            title: "¿Estás seguro?",
            text: "Esta acción no se puede revertir.",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                if (actionType === "comments") {
                    let arrayOfComments = this.state.people.comments;
                    let found = arrayOfComments.findIndex(
                        (element) =>
                            element.comments === info1
                    );
                    if (found !== -1) {
                        if (this.props.hotelId) {
                            let bagDoc = DB.collection("countrys")
                                .doc(`${this.props.hotelId}`)
                                .collection("golfbags").doc(this.props.match.params.idBag)
                            let commentsArrayFromState = this.state.people.comments
                            commentsArrayFromState.splice(found, 1);
                            bagDoc.update({ comments: commentsArrayFromState })
                        }
                    }
                }
                if (actionType === "items") {
                    let arrayOfItems = this.state.people.items;
                    let found = arrayOfItems.findIndex(
                        (element) =>
                            element.name === info1 &&
                            element.description === info2
                    );
                    if (found !== -1) {
                        if (this.props.hotelId) {
                            let bagDoc = DB.collection("countrys")
                                .doc(`${this.props.hotelId}`)
                                .collection("golfbags").doc(this.props.match.params.idBag)
                            let itemsArrayFromState = this.state.people.items
                            itemsArrayFromState.splice(found, 1);
                            bagDoc.update({ items: itemsArrayFromState })
                        }
                    }
                }
            }
        })
    }

    render() {
        return (
            <div>
                <Sidebar />
                <SimpleBag
                    people={this.state.people}
                    handleClick={this.handleClick}
                    rol={this.props.rol}
                    lideresCant={this.state.lideresCant}
                    simpleBag={this.props.match.params.idBag}
                />
                <div>
                    <FullPageLoader />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleBagContainer);
