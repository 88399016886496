import React from "react";
import { Link } from "react-router-dom";

const styles = {
  container: {
    backgroundColor: "white",
    marginLeft: "250px",
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "20px",
  },
  createMenu: {
    textDecoration: "none",
    color: "#ffffff",
    backgroundColor: "#ff2068",
    borderColor: "#ff2068",
    marginTop: "20px",
    marginBottom: "10px",
  },
};

export default ({ outlets, menuObject, deleteFunc, rol }) => {
  return (
    <div style={styles.container}>
      <h1 className="font-weight-bold">Current Outlets</h1>

      {rol === "admin" ? (
        <Link
          style={styles.createMenu}
          className="btn btn-primary"
          to="/outlet/createOutlet"
        >
          Create Outlet
        </Link>
      ) : (
        <></>
      )}

      <hr />

      <ul
        className="list-group"
        style={{
          marginTop: "30px",
        }}
      >
        {outlets ? (
          outlets.map((outlet) => {
            return (
              <li
                key={outlet.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <Link
                  style={{
                    display: "flex",
                    flex: 2,
                  }}
                  to={`/outlets`}
                >
                  <img
                    style={{
                      maxWidth: "100px",
                      marginRight: "20px",
                      display: "flex",
                      flex: 12,
                    }}
                    src={outlet.imageOutlet}
                    alt="category img"
                  />
                </Link>
                <Link
                  style={{
                    display: "flex",
                    flex: 10,
                    //fontWeight: "bold",
                  }}
                  to={`/outlets`}
                >
                  {outlet.name}
                </Link>

                {rol === "admin" ? (
                  <div>
                    <Link to={`/outlet/${outlet.id}/editOutlet`}>
                      <button
                        style={{
                          backgroundColor: "#339AE7",
                          borderColor: "#339AE7",
                          marginRight: "20px",
                        }}
                        className="btn btn-primary btn-lg"
                      >
                        Edit
                      </button>
                    </Link>
                    <button
                      onClick={(e) => deleteFunc(e, outlet.id)}
                      style={{
                        backgroundColor: "#ff2068",
                        borderColor: "#ff2068",
                      }}
                      className="btn btn-primary btn-lg"
                    >
                      Delete
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </li>
            );
          })
        ) : (
          <li>No Outlets Found</li>
        )}
      </ul>
    </div>
  );
};
