import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Dashboard from "../../../restaurant/components/views/Dashboard";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import {
  saveHotelId,
} from "../../../store/actions/hotelAction";
const DB = firebase.db;

const mapStateToProps = (state) => {
  return {
    hotelId: state.user.loginUser.hotelId,
    rol: state.user.loginUser.rol,
    name: state.user.loginUser.name,
    isAuth: state.user.isAuth,
    userLogin: state.user.loginUser.hotelId,
    rooms: state.hotel.rooms,
    bags: state.hotel.absences,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
  };
};


class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: {},
      loader: true,
      hotelTotalMenues: 0,
      hotelTotalCategories: 0,
      hotelTotalProducts: 0,
      hotelTotalDisableProducts: 0,
      hotels: [],

      people: [],

      bags: [],
      bagsNoActive: [],

      oneAction: false,
      lideresCant: [],
    };
  }

  componentDidMount() {
    this.props.showLoader();
  }
  componentDidUpdate() {
    if (this.state.oneAction === false) {

      if (this.props.rol === "screen") {
        this.props.history.push(`/screen`);
      }

      if (this.props.rooms.lenth !== 0) {
        this.setState({
          people: this.props.rooms,
          bags: this.props.bags,
          loader: false,
          oneAction: true
        });
        let lideres = this.props.rooms
        let lideresFilter = lideres.filter(lider => lider.active === true)
        this.setState({
          lideresCant: lideresFilter
        });

        let bagsToUpdate = this.props.bags
        let bagsFilter = bagsToUpdate.filter(bag => bag.active === true)
        this.setState({
          bagsNoActive: bagsFilter
        }, () => this.props.hideLoader());
      }

    }
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Dashboard
          loader={this.state.loader}
          people={this.state.people}
          bags={this.state.bags}
          lideresCant={this.state.lideresCant}
          bagsNoActive={this.state.bagsNoActive}
          name={this.props.name}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
