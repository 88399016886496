import React from "react";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import Login from "../../../client/components/views/Login";
import { loginUserClient } from "../../../store/actions/loginAction";
import { connect } from "react-redux";
import firebase from "../../../services/firebase";

const DB = firebase.db;
const STORAGE = firebase.storage;


const mapStateToProps = (state) => {
  return {
    userLogin: state.user.loginUser,
    isAuth: state.user.isAuth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggeado: (firstName, lastName, birthday, classUser, mail, phone, photo, rol, liderOf) => dispatch(loginUserClient(firstName, lastName, birthday, classUser, mail, phone, photo, rol, liderOf)),
  };
};

class ClientLoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: '',
      password: '',
      userBoolean: false,
      loader: false,
    };
    this.handlerChange = this.handlerChange.bind(this);
    this.handlerSubmit = this.handlerSubmit.bind(this);
  }


  componentDidMount() {

    if (this.props.isAuth) {
      this.props.history.replace("/home");
    }
  }



  handlerChange(e) {
    const key = e.target.name;
    const value = e.target.value;

    this.setState({
      [key]: value,
    });
  }


  handlerSubmit(e) {
    e.preventDefault();

    this.setState({
      loader: true,
      userBoolean: false
    });


    let personLogin = DB.collection("church").doc("02NyPwGGExwDiTMeI70m").collection("people").where('mail', '==', this.state.mail)


    personLogin.get().then((result) => {

      result.forEach((resultDoc) => {
        //  console.log("llegue", doc.data())

        this.setState({ userBoolean: true })


        //POR AHORA SOLO PERMITIR A LOS LIDERES

        if (
          resultDoc.data().rol === "lider"
        ) {


          if (
            resultDoc.data().password
          ) {
            //la compara
            if (
              resultDoc.data().password == this.state.password
            ) {
              //aca avanza 


              if (
                resultDoc.data().rol === "lider"
              ) {

                this.props.loggeado(
                  resultDoc.data().firstName,
                  resultDoc.data().lastName,
                  resultDoc.data().birthday,
                  resultDoc.data().class,
                  resultDoc.data().mail,
                  resultDoc.data().phone,
                  resultDoc.data().photo,
                  resultDoc.data().rol,
                  resultDoc.data().liderOf,
                );

              } else {

                this.props.loggeado(
                  resultDoc.data().firstName,
                  resultDoc.data().lastName,
                  resultDoc.data().birthday,
                  resultDoc.data().class,
                  resultDoc.data().mail,
                  resultDoc.data().phone,
                  resultDoc.data().photo,
                  resultDoc.data().rol,
                  "no",
                );

              }

              this.props.history.push(`/home`);


            } else {
              //aca dice que la contraseña es incorrecta
              Swal.fire({
                title: "Oh no!",
                text: "La contraseña es incorrecta.",
                icon: "error",
                showCancelButton: false,
                confirmButtonColor: "#339AE7",
                confirmButtonText: "Continuar",
              });
            }
          } else {

            //  y sino la crea y loguea.
            let personDoc = DB.collection("church")
              .doc("02NyPwGGExwDiTMeI70m").collection("people").doc(resultDoc.id);

            console.log("llegue al mail pero no tengo contraseña")

            personDoc.update(
              {
                password: this.state.password,
              }
            );

            this.props.history.push(`/home`);
          }


        } else {

          Swal.fire({
            title: "Falta poco",
            text: "Todavía hay que esperar.",
            icon: "info",
            showCancelButton: false,
            confirmButtonColor: "#339AE7",
            confirmButtonText: "Continuar",
          });



        }





      });
      //si el userBoolean esta en false decir que no es ese mail.
      if (this.state.userBoolean === false) {
        Swal.fire({
          title: "Oh no!",
          text: "El mail es incorrecto.",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#339AE7",
          confirmButtonText: "Continuar",
        });
      }

      this.setState({
        loader: false,
      });

    }
    );

  }

  render() {
    return (
      <div>

        <Login
          handleClick={this.handleClick}
          handlerChange={this.handlerChange}
          handlerSubmit={this.handlerSubmit}
          birthday={this.state.birthday}
          loader={this.state.loader}
        />
      </div>
    );
  }
}

// const mapDispatchToProps = (dispatch, state) => {
//   return {};
// };



export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientLoginContainer)
);
