import React from "react";
import firebase from "../../../services/firebase";
import ComingSoon from "../../../client/components/views/ComingSoon";
import { connect } from "react-redux";
// import {
//   saveHotel,
//   saveHotelId,
//   saveLanguage,
// } from "../../../store/actions/hotelAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
//import { hideLoader, showLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../../restaurant/components/FullPageLoader/FullPageLoader";
import Confetti from 'react-confetti'

const DB = firebase.db;
const MySwal = withReactContent(Swal);

//let roomsOfHotel;
let hotelInfo;
let control;
//let controlPay;

const mapStateToProps = (state, ownprops) => {
  return {
    hotelId: state.hotel.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    // saveHotel: (hotel) => dispatch(saveHotel(hotel)),
    // saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
    // saveLanguage: (HotelId) => dispatch(saveLanguage(HotelId)),
  };
};

class ComingSoonContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      room: {},
      hotel: {},
      loader: true,
      confetti: false,
      sizeScreen: 0,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.setState({ loader: false, sizeScreen: window.screen.width });
  }

  handleClick(e) {
    this.setState({ confetti: !this.state.confetti })
  }

  render() {
    return (
      <div>
        {this.state.confetti === false ? (<></>) : (<Confetti numberOfPieces={200}
          width={window.screen.width} height={window.screen.height}
        />)}

        <ComingSoon
          handleClick={this.handleClick}
          loader={this.state.loader}
          confetti={this.state.confetti}
          screenSize={window.screen.width}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

//export default ClientViewContainer;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComingSoonContainer);
