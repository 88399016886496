import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//const Background =
//  "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Falmuerzo-cover.png?alt=media&token=720f0451-55d2-481b-a401-282fd2d34fcf";

const styles = {
    container: {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: '#008477',
        // flex:"12",
    },
    containerFlex: {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        justifyContent: "center",
        alignItems: "center",
    },


    upScreen: {
        display: "flex",
        flex: 10,
        flexDirection: "column",
        backgroundColor: "white",
        overflow: "auto",
        borderRadius: "0 0 25px 0",
    },


    head: {
        flex: 2,
        display: "flex",
        justifyContent: "center",
        flexFlow: "column",
        alignItems: "flex-start",
        paddingLeft: "30px",
        backgroundColor: "white",
        borderBottom: "3px solid white",

        // margin: "30px",
    },

    headWeb: {
        flex: 2,
        display: "flex",
        justifyContent: "center",
        flexFlow: "column",
        alignItems: "flex-start",
        padding: "50px",
        paddingBottom: "0px",
        backgroundColor: "white",
        borderBottom: "3px solid white",
    },
    title: {
        margin: 0,
        marginTop: 30,
        fontSize: 30,
        // fontWeight: "bold",
        color: "#000",
        // fontSize: 65,
        // fontFamily: "SimpleJoys",
        fontFamily: "LatoBlack",
    },

    titleWeb: {
        margin: 0,
        fontSize: 40,
        // fontWeight: "bold",
        color: "#000",
        fontFamily: "LatoBlack",
        //fontSize: 65,
        //fontFamily: "SimpleJoys",
    },
    subtitle: {
        margin: 0,
        marginRight: 20,
        marginTop: "5px",
        marginBottom: "20px",
        fontSize: 14,
        fontWeight: 400,
        color: "grey",
        //fontSize: 65,
        // fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },
    subtitleWeb: {
        margin: 0,
        marginTop: "10px",
        marginBottom: "10px",
        fontSize: 18,
        //fontWeight: 400,
        color: "grey",
        //fontSize: 65,
        //fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },
    propertiesContainer: {
        flex: 10,
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "white",
        overflow: "auto",
        marginBottom: '10px'
    },

    propertiesContainerWeb: {
        flex: 10,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "white",
        overflow: "auto",
        padding: "20px",
    },

    propertie: {
        display: "flex",
        flex: 12,
        flexFlow: "column",
        margin: 0,
        width: "100%",
        borderRadius: "25px",
    },

    propertieWeb: {
        display: "flex",
        flex: 12,
        flexFlow: "column",
        margin: 0,
        width: "100%",
        borderRadius: "25px",
    },

    cover: {
        display: "flex",
        flex: 12,
        margin: 0,
        width: "100%",
        borderRadius: "25px",
        height: "240px",
    },

    coverWeb: {
        display: "flex",
        flex: 4,
        margin: 0,
        maxHeight: "300px",
    },

    imageCover: {
        width: "100%",
        borderRadius: "15px",
        objectFit: "cover",
        height: "238px",
    },

    // imageCoverWeb: {
    //     width: "100%",
    //     // height:"auto",
    //     borderRadius: "20px",
    //     objectFit: "cover",
    // },

    imagePointersWeb: { margin: 0, height: "25px" },

    imagePointers: { margin: 0, height: "15px" },

    info: {
        display: "flex",
        flex: 5,
        margin: 0,
        width: "100%",
        backgroundColor: "white",
        borderRadius: "25px",
        padding: "5px",
    },

    left: {
        display: "flex",
        flex: 8,
        flexFlow: "column",
        margin: 0,
    },

    right: {
        display: "flex",
        flex: 4,
        flexFlow: "column",
        margin: 0,
        padding: "5px",
        justifyContent: "center",
        alignItems: "flex-end",
    },

    rightWeb: {
        display: "flex",
        flex: 4,
        flexFlow: "column",
        margin: 0,
        padding: "5px",
        justifyContent: "center",
        alignItems: "flex-end",
    },



    numbers: {
        flex: 12,
        display: "flex",
        margin: 0,
        fontSize: 11,
        // fontWeight: "bold",
        color: "#000",
        padding: "5px",
        paddingTop: "15px",
        //fontFamily: "LoewMedium",
        fontFamily: "LatoBlack",
    },

    leftNumbersWeb: {
        flex: 12,
        display: "flex",
        alignItems: "center",
        margin: 0,
        padding: "15px 0 15px 5px",
    },

    leftNumbers: {
        flex: 12,
        display: "flex",
        alignItems: "center",
        margin: 0,
        padding: "5px 0 5px 5px",
    },

    numbersWeb: {
        margin: 0,
        fontSize: 18,
        // fontWeight: "bold",
        color: "#000",
        padding: "0 10px",
        marginRight: "20px",
        //fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },

    numbersCell: {
        margin: 0,
        fontSize: 16,
        //fontWeight: "bold",
        color: "#000",
        padding: "0 10px",
        marginRight: "5px",
        //fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },

    news: {
        flex: 12,
        display: "flex",
        margin: 0,
        fontSize: 20,
        // color: "grey",
        padding: "5px",
        paddingTop: "10px",
        paddingBottom: "15px",
        // fontFamily: "LoewMedium",
        fontFamily: "LatoBold",
    },

    directionWeb: {
        flex: 12,
        display: "flex",
        margin: 0,
        fontSize: 16,
        color: "grey",
        padding: "5px",
        paddingTop: "5px",
        paddingBottom: "15px",
        // fontFamily: "LoewMedium",
        fontFamily: "Lato",
    },

    price: {
        margin: 0,
        fontSize: 15,
        //fontWeight: "bold",
        color: "#000",
        // fontFamily: "LoewMedium",
        fontFamily: "LatoBlack",

    },

    priceWeb: {
        margin: 0,
        fontSize: 22,
        // fontWeight: "bold",
        color: "#000",
        // fontFamily: "LoewMedium",
        fontFamily: "LatoBold",
    },

    buttonFloat: {
        position: "absolute",
        bottom: 10,
        right: 10,
        display: "flex",
        justifyContent: "flex-end",
        borderRadius: "50px",
    },

    downScreen: {
        display: "flex",
        flex: 1.5,
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor:'rgba(180,180,180)',
        backgroundColor: 'white',
        width: "100%",
    },

    buttonsBox: {
        display: "flex",
        flex: 12,
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: '#008477',
        // backgroundColor:'#000',
        height: "100%",
        width: "100%",
        borderRadius: "25px 0 0 0",
    },


    textBotones: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
        margin: '0'
    },


    imageFinal: { margin: 0, height: "35px" },
    textFinal: {
        margin: 0,
        fontSize: 14,
        color: "#fff",
        paddingTop: "15px",
    },
    link: {
        //padding: "20px",
    },
    icons: {
        color: '#fff'
    },

    imagePDF: {
        height: "100vh",
    },

};

export default ({ wallpapers, loader, screenSize, handleClick, popUp, selectedImage }) => {
    return (
        <>
            {loader == false ? (
                <>
                    {screenSize > 800 ? (
                        <>

                        </>
                    ) : (


                        <>
                            {popUp === true ? (
                                <>
                                    <img style={styles.imagePDF} src={`${selectedImage}`} onClick={(e) => handleClick(e, "close")} />
                                </>
                            ) : (<>
                                <div style={styles.container}>
                                    <div style={styles.upScreen}>
                                        <div>
                                            <div style={styles.head}>
                                                <h1 style={styles.title}>Mapas</h1>
                                                <h6 style={styles.subtitle}>
                                                    Sabe llegar a dónde quieras!
                </h6>
                                            </div>

                                            <div style={styles.propertiesContainer}>
                                                {wallpapers
                                                    ? wallpapers.map((wallpaper) => (
                                                        <>


                                                            <div
                                                                key={wallpaper.id}
                                                                style={{
                                                                    display: "flex",
                                                                    //height: "231px",
                                                                    width: "90%",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    borderRadius: "25px",
                                                                    margin: "10px",
                                                                    textDecoration: "none",
                                                                    color: "inherit",
                                                                    border: 'none',
                                                                    backgroundColor: "#fff",
                                                                    outline: '0'

                                                                }}
                                                                onClick={(e) =>
                                                                    handleClick(e, wallpaper.photo)
                                                                }
                                                            >
                                                                {/* <div style={styles.cover}>
                                                                    <img
                                                                        style={styles.imageCover}
                                                                        src={wallpaper.photo}
                                                                        alt="..."
                                                                    />
                                                                </div> */}


                                                                <div style={styles.propertie}>
                                                                    <div style={styles.cover}>
                                                                        <img
                                                                            style={styles.imageCover}
                                                                            src={wallpaper.photo}
                                                                            alt="..."
                                                                        />
                                                                    </div>

                                                                    <div style={styles.info}>
                                                                        <div style={styles.left}>
                                                                            <h4 style={styles.news}>
                                                                                {wallpaper.title}
                                                                            </h4>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>


                                                        </>
                                                    ))
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={styles.downScreen}>

                                        <div style={styles.buttonsBox}>
                                            {/* <h4 style={styles.textBotones}>
                                Botones
                                </h4> */}


                                            <Link
                                                className="nav-link active"
                                                style={styles.link}
                                                to="/home"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
                                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                                                </svg>
                                            </Link>

                                            <Link
                                                className="nav-link active"
                                                style={styles.link}
                                                to="/hours"
                                            >


                                                <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
                                                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                                </svg>
                                            </Link>
                                            <Link
                                                className="nav-link active"
                                                style={styles.link}
                                                to="/maps"
                                            >



                                                <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-map-fill" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z" />
                                                </svg>


                                            </Link>
                                            {/* <Link
              className="nav-link active"
              style={styles.link}
              to="/actions"
            >
             <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
  <path d="M13.498.795l.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
</svg>

            </Link> */}
                                            {/* <Link
              className="nav-link active"
              style={styles.link}
              to="/ministries"
            >
             <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-grid" viewBox="0 0 16 16">
  <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
</svg>
            </Link> */}

                                            <Link
                                                className="nav-link active"
                                                style={styles.link}
                                                to="/profile"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" style={styles.icons} fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </>)} </>)}
                </>
            ) : (
                <div style={styles.containerFlex}>
                    <Loader
                        type="TailSpin"
                        //color="#ff2068"
                        color="#008477"
                        height={100}
                        width={100}
                        timeout={10000} //3 secs
                    />
                </div>
            )}
        </>
    );
};
