import React from "react";
import { Link } from "react-router-dom";

const styles = {
    container: {
        backgroundColor: "white",
        marginLeft: "250px",
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingTop: "20px",
    },
    createMenu: {
        textDecoration: "none",
        color: "#ffffff",
        backgroundColor: "#ff2068",
        borderColor: "#ff2068",
        marginTop: "20px",
        marginBottom: "10px",
    },
    textBold: {
        margin: 0,
        display: "flex",
        flex: 6,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },
    text2Bold: {
        margin: 0,
        display: "flex",
        flex: 2,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },
    text2BoldCenter: {
        margin: 0,
        display: "flex",
        flex: 2,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    text3Bold: {
        margin: 0,
        display: "flex",
        flex: 3,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },

    text: {
        margin: 0,
        display: "flex",
        flex: 6,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
    },
    text2: {
        margin: 0,
        display: "flex",
        flex: 2,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    text3: {
        margin: 0,
        display: "flex",
        flex: 3,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
    },
    divAbsences: {
        display: "flex",
        flex: 2,
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    text4: {
        margin: 0,
        fontSize: 10,
        color: "white",
        fontFamily: "Lato",
        backgroundColor: "green",
        padding: '4px 20px',
        borderRadius: "5px"
    },

    text5: {
        margin: 0,
        fontSize: 10,
        color: "white",
        fontFamily: "Lato",
        backgroundColor: "orange",
        padding: '4px 20px',
        borderRadius: "5px"
    },

    text6: {
        margin: 0,
        fontSize: 10,
        color: "white",
        fontFamily: "Lato",
        backgroundColor: "red",
        padding: '4px 20px',
        borderRadius: "5px"
    },

    titleAnalytic: {
        color: "#000",
        //  fontWeight: "bold",
        textAlign: "center",
        fontSize: "35px",
        fontFamily: "LatoBlack",
    },
    titleDashboard: {
        color: "#000",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "25px",
    },
    descriptionAnalytic: {
        color: "#000",
        textAlign: "center",
        // fontWeight: "400",
        marginBottom: "0px",
        fontFamily: "Lato",
    },
    textLatoBlack: {
        fontFamily: "LatoBlack",
    },
    textLato: {
        fontFamily: "Lato",
    },

};

export default ({ people, lideresCant, handleClick }) => {
    //  console.log("cantidad:", people.length)
    return (
        <div style={styles.container}>
            <h1 style={styles.textLatoBlack}>Personas</h1>

            {/* {rol === "admin" ? (
                <Link
                    style={styles.createMenu}
                    className="btn btn-primary"
                    to="/news/create"
                >
                    Crear nueva
                </Link>
            ) : (
                    <></>
                )} */}

            <hr />

            <div
                className="row row-cols-1 row-cols-md-3"
                style={{
                    marginTop: "30px",
                    // marginBottom: "20px",
                }}>
                <div className="col mb-4" >
                    <div className="card h-100" style={styles.shadow}>
                        <img
                            src="https://i.postimg.cc/zGK7LWVk/dashboard-person.png"
                            className="card-img-top"
                            alt="..."
                        />
                        <div className="card-body">
                            <h5 style={styles.titleAnalytic}>{people.length}</h5>
                            <p style={styles.descriptionAnalytic}>Personas Totales</p>
                        </div>
                    </div>
                </div>
                <div className="col mb-4" >
                    <div className="card h-100" style={styles.shadow}>
                        <img
                            src="https://i.postimg.cc/QCC8gtFV/dashboard-person.png"
                            className="card-img-top"
                            alt="..."
                        />
                        <div className="card-body">
                            <h5 style={styles.titleAnalytic}>{lideresCant.length}</h5>

                            <p style={styles.descriptionAnalytic}>Personas Activas</p>
                        </div>
                    </div>
                </div>
                <div className="col mb-4" >
                    <div className="card h-100" style={styles.shadow}>
                        <img
                            src="https://i.postimg.cc/66b5WdPZ/dashboard-noperson.png"
                            className="card-img-top"
                            alt="..."
                        />
                        <div className="card-body">
                            <h5 style={styles.titleAnalytic}>{people.length - lideresCant.length}</h5>

                            <p style={styles.descriptionAnalytic}>Personas Inactivas</p>
                        </div>
                    </div>
                </div>
            </div>

            <ul
                className="list-group"
                style={{
                    marginTop: "10px",
                    marginBottom: "10px"
                }}
            >
                <li
                    className="list-group-item d-flex justify-content-between align-items-center"
                >
                    <div
                        style={{
                            display: "flex",
                            flex: 12,
                        }}
                    >
                        <h1 style={styles.textBold}>Nombre</h1>
                        <h1 style={styles.text2BoldCenter}>Matrícula</h1>
                        <h1 style={styles.text2BoldCenter}>Bolsa</h1>
                        <h1 style={styles.text2BoldCenter}>Estado</h1>
                    </div>
                </li>
            </ul>
            <ul
                className="list-group"
                style={{
                    marginBottom: "100px"
                }}
            >
                {people ? (
                    people.map((person) => {
                        return (
                            <li
                                key={person.id}
                                className="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <button
                                    style={{
                                        display: "flex",
                                        flex: 12,
                                        backgroundColor: "white",
                                        textDecoration: "none",
                                        color: "inherit",
                                        border: 'none',
                                        outline: '0',
                                        padding: 0

                                    }}
                                // onClick={() => handleClick(person)}
                                //   to={`/outlets`}
                                >
                                    <h1 style={styles.text}>{person.firstName} {person.lastName}</h1>
                                    <h1 style={styles.text2}>   {person.numberSocio}</h1>

                                    {person.bags === true ? (
                                        <h1 style={styles.text2}>Con Bolsa</h1>
                                    ) : (
                                        <h1 style={styles.text2}>Sin Bolsa</h1>
                                    )}

                                    <div style={styles.divAbsences}>
                                        {person.active === true ? (
                                            <h1 style={styles.text4}>Activo</h1>
                                        ) : (
                                            <h1 style={styles.tex6}>Inactivo</h1>
                                        )}
                                    </div>
                                </button>
                            </li>
                        );
                    })
                ) : (
                    <li>Sin datos.</li>
                )}
            </ul>
        </div>
    );
};
