import React from "react";
import { withRouter } from "react-router-dom";
import firebase from "../../../services/firebase";
import { connect } from "react-redux";
import Navbar from "../../../restaurant/components/general/Navbar";
import { userLogout } from "../../../store/actions/loginAction";
import {
  saveHotel,
  saveHotelId,
  saveRooms,
  saveAbsences,
} from "../../../store/actions/hotelAction";
import { toast } from "react-toastify";

const DB = firebase.db;
let hotelDoc;
let orderQuery;

const mapStateToProps = (state) => {
  return {
    userLogin: state.user.loginUser.hotelId,
    hotelId: state.user.loginUser.hotelId,
    rol: state.user.loginUser.rol,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveHotel: (hotel) => dispatch(saveHotel(hotel)),
    saveRooms: (rooms) => dispatch(saveRooms(rooms)),
    saveAbsences: (absences) => dispatch(saveAbsences(absences)),
    logoutFn: () => dispatch(userLogout()),
  };
};

class NavbarContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      rooms: [],
      ordersArray: [],
      isOpen: false,
      hotel: {},
    };
    this.logoutButton = this.logoutButton.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  logoutButton(e) {
    e.preventDefault();
    firebase.logout();
    this.props.logoutFn();
    this.props.history.replace("/login");
  }

  toggleOpen() {
    if (!this.state.isOpen) {
      this.setState({
        isOpen: true,
      });
    } else {
      this.setState({
        isOpen: false,
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    if (this.props.hotelId) {

      let churchPeopleDoc = DB.collection("countrys")
        .doc(`${this.props.hotelId}`).collection("people").orderBy('firstName', 'asc')
      // .limit(14)

      churchPeopleDoc.onSnapshot((peopleBack) => {
        let peopleArray = [];
        peopleBack.forEach((people) => {
          peopleArray.push(people.data());
        });
        this.props.saveRooms(peopleArray);
      })

      let churchAbsencesDoc = DB.collection("countrys")
        .doc(`${this.props.hotelId}`).collection("golfbags").orderBy('fullName', 'asc')
      //  .limit(14)

      churchAbsencesDoc.onSnapshot((absenceBack) => {
        let absenceArray = [];
        absenceBack.forEach((absence) => {


          let objectTrasfer = {}
          objectTrasfer = absence.data()
          objectTrasfer.id = absence.id

          absenceArray.push(objectTrasfer);

          //  absenceArray.push(absence.data());

        });
        this.props.saveAbsences(absenceArray);
      });





      // churchGroupsDoc.onSnapshot((groupsBack) => {
      //   let groupsArray = [];
      //   groupsBack.forEach((group) => {
      //     //    console.log(group.data())

      //     let objectTrasfer = {}
      //     objectTrasfer = group.data()
      //     objectTrasfer.id = group.id

      //     groupsArray.push(objectTrasfer);
      //   });
      //   // console.log("array antes de subir", groupsArray)
      //   this.props.saveGroups(groupsArray);
      // });


    }
  }


  // hotelDoc = DB.collection("hoteles").doc(`${this.props.hotelId}`);
  // hotelDoc.onSnapshot((hotel) => {
  //   this.props.saveHotel(hotel.data());
  // });

  // GUARDA LA INFO DEL CHAT EN REDUX.
  // let hotelRoomsDoc = DB.collection("hoteles")
  //   .doc(`${this.props.hotelId}`)
  //   .collection("rooms");
  // hotelRoomsDoc.onSnapshot((rooms) => {
  //   let roomsArray = [];
  //   rooms.forEach((room) => {
  //     roomsArray.push(room.data());
  //   });
  //   this.props.saveRooms(roomsArray);
  //   // this.setState({
  //   //   chats: arrayChats,
  //   // });
  // });




  // if (this.props.hotelId) {
  //   hotelDoc = DB.collection("hoteles")
  //     .doc(`${this.props.userLogin}`)
  //     .collection("rooms");
  //   hotelDoc.onSnapshot((roomsDoc) => {
  //     let rooms = [];
  //     roomsDoc.forEach((change) => {
  //       if (
  //         change.data().pay ||
  //         change.data().clean ||
  //         change.data().orderStatus === "pending"
  //       )
  //         rooms.push({
  //           id: change.id,
  //           number: change.data().number,
  //           pay: change.data().pay,
  //           clean: change.data().clean,
  //           orderStatus: change.data().orderStatus,
  //         });
  //     });
  //     orderQuery = DB.collection("hoteles")
  //       .doc(`${this.props.userLogin}`)
  //       .collection("orders");
  //     orderQuery.onSnapshot((ordenes) => {
  //       let orders = [];
  //       ordenes.forEach((orden) => {
  //         if (
  //           orden.data().status === "pending" &&
  //           orden.data().notify === false
  //         ) {
  //           orders.push({
  //             id: orden.id,
  //             idUser: orden.data().idUser,
  //             numberOfOrder: orden.data().numberOfOrder,
  //             numberOfTable: orden.data().numberOfTable,
  //             status: orden.data().status,
  //             totalPrice: orden.data().totalPrice,
  //             notify: orden.data().notify,
  //             tableID: orden.data().tableID,
  //           });
  //         }
  //       });
  //       this.setState({ ordersArray: orders, rooms: rooms });
  //     });
  //   });
  // }


  render() {
    if (this.props.rol === "screen") {
      return (<></>)
    } else {
      return (
        <Navbar
          rooms={this.state.rooms}
          buttonClick={this.logoutButton}
          isOpen={this.toggleOpen}
          dropdown={this.state.isOpen}
        />
      );

    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavbarContainer)
);
