import React from "react";

const styles = {
  container: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
  },

  head: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    paddingLeft: "30px",
    backgroundColor: "white",
    borderBottom: "3px solid white",
  },
  nameOfMenu: {
    margin: 0,
    fontSize: 35,
    fontWeight: "bold",
    color: "#000",
  },
  descriptionOfMenu: {
    margin: 0,
    marginTop: "5px",
    fontSize: 16,
    fontWeight: 400,
    color: "grey",
  },
  cartContainer: {
    flex: 8,
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "auto",
    backgroundColor: "white",
  },

  card: {
    display: "flex",
    justifyContent: "flex-start",
    //alignItems: "center",
    height: "160px",
    width: "90%",
    borderRadius: "25px",
    margin: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "#4A4A4A",
    padding: "20px",
  },

  imageContainer: {
    //width: "100%",
    flex: 3,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "10px",
    paddingRight: "0px",
    borderRadius: "10px",
    backgroundColor: "white",
  },
  imageProduct: { width: "100%" },
  textContainer: {
    flex: 9,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column",
    marginLeft: "20px",
  },
  textProduct: {
    margin: 0,
    fontSize: 18,
    fontWeight: "600",
    color: "#ffffff",
  },
  textPrice: {
    margin: 0,
    fontSize: 22,
    fontWeight: "600",
    color: "#ffffff",
    //paddingTop: "6px",
  },
  textCantidad: {
    margin: 0,
    fontSize: 14,
    fontWeight: "400",
    color: "#ffffff",
    //paddingTop: "6px",
    //paddingBottom: "6px",
  },
  butttonSection: { flex: 1.5, display: "flex" },
  buttonCart: {
    height: "100%",
    width: "100%",
    backgroundColor: "#339AE7",
    borderColor: "#339AE7",
    fontSize: "20px",
    textAlign: "center",
    color: "#ffffff",
    borderRadius: "40px 40px 0 0",
  },
};

export default ({ productos, priceTotal, deleteClick, handlerSubmit }) => {
  return (
    <div style={styles.container}>
      <div style={styles.head}>
        <h1 style={styles.nameOfMenu}>Orden! 😎</h1>
        <h6 style={styles.descriptionOfMenu}>Todos los productos elegidos!</h6>
      </div>

      <div style={styles.cartContainer}>
        {productos
          ? productos.map((product) => {
              return (
                <div style={styles.card}>
                  <div style={styles.imageContainer}>
                    <img
                      src={product.imageProduct}
                      className="card-img"
                      alt="..."
                    />
                  </div>
                  <div style={styles.textContainer}>
                    <h1 style={styles.textProduct}>{product.name}</h1>
                    <h6 style={styles.textPrice}>$ {`${product.price}`}</h6>
                    <h6 style={styles.textCantidad}>
                      Cantidad: {product.cantidad}
                    </h6>
                    {/* <h6 style={styles.textProduct2}>{product.comentarios}</h6> */}
                    <button
                      style={{
                        //marginTop: "6px",
                        textDecoration: "none",
                        color: "#ffffff",
                        backgroundColor: "#ff2068",
                        borderColor: "#ff2068",
                        padding: "3px 12px",
                        fontSize: "13px",
                      }}
                      className="btn btn-primary"
                      onClick={(e) => deleteClick(e, product.id)}
                    >
                      Remover
                    </button>

                    {/* <h6>
                    Subtotal: $ {`${product.price * product.cantidad},00`}
                  </h6>  */}
                  </div>
                </div>
              );
            })
          : null}
      </div>

      {/* <h2
        className="font-weight-bold"
        style={{
          marginBottom: "20px",
        }}
      >
        Total ARS {priceTotal}
      </h2> */}

      <div style={styles.butttonSection}>
        <button
          style={styles.buttonCart}
          className="btn btn-primary"
          onClick={(e) => handlerSubmit(e)}
        >
          Enviar Pedido
        </button>
      </div>
    </div>
  );
};
