import React from "react";
import { Link } from "react-router-dom";

const styles = {
    container: {
        backgroundColor: "white",
        marginLeft: "250px",
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingTop: "20px",
    },
    createMenu: {
        textDecoration: "none",
        color: "#ffffff",
        backgroundColor: "#ff2068",
        borderColor: "#ff2068",
        marginTop: "20px",
        marginBottom: "10px",
    },
    createMenu2: {
        textDecoration: "none",
        color: "#ff2068",
        backgroundColor: "#fff",
        borderColor: "#ff2068",
        margin: "15px 10px 10px 0px",
    },
    createMenu3: {
        textDecoration: "none",
        color: "#ff2068",
        backgroundColor: "#fff",
        borderColor: "#ff2068",
        margin: "15px 0px 10px 0px",
    },
    text2Bold: {
        margin: 0,
        display: "flex",
        flex: 2,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },
    text3Bold: {
        margin: 0,
        display: "flex",
        flex: 3,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },
    text4Bold: {
        margin: 0,
        display: "flex",
        flex: 4,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },
    text2: {
        display: "flex",
        flex: 2,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
        margin: 0,
    },
    text3: {
        display: "flex",
        flex: 3,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
        margin: 0,
    },

    text4: {
        display: "flex",
        flex: 4,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
        margin: 0,
    },
    divAbsences: {
        display: "flex",
        flex: 2,
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    titleAnalytic: {
        color: "#000",
        //  fontWeight: "bold",
        textAlign: "center",
        fontSize: "35px",
        fontFamily: "LatoBlack",
    },
    titleDashboard: {
        color: "#000",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "25px",
    },
    descriptionAnalytic: {
        color: "#000",
        textAlign: "center",
        // fontWeight: "400",
        marginBottom: "0px",
        fontFamily: "Lato",
    },
    textLatoBlack: {
        fontFamily: "LatoBlack",
    },
    textLato: {
        fontFamily: "Lato",
    },
    textLatoBold: {
        fontFamily: "LatoBold",
        fontSize: "25px",
        margin: 0
    },
    textLatoBold2: {
        fontFamily: "LatoBold",
        fontSize: "25px",
        // margin: 0,
        margin: "25px 0px 0px 0px"
    },

};

export default ({ people, lideresCant, rol, handleClick, handleDelete, lastMoves }) => {
    //  console.log("cantidad:", people.length)
    return (
        <div style={styles.container}>
            <h1 style={styles.textLatoBlack}>Bolsas y Carros</h1>

            {/* {rol === "admin" ? (
                <Link
                    style={styles.createMenu}
                    className="btn btn-primary"
                    to="/bags/create"
                >
                    Agregar nuevo
                </Link>
            ) : (
                <></>
            )} */}

            <hr />

            {/* <div
                className="row row-cols-1 row-cols-md-3"
                style={{
                    marginTop: "30px",
                    // marginBottom: "20px",
                }}
            >
                <div className="col mb-4" >
                    <div className="card h-100" style={styles.shadow}>
                        <img
                            src="https://i.postimg.cc/zGK7LWVk/dashboard-person.png"
                            className="card-img-top"
                            alt="..."
                        />
                        <div className="card-body">
                            <h5 style={styles.titleAnalytic}>{people.length}</h5>
                            <p style={styles.descriptionAnalytic}>Total</p>
                        </div>
                    </div>
                </div>
                <div className="col mb-4" >
                    <div className="card h-100" style={styles.shadow}>
                        <img
                            src="https://i.postimg.cc/zGK7LWVk/dashboard-person.png"
                            className="card-img-top"
                            alt="..."
                        />
                        <div className="card-body">
                            <h5 style={styles.titleAnalytic}>{lideresCant.length}</h5>

                            <p style={styles.descriptionAnalytic}>Bolsas</p>
                        </div>
                    </div>
                </div>
                <div className="col mb-4" >
                    <div className="card h-100" style={styles.shadow}>
                        <img
                            src="https://i.postimg.cc/zGK7LWVk/dashboard-person.png"
                            className="card-img-top"
                            alt="..."
                        />
                        <div className="card-body">
                            <h5 style={styles.titleAnalytic}>{people.length - lideresCant.length}</h5>

                            <p style={styles.descriptionAnalytic}>Carros</p>
                        </div>
                    </div>
                </div>

            </div> */}
            <h1 style={styles.textLatoBold}>Últimos Movimientos</h1>
            {rol === "admin" ? (
                <>
                    <Link
                        style={styles.createMenu2}
                        className="btn btn-primary"
                        to={`/bags/lastmovements`}
                    >
                        Ver más movimientos
                </Link>
                    <button
                        style={styles.createMenu3}
                        className="btn btn-primary"
                        onClick={() => handleDelete()}
                    >
                        Eliminar pendientes
                </button>
                </>
            ) : (
                <></>
            )}
            <ul
                className="list-group"
                style={{
                    marginTop: "10px",
                    marginBottom: "10px"
                }}
            >
                <li
                    className="list-group-item d-flex justify-content-between align-items-center"
                >
                    <div
                        style={{
                            display: "flex",
                            flex: 12,
                        }}
                    >
                        <h1 style={styles.text3Bold}>Fecha</h1>
                        <h1 style={styles.text2Bold}>Acción</h1>
                        <h1 style={styles.text4Bold}>Nombre</h1>
                        <h1 style={styles.text4Bold}>Comentarios</h1>
                    </div>
                </li>
            </ul>

            <ul
                className="list-group"
                style={{
                    marginBottom: "20px"
                }}
            >
                {lastMoves.length ? (
                    lastMoves.map((lastmoveSingle, index) => {
                        if (index < 5) {
                            return (
                                <li
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                >
                                    <button
                                        style={{
                                            display: "flex",
                                            flex: 12,
                                            backgroundColor: "white",
                                            textDecoration: "none",
                                            color: "inherit",
                                            border: 'none',
                                            outline: '0',
                                            padding: 0

                                        }}
                                    //onClick={() => handleClick(lastmoveSingle.idBag)}
                                    >
                                        <h1 style={styles.text3}>{lastmoveSingle.date}</h1>
                                        <h1 style={styles.text2}>{lastmoveSingle.actionType}</h1>
                                        <h1 style={styles.text4}>{lastmoveSingle.fullName}</h1>
                                        <h1 style={styles.text4}>{lastmoveSingle.comments}</h1>
                                    </button>
                                </li>
                            )
                        }
                    })
                ) : (
                    <li className="list-group-item d-flex justify-content-between align-items-center" ><h1 style={styles.text3}>Sin movimientos</h1></li>
                )}
            </ul>
            <h1 style={styles.textLatoBold2}>Bolsas y Carros</h1>
            {rol === "admin" ? (
                <Link
                    style={styles.createMenu2}
                    className="btn btn-primary"
                    to="/bags/create"
                >
                    Agregar nueva
                </Link>
            ) : (
                <></>
            )}
            <ul
                className="list-group"
                style={{
                    marginTop: "10px",
                    marginBottom: "10px"
                }}
            >
                <li
                    className="list-group-item d-flex justify-content-between align-items-center"
                >
                    <div
                        style={{
                            display: "flex",
                            flex: 12,
                        }}
                    >
                        <h1 style={styles.text3Bold}>Tipo</h1>
                        <h1 style={styles.text2Bold}>Cantidad</h1>
                        <h1 style={styles.text4Bold}>Propietario</h1>
                        <h1 style={styles.text4Bold}>Matrícula</h1>
                    </div>
                </li>
            </ul>
            <ul
                className="list-group"
                style={{
                    marginBottom: "100px"
                }}
            >
                {people ? (
                    people.map((person) => {
                        return (
                            <li
                                key={person.id}
                                className="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <button
                                    style={{
                                        display: "flex",
                                        flex: 12,
                                        backgroundColor: "white",
                                        textDecoration: "none",
                                        color: "inherit",
                                        border: 'none',
                                        outline: '0',
                                        padding: 0

                                    }}
                                    onClick={() => handleClick(person.id)}
                                //   to={`/outlets`}
                                >
                                    <h1 style={styles.text3}>Bolsa {person.brand}</h1>
                                    <h1 style={styles.text2}>{person.items.length}</h1>
                                    <h1 style={styles.text4}>{person.fullName}</h1>
                                    <h1 style={styles.text4}>{person.numberSocio}</h1>
                                </button>
                            </li>
                        );
                    })
                ) : (
                    <li className="list-group-item d-flex justify-content-between align-items-center"><h1 style={styles.text2}>Sin datos</h1></li>
                )}
            </ul>
        </div>
    );
};
