import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import Chat from "../../../restaurant/components/views/Chat";
import { hideLoader, showLoader } from "../../../store/actions/loginAction";
import { connect } from "react-redux";

const DB = firebase.db;

const MySwal = withReactContent(Swal);

class ChatContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rooms: [],
    };
    this.handlerSubmit = this.handlerSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.hotelId) {
      this.props.dispatch(showLoader());
      this.setState({
        rooms: this.props.rooms,
      });
      setTimeout(() => {
        this.props.dispatch(hideLoader());
      }, 500);
    }
  }

  handlerSubmit(e) {
    e.preventDefault();
    console.log("hola", e);
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Chat rooms={this.state.rooms} handlerSubmit={this.handlerSubmit} />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hotelId: state.user.loginUser.hotelId,
    rooms: state.hotel.rooms,
  };
};

export default connect(mapStateToProps)(ChatContainer);
