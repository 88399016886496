import React from "react";
import Donations from "../../components/views/Donations";
import firebase from "../../../services/firebase";
const DB = firebase.db;

class DonationsContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      messages: [],
      loader: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
      this.setState({
      loader: false,
    });
    }

  render() {
    return (
      <div>
        <Donations
          messages={this.state.messages}
          loader={this.state.loader}
          screenSize={window.screen.width}
        />
      </div>
    );
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(PropiedadesCliente);

export default DonationsContainer;
