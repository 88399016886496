import React from "react";
import { Link } from "react-router-dom";

const styles = {
  container: {
    backgroundColor: "white",
    marginLeft: "250px",
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "20px",
  },
  createMenu: {
    textDecoration: "none",
    color: "#ffffff",
    backgroundColor: "#ff2068",
    borderColor: "#ff2068",
    marginTop: "20px",
    marginBottom: "10px",
  },
  textLatoBlack: {
    fontFamily: "LatoBlack",
  },
  textLato: {
    fontFamily: "Lato",
  },
};

export default ({ submit, input }) => {
  return (
    <div style={styles.container}>
      <h1 style={styles.textLatoBlack} className="font-weight-bold">Crear Novedad</h1>

      <hr />
      <form
        onSubmit={(e) => submit(e)}
        style={{
          margin: "30px",
        }}
      >
        <div
          className="form-group"
          style={{
            paddingBottom: "20px",
          }}
        >
          <label
            style={{
              marginTop: "20px",
            }}
          >
            Título
              </label>
          <input
            onChange={(e) => input(e)}
            name="title"
            type="text"
            className="form-control"
            placeholder="Ingresar título..."
            required
          />
          <label
            style={{
              marginTop: "20px",
            }}
          >
            Descripción
              </label>
          <input
            onChange={(e) => input(e)}
            name="description"
            type="text"
            className="form-control"
            placeholder="Ingresar descripción..."
            required
          />
          <label
            style={{
              marginTop: "20px",
            }}
          >
            Imagen
              </label>
          <input
            onChange={(e) => input(e)}
            name="image"
            type="text"
            className="form-control"
            placeholder="Ingresar URL..."
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary"
          style={{
            backgroundColor: "#FF2068",
            borderColor: "#FF2068",
          }}
        >
          Crear novedad
            </button>
      </form>
    </div>
  );
};
