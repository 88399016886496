import React from "react";
import { Link } from "react-router-dom";

const styles = {
    container: {
        backgroundColor: "white",
        marginLeft: "250px",
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingTop: "20px",
    },
    createMenu: {
        textDecoration: "none",
        color: "#ffffff",
        backgroundColor: "#ff2068",
        borderColor: "#ff2068",
        marginTop: "20px",
        marginBottom: "10px",
    },
    textLatoBlack: {
        fontFamily: "LatoBlack",
    },
    textLato: {
        fontFamily: "Lato",
    },
    textLatoBlack2: {
        fontFamily: "LatoBlack",
        margin: "40px 0px 10px"
    },
    divPhoto: {
        display: "flex",
        flex: 2,
    },
    photo: {
        border: "0.1 px solid #000",
        width: '50px',
    },
    text4: {
        margin: 0,
        display: "flex",
        flex: 4,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBlack",
        margin: 0,
    },
    text2: {
        margin: 0,
        display: "flex",
        flex: 6,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
        margin: 0,
    },

};

export default ({ submit, input, stateItems, handleClick }) => {
    return (
        <div style={styles.container}>
            <h1 style={styles.textLatoBlack} className="font-weight-bold">Crear Bolsa</h1>

            <hr />

            <form
                onSubmit={(e) => submit(e)}
                style={{
                    margin: "30px",
                }}
            >
                <h4 style={styles.textLatoBlack2} className="font-weight-bold">Propietario</h4>
                <div
                    className="form-group"
                >
                    <label
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Nombre
                    </label>
                    <input
                        onChange={(e) => input(e)}
                        name="firstName"
                        type="text"
                        className="form-control"
                        placeholder="Ingresar nombre..."
                        required
                    />
                    <label
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Apellido
                    </label>
                    <input
                        onChange={(e) => input(e)}
                        name="lastName"
                        type="text"
                        className="form-control"
                        placeholder="Ingresar apellido..."
                        required
                    />
                    <label
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Número de Socio
                    </label>
                    <input
                        onChange={(e) => input(e)}
                        name="numberSocio"
                        type="text"
                        className="form-control"
                        placeholder="Ingresar número de socio..."
                        required
                    />
                    <label
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Teléfono
                    </label>
                    <input
                        onChange={(e) => input(e)}
                        name="phone"
                        type="text"
                        className="form-control"
                        placeholder="Ingresar teléfono..."
                    />
                    <label
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Mail
                    </label>
                    <input
                        onChange={(e) => input(e)}
                        name="mail"
                        type="text"
                        className="form-control"
                        placeholder="Ingresar mail..."
                    />
                </div>
                <h4 style={styles.textLatoBlack2} className="font-weight-bold">Bolsa</h4>
                <div
                    className="form-group"
                    style={{
                        paddingBottom: "20px",
                    }}
                >
                    <label
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Marca de Bolsa
                    </label>
                    <input
                        onChange={(e) => input(e)}
                        name="brand"
                        type="text"
                        className="form-control"
                        placeholder="Ingresar marca de bolsa..."
                        required
                    />
                    <h4 style={styles.textLatoBlack2} className="font-weight-bold">{`Items (${stateItems.length})`}</h4>
                    <ul
                        className="list-group"
                        style={{
                            margin: "30px 0px 10px 0px"
                        }}
                    >
                        {stateItems ? (
                            stateItems.map((singleItem) => {
                                return (
                                    <li
                                        className="list-group-item d-flex justify-content-between align-items-center"
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flex: 12,
                                                backgroundColor: "white",
                                                textDecoration: "none",
                                                color: "inherit",
                                                border: 'none',
                                                outline: '0',
                                                padding: 0,
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div style={styles.divPhoto}>
                                                {singleItem.photo !== "" ? (<img
                                                    src={singleItem.photo}
                                                    alt="profile picture"
                                                    style={styles.photo}
                                                />) : (
                                                    <img
                                                        style={styles.photo}
                                                        src="https://i.postimg.cc/nh8pTvhc/golfstick-pink.png"
                                                        alt="..."
                                                    />
                                                )}
                                            </div>
                                            <h1 style={styles.text4}>{singleItem.name}</h1>
                                            <h1 style={styles.text2}>{singleItem.description}</h1>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                backgroundColor: "white",
                                                textDecoration: "none",
                                                color: "inherit",
                                                border: 'none',
                                                outline: '0',
                                                padding: 0,
                                                alignItems: 'center',
                                                margin: '0px 5px 0px 0px'
                                            }}
                                            onClick={() => handleClick(singleItem.name)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                                            </svg>
                                        </div>
                                    </li>
                                );
                            })
                        ) : (
                            <li
                                className="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <h1 style={styles.text2}>Sin items</h1>
                            </li>
                        )}
                    </ul>
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                        backgroundColor: "#FF2068",
                        borderColor: "#FF2068",
                        marginBottom: '100px',
                        width: '180px'
                    }}
                >
                    Crear bolsa
                </button>
            </form>
        </div>
    );
};
