import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Menu from "../../../restaurant/components/create/Menu";
import { connect } from "react-redux";
import { saveAddMenuQuantity } from "../../../store/actions/hotelAction";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
const DB = firebase.db;
// let local = JSON.parse(window.localStorage.getItem("persist:lovableLogin"));
// let userLS;
// if (local) {
//   userLS = JSON.parse(local.user);
// }

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.isAuth,
    userLogin: state.user.loginUser.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
    hotelTotalMenues: state.hotel.totalMenues,
    hotelTotalCategories: state.hotel.totalCategories,
    hotelTotalProducts: state.hotel.totalProducts,
    hotelTotalDisableProducts: state.hotel.totalDisableProducts,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveAddMenuQuantity: (hotel) => dispatch(saveAddMenuQuantity(hotel)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

class CreateMenuContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      nameOfMenu: "",

      nameOfMenuSpanish: "",
      nameOfMenuEnglish: "",
      nameOfMenuPortuguese: "",
      nameOfMenuFrench: "",

      imageMenu: "",
      hours: "",
      orderMenu: 1,

      aclarationSpanish: "",
      aclarationEnglish: "",
      aclarationPortuguese: "",
      aclarationFrench: "",

      hotel: {},
      hotelTotalMenues: 0,
      hotelTotalCategories: 0,
      hotelTotalProducts: 0,
      hotelTotalDisableProducts: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }
  componentDidMount() {
    if (this.props.hotelInfoRedux.name) {
      this.setState({
        hotel: this.props.hotelInfoRedux,
        hotelTotalMenues: this.props.hotelTotalMenues,
        hotelTotalCategories: this.props.hotelTotalCategories,
        hotelTotalProducts: this.props.hotelTotalProducts,
        hotelTotalDisableProducts: this.props.hotelTotalDisableProducts,
      });
    }
    // if (userLS.isAuth === false) this.props.history.push("/");
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.showLoader();
    let doc = DB.collection("hoteles")
      .doc(`${this.props.userLogin}`)
      .collection("menues")
      .doc();
    doc
      .set({
        nameOfMenu: this.state.nameOfMenu,

        nameOfMenuSpanish: this.state.nameOfMenuSpanish,
        nameOfMenuEnglish: this.state.nameOfMenuEnglish,
        nameOfMenuPortuguese: this.state.nameOfMenuPortuguese,
        nameOfMenuFrench: this.state.nameOfMenuFrench,

        imageMenu: this.state.imageMenu,
        hours: this.state.hours,
        orderMenu: this.state.orderMenu,
        aclarationSpanish: this.state.aclarationSpanish,
        aclarationEnglish: this.state.aclarationEnglish,
        aclarationPortuguese: this.state.aclarationPortuguese,
        aclarationFrench: this.state.aclarationFrench,
      })
      .then((doc) => {
        this.props.hideLoader();

        this.props.history.push("/menu");
        firebase.succesfullMsg("Menu successfully created!");
      });
    //1. setear en la DB una cantidad mas de menu.
    let hotelInfo = DB.collection("hoteles").doc(`${this.props.userLogin}`);
    //let hotelInfo = DB.collection("hoteles").doc("wbKs9LDjm0SpFBZnqvst");
    let sumaMenues = this.state.hotelTotalMenues + 1;
    hotelInfo.update({ totalMenues: sumaMenues });
    //2. agregar en Redux una cantidad mas de menu.
    this.props.saveAddMenuQuantity(sumaMenues);
  }

  handleInput(e) {
    let key = e.target.name;
    let input = e.target.value;
    this.setState({
      [key]: input,
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Menu submit={this.handleSubmit} input={this.handleInput} />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMenuContainer);
