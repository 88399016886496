import React from "react";
import Ministries from "../../components/views/Ministries";
import firebase from "../../../services/firebase";
const DB = firebase.db;

class MinistriesContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      messages: [],
      loader: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);


    // let personClass = DB.collection("church").doc("02NyPwGGExwDiTMeI70m").collection("people").where('class', '==', `13`)



    // personClass.get().then((result) => {
    //   result.forEach((resultDoc) => {

    //     console.log(`${resultDoc.data().firstName} ${resultDoc.data().lastName}`)
    //   })


    // })



    this.setState({
      loader: false,
    });
  }

  render() {
    return (
      <div>
        <Ministries
          messages={this.state.messages}
          loader={this.state.loader}
          screenSize={window.screen.width}
        />
      </div>
    );
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(PropiedadesCliente);

export default MinistriesContainer;
