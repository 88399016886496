import React from "react";

const Background =
  "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbackground-palms-app.png?alt=media&token=6771073c-a51a-4b96-b785-347babd36c60";

const styles = {
  container: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  top: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    marginLeft: "30px",
    //  backgroundColor: "green",
  },
  title: {
    fontSize: "45px",
    color: "#fff",
    fontWeight: "bold",
  },
  bottom: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "40px 0 0 0",
  },
  form: {
    width: "80%",
  },
  label: {
    fontSize: "20px",
    color: "#000000",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "#339AE7",
    borderColor: "#339AE7",
    fontSize: "20px",
    width: "100%",
    marginTop: "20px",
    height: "60px",
    borderRadius: "10px",
    //fontWeight: "bold",
  },
};

export default ({ handlerSubmit, handlerChange }) => {
  return (
    <div style={styles.container}>
      <div style={styles.top}>
        {/* <img
          src="https://insideone.s3-sa-east-1.amazonaws.com/logo-lovable.png"
          height="50"
          alt=""
        /> */}

        <h1 style={styles.title}>Hoteles</h1>
        <h1 style={styles.title}>Digitales</h1>
      </div>

      <div style={styles.bottom}>
        <form style={styles.form} onSubmit={(e) => handlerSubmit(e)}>
          <div className="form-group">
            <label style={styles.label}>Código</label>
            <input
              name="code"
              type="text"
              className="form-control"
              id="code"
              onChange={handlerChange}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            style={styles.button}
          >
            Ingresar
          </button>
        </form>
      </div>
    </div>
  );
};
