import { SET_NEW_PRODUCT, SET_EMPTY_CART } from "../reducers/constant";

const addProduct = (productData) => ({
  type: SET_NEW_PRODUCT,
  productData,
});

const deleteCart = (productData) => ({
  type: SET_EMPTY_CART,
  productData,
});

// const addIdTable = table => ({
//   type: SET_ID_TABLE,
//   table
// });

// const addIdRestaurant = restaurant => ({
//   type: SET_ID_RESTAURANT,
//   restaurant
// });

export const saveProduct = (product) => (dispatch) =>
  dispatch(addProduct(product));

export const emptyCart = (product) => (dispatch) =>
  dispatch(deleteCart(product));

// export const saveTable = table => dispatch => dispatch(addIdTable(table));

// export const saveRestaurant = restaurant => dispatch =>
//   dispatch(addIdRestaurant(restaurant));
