import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import MoveCommentItem from "../../components/create/MoveCommentItem";
import { connect } from "react-redux";
import { saveAddMenuQuantity } from "../../../store/actions/hotelAction";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
const DB = firebase.db;

const mapStateToProps = (state) => {
    return {
        isAuth: state.user.isAuth,
        userLogin: state.user.loginUser.hotelId,
        hotelInfoRedux: state.hotel.hotelInfo,
        hotelId: state.user.loginUser.hotelId,
    };
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        saveAddMenuQuantity: (hotel) => dispatch(saveAddMenuQuantity(hotel)),
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
    };
};

class CreateMoveCommentItemContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {

            news: [],

            comments: "",

            actionType: "Ingreso",

            name: "",
            description: "",
            photo: "",

            date: "",

            nameOfBag: ""

        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleInput(e) {
        let key = e.target.name;
        let input = e.target.value;
        this.setState({
            [key]: input,
        });
    }

    handleClick(e) {
        this.setState({ actionType: e })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.showLoader();

        let year = new Date().getFullYear()
        let month = new Date().getMonth() + 1
        const date = new Date().getDate()
        let hours = new Date().getHours()
        let minutes = new Date().getMinutes()
        let contactSinceInfo = (date + "/" + month + "/" + year + " " + hours + ":" + minutes)
        let dateTotal = new Date().getTime()
        this.setState({ date: contactSinceInfo })


        if (this.props.hotelId) {

            if (this.props.match.params.idAction === "comment") {

                let singleBagDoc = DB.collection("countrys")
                    .doc(`${this.props.hotelId}`)
                    .collection("golfbags").doc(this.props.match.params.idBag)

                singleBagDoc.get().then((peopleBack) => {

                    let commentsArray = peopleBack.data().comments

                    if (commentsArray.length > 4) {
                        commentsArray.unshift({
                            comments: this.state.comments,
                            date: this.state.date,
                        })
                        commentsArray.pop()
                    } else {
                        commentsArray.unshift({
                            comments: this.state.comments,
                            date: this.state.date,
                        })
                    }

                    let singleBagDocUpdate = DB.collection("countrys")
                        .doc(`${this.props.hotelId}`)
                        .collection("golfbags").doc(this.props.match.params.idBag)

                    singleBagDocUpdate
                        .update({ comments: commentsArray })
                        .then((creado) => {
                            this.props.hideLoader();
                            this.props.history.push(`/bags/${this.props.match.params.idBag}`);
                            firebase.succesfullMsg("Comentario creado!");
                        });
                })
            }

            if (this.props.match.params.idAction === "item") {
                let singleBagDoc = DB.collection("countrys")
                    .doc(`${this.props.hotelId}`)
                    .collection("golfbags").doc(this.props.match.params.idBag)

                singleBagDoc.get().then((peopleBack) => {
                    let itemsArray = peopleBack.data().items
                    itemsArray.push({
                        name: this.state.name,
                        description: this.state.description,
                        photo: this.state.photo,
                    })

                    let singleBagDocUpdate = DB.collection("countrys")
                        .doc(`${this.props.hotelId}`)
                        .collection("golfbags").doc(this.props.match.params.idBag)
                    singleBagDocUpdate
                        .update({ items: itemsArray })
                        .then((creado) => {
                            this.props.hideLoader();
                            this.props.history.push(`/bags/${this.props.match.params.idBag}`);
                            firebase.succesfullMsg("Item creado!");
                        });
                })
            }

            if (this.props.match.params.idAction === "movement") {

                let singleBagToUpdate = DB.collection("countrys")
                    .doc(`${this.props.hotelId}`).collection("golfbags").doc(`${this.props.match.params.idBag}`)

                singleBagToUpdate.get().then((simpleBag) => {

                    if (this.state.comments !== "" && this.state.comments !== " " && this.state.comments !== "  ") {
                        let commentsArray = simpleBag.data().comments
                        let commentsArrayToPush = commentsArray.slice()
                        if (commentsArrayToPush.length > 4) {
                            commentsArrayToPush.unshift({
                                date: this.state.date,
                                comments: this.state.comments,
                            })
                            commentsArrayToPush.pop()
                        } else {
                            commentsArrayToPush.unshift({
                                date: this.state.date,
                                comments: this.state.comments,
                            })
                        }
                        singleBagToUpdate.update({ comments: commentsArrayToPush })
                    }

                    this.setState({ nameOfBag: simpleBag.data().fullName })

                    let historyArray = simpleBag.data().history
                    let historyArrayToPush = historyArray.slice()
                    if (historyArrayToPush.length > 4) {
                        historyArrayToPush.unshift({
                            date: this.state.date,
                            actionType: this.state.actionType,
                        })
                        historyArrayToPush.pop()
                    } else {
                        historyArrayToPush.unshift({
                            date: this.state.date,
                            actionType: this.state.actionType,
                        })
                    }
                    singleBagToUpdate.update({ history: historyArrayToPush })

                    let singleHistoryToUpdate = DB.collection("countrys")
                        .doc(`${this.props.hotelId}`).collection("moveBagsHistory").doc("vZSnYrYxnycxb5n8Etk0")
                    singleHistoryToUpdate.get().then((simpleBag) => {
                        let historyArrayBags = simpleBag.data().history
                        let historyArrayBagsToPush = historyArrayBags.slice()
                        if (historyArrayBagsToPush.length > 50) {
                            historyArrayBagsToPush.unshift({
                                date: this.state.date,
                                actionType: this.state.actionType,
                                comments: this.state.comments,
                                idBag: this.props.match.params.idBag,
                                fullName: this.state.nameOfBag,
                            })
                            historyArrayBagsToPush.pop()
                        } else {
                            historyArrayBagsToPush.unshift({
                                date: this.state.date,
                                actionType: this.state.actionType,
                                comments: this.state.comments,
                                idBag: this.props.match.params.idBag,
                                fullName: this.state.nameOfBag,
                            })
                        }
                        singleHistoryToUpdate.update({ history: historyArrayBagsToPush }).then((creado) => {
                            this.props.hideLoader();
                            this.props.history.push(`/bags/${this.props.match.params.idBag}`);
                            firebase.succesfullMsg("Movimiento registrado!");
                        });
                    })

                });



                //3.Agregar en movebags como historico?


            }




        }

    }

    render() {
        return (
            <div>
                <Sidebar />
                <MoveCommentItem submit={this.handleSubmit} input={this.handleInput} actionType={this.props.match.params.idAction} actionState={this.state.actionType} handleClick={this.handleClick} />
                <div>
                    <FullPageLoader />
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateMoveCommentItemContainer);
