import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import ReactPlayer from "react-player/youtube";

const styles = {
  containerFlex: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  topCenter: {
    flex: 7,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "center",
    // marginLeft: "30px",
    //  backgroundColor: "green",
  },

  cover: {
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "center",
    margin: 0,
    height: "90vh",
  },

  textTitleCover: {
    margin: 0,
    fontSize: 35,
    fontWeight: "bold",
    color: "#fff",
    // padding: "5px",
    paddingTop: "15px",
    // fontFamily: "LoewMedium",
    fontFamily: "LatoBlack",
  },

  textTitleCoverWeb: {
    margin: 0,
    fontSize: 55,
    fontWeight: "bold",
    color: "#fff",
    // padding: "5px",
    paddingTop: "15px",
   fontFamily: "LatoBlack",
  },
  textCityCover: {
    margin: 0,
    fontSize: 18,
    color: "#fff",
    paddingTop: "20px",
    paddingRight: "100px",
    fontFamily: "Lato",
  },

  textCityCoverWeb: {
    margin: 0,
    fontSize: 22,
    color: "#fff",
    paddingTop: "20px",
    paddingRight: "100px",
    fontFamily: "Lato",
  },

  textPointersCover: {
    margin: 0,
    fontSize: 14,
    color: "#fff",
    paddingTop: "25px",
    fontFamily: "Lato",
  },

  divTextPointersCoverWeb: {
    display: "flex",
    alignItems: "center",
    margin: 0,
  },

  divTextPointersCover: {
    display: "flex",
    alignItems: "center",
    margin: 0,
  },

  textPointersCoverWeb: {
    margin: 0,
    fontSize: 22,
    color: "#fff",
    paddingTop: "25px",
    fontFamily: "Lato",
  },

  textPriceCover: {
    margin: 0,
    fontSize: 28,
  //  fontWeight: "bold",
    color: "#fff",
    paddingTop: "50px",
    fontFamily: "LatoBold",
  },

  textPriceCoverWeb: {
    margin: 0,
    fontSize: 48,
    //fontWeight: "bold",
    color: "#fff",
    paddingTop: "50px",
    fontFamily: "LatoBold",
  },

  textTitle: {
    margin: 0,
    fontSize: 35,
    //fontWeight: "bold",
    color: "#000",
    paddingTop: "25px",
    paddingBottom: "25px",
    fontFamily: "LatoBlack",
  },

  textTitleWeb: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 40px 0 20px",
    fontSize: 45,
   // fontWeight: "bold",
    color: "#000",
    fontFamily: "LatoBlack",
  },

  textTitleWeb2: {
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 45,
   // fontWeight: "bold",
    color: "#000",
    textAlign: "center",
    fontFamily: "LatoBlack",
  },

  textTitleWeb3: {
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 45,
   // fontWeight: "bold",
    color: "#000",
    textAlign: "center",
    fontFamily: "LatoBlack",
  },

  textPointers: {
    margin: 0,
    fontSize: 20,
    color: "#000",
    paddingTop: "25px",
    paddingBottom: "25px",
    fontFamily: "Lato",
  },

  textPointersWeb: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: 0,
    fontSize: 20,
    color: "#000",
    fontFamily: "Lato",
  },

  imagePointers: { margin: 0, height: "30px", marginRight: "25px" },

  imagePointersWeb: { margin: 0, height: "30px", marginRight: "20px" },

  pointerDiv: {
    display: "flex",
    alignItems: "center",
  },

  pointerDivWeb: {
    display: "flex",
    alignItems: "center",
    margin: "20px",
  },

  video: {
    display: "flex",
    margin: "30px 0 10px 0",
    height: "250px",
    width: "100%",
  },

  videoWeb: {
    display: "flex",
    // margin: "30px",
    height: "750px",
    width: "100%",
    // paddingLeft: "100px",
    // paddingRight: "100px",
    padding: "75px 75px 20px 75px",
    //padding: "75px",
  },

  cover360: {
    display: "flex",
    margin: "30px 0 30px 0",
  },

  cover360Web: {
    display: "flex",
    margin: "30px 0 30px 0",
    height: "600px",
    width: "100%",
  },

  imagesContainerWeb: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    padding: "55px 55px 0 55px",
    //padding: "55px",
  },

  imageCover: {
    width: "100%",
    objectFit: "cover",
    padding: "20px",
    paddingBottom: "0px",
  },

  imageCoverWeb: {
    width: "50%",
    objectFit: "cover",
    padding: "0 20px 40px 20px",
  },

  info: {
    display: "flex",
    flex: 3,
    margin: 0,
    width: "100%",
    backgroundColor: "white",
    borderRadius: "25px",
    padding: "5px",
  },

  left: {
    display: "flex",
    flex: 8,
    flexFlow: "column",
    margin: 0,
  },

  right: {
    display: "flex",
    flex: 4,
    flexFlow: "column",
    margin: 0,
    padding: "5px",
    justifyContent: "center",
    alignItems: "center",
  },

  numbers: {
    flex: 12,
    display: "flex",
    margin: 0,
    fontSize: 11,
    fontWeight: "bold",
    color: "#000",
    padding: "5px",
    paddingTop: "15px",
    fontFamily: "LoewMedium",
  },

  direction: {
    flex: 12,
    display: "flex",
    margin: 0,
    fontSize: 9,
    color: "grey",
    padding: "5px",
    paddingTop: "5px",
    paddingBottom: "15px",
    fontFamily: "LoewMedium",
  },

  price: {
    margin: 0,
    fontSize: 14,
    fontWeight: "bold",
    color: "#000",
    fontFamily: "LoewMedium",
  },
  imageFinal: { margin: 0, height: "35px" },

  imageHead: {  
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  margin: 0, 
  height: "35px" },


  textFinal: {
    margin: 0,
    fontSize: 14,
    color: "#fff",
    paddingTop: "25px",

  },
  buttonFloat: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "50px",
    //backgroundColor: "green",
  },

  buttonWeb2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "250px",
    height: "60px",
    backgroundColor: "#ff2068",
    borderRadius: "50px",
    margin: "50px 20px 0 20px",
  },
  textButton2: {
    margin: 0,
    //fontFamily: "Lato",
    fontSize: 20,
    //fontWeight: 900,
    textAlign: "center",
    color: "#fff",
    padding: "10px",
    inherit: "none",
    fontFamily: "Lato",
  },

  buttonWeb3: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "250px",
    height: "60px",
    backgroundColor: "#ff2068",
    borderRadius: "50px",
    marginTop: "30px",
  },
  textButton3: {
    margin: 0,
    //fontFamily: "Lato",
    fontSize: 20,
    //fontWeight: 900,
    textAlign: "center",
    color: "#fff",
    padding: "10px",
    inherit: "none",
    fontFamily: "Lato",
  },
};

export default ({ propertie, loader, screenSize }) => {
  return (
    <>
      {loader == false ? (
        <>
          {screenSize > 800 ? (
            <>
              <div
                style={{
                  overflow: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    //alignItems: "center",
                    flexFlow: "column",
                    margin: 0,
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "200px",
                    paddingBottom: "250px",

                    backgroundImage: `url("${propertie.cover}")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",

                    //objectFit: "cover",
                  }}
                >

                 

              

                  <h1 style={styles.textTitleCoverWeb}>
                    {propertie.direction}
                  </h1>
                  <h1 style={styles.textCityCoverWeb}>{propertie.city}</h1>

                  {/* <h1 style={styles.textPointersCoverWeb}>
                    {propertie.mtTotales} mt2 ・ {propertie.ambientes} Amb ・{" "}
                    {propertie.bathrooms} Baño
                  </h1> */}

                  <div style={styles.divTextPointersCoverWeb}>
                    <h1 style={styles.textPointersCoverWeb}>
                      {propertie.mtTotales} mt2
                    </h1>

                    <h1 style={styles.textPointersCoverWeb}>
                      {propertie.ambientes ? (
                        ` ・ ${propertie.ambientes} Amb`
                      ) : (
                        <></>
                      )}
                    </h1>

                    <h1 style={styles.textPointersCoverWeb}>
                      {propertie.bathrooms ? (
                        <>
                          {propertie.bathrooms !== "1"
                            ? ` ・ ${propertie.bathrooms} Baños`
                            : ` ・ ${propertie.bathrooms} Baño`}
                        </>
                      ) : (
                        <></>
                      )}
                    </h1>

                    <h1 style={styles.textPointersCoverWeb}>
                      {propertie.car ? (
                        <>
                          {propertie.car !== "1"
                            ? ` ・ ${propertie.car} Cocheras`
                            : ` ・ ${propertie.car} Cochera`}
                        </>
                      ) : (
                        <></>
                      )}
                    </h1>
                  </div>

                  <h1 style={styles.textPriceCoverWeb}>{propertie.price}</h1>
                </div>

                {/* <Link
                  style={styles.buttonFloat}
                  //to={() => (window.location.href = "http://domain.com")}
                >
                  <img
                    onClick={() =>
                      // (window.location.href = `https://wa.me/%2B${whatsapp}`)
                      (window.location.href = `https://wa.me/5491136926957?text=Hola!%20Quisiera%20saber%20m%C3%A1s%20sobre%20las%20propiedades%20publicadas.%20Saludos`)
                    }
                    src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fwhatsapp-logo.png?alt=media&token=577f9926-5c47-42ba-a0c5-af980d340f01"
                    height="75"
                    alt="whatsapp-icon"
                  />
                </Link> */}

                {propertie.youtubeURL !== "" ? (
                  <div style={styles.videoWeb}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={`${propertie.youtubeURL}`}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                ) : (
                  <></>
                )}

                <div style={styles.imagesContainerWeb}>
                  {propertie.images.lenght !== 0 ? (
                    propertie.images.map((image) => (
                      <img
                        style={styles.imageCoverWeb}
                        src={image}
                        alt="..."
                        loading="lazy"
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // flexFlow: "column",
                    margin: 0,
                    padding: "70px 30px 70px 30px",
                  }}
                >
                  <h1 style={styles.textTitleWeb}>Información</h1>

                  {propertie.type === "Departamento" ||
                  propertie.type === "Casa" ? (
                    <>
                      {propertie.mtTotales !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Farea.png?alt=media&token=50a87945-0173-4080-b1ef-89756cbc47dd"
                            alt="..."
                            loading="lazy"
                          />
                          <h1 style={styles.textPointersWeb}>
                            {propertie.mtTotales} Mts Totales
                          </h1>
                        </div>
                      ) : (
                        <></>
                      )}
                      {propertie.ambientes !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Ffloor.png?alt=media&token=02314369-d406-4952-92d7-1bd62e4260dc"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.ambientes === "1" ? (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.ambientes} Ambiente
                            </h1>
                          ) : (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.ambientes} Ambientes
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {propertie.bathrooms !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fbath.png?alt=media&token=51f6a5f1-e66f-4822-bdff-fbc129c9bef6"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.bathrooms === "1" ? (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.bathrooms} Baño
                            </h1>
                          ) : (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.bathrooms} Baños
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {propertie.car !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fcar.png?alt=media&token=9b7ed17b-7b5b-47a8-9986-1f46f384719e"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.car === "1" ? (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.car} Cochera
                            </h1>
                          ) : (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.car} Cocheras
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {propertie.baulera !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fstorage.png?alt=media&token=dbb8d92c-0b45-4427-b4af-70a4ad4b9d9d"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.baulera === "1" ? (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.baulera} Baulera
                            </h1>
                          ) : (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.baulera} Bauleras
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {propertie.type === "Oficina" ? (
                    <>
                      {propertie.mtTotales !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Farea.png?alt=media&token=50a87945-0173-4080-b1ef-89756cbc47dd"
                            alt="..."
                            loading="lazy"
                          />
                          <h1 style={styles.textPointersWeb}>
                            {propertie.mtTotales} Mts
                          </h1>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* {propertie.office !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Ffloor.png?alt=media&token=02314369-d406-4952-92d7-1bd62e4260dc"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.office === "1" ? (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.office} Oficina
                            </h1>
                          ) : (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.office} Oficinas
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}

                      {propertie.meet !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fmeet.png?alt=media&token=1b347863-17ad-43c2-8e44-9046a071373b"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.meet === "1" ? (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.meet} Sala
                            </h1>
                          ) : (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.meet} Salas
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )} */}
                      {propertie.ambientes !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Ffloor.png?alt=media&token=02314369-d406-4952-92d7-1bd62e4260dc"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.ambientes === "1" ? (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.ambientes} Ambiente
                            </h1>
                          ) : (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.ambientes} Ambientes
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* {propertie.kitchen !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fkitchen.png?alt=media&token=c2827e36-6db6-4eeb-96e5-a4459909a8ad"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.kitchen === "1" ? (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.kitchen} Cocina
                            </h1>
                          ) : (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.kitchen} Cocinas
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )} */}

                      {propertie.bathrooms !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fbathroom.png?alt=media&token=314c6dce-7c09-4cf1-8bf3-6fd7aab8dc34"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.bathrooms === "1" ? (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.bathrooms} Baño
                            </h1>
                          ) : (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.bathrooms} Baños
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {propertie.car !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fcar.png?alt=media&token=9b7ed17b-7b5b-47a8-9986-1f46f384719e"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.car === "1" ? (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.car} Cochera
                            </h1>
                          ) : (
                            <h1 style={styles.textPointersWeb}>
                              {propertie.car} Cocheras
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {propertie.type === "Lote" || propertie.type === "Cochera" ? (
                    <>
                      {propertie.mtTotales !== "" ? (
                        <div style={styles.pointerDivWeb}>
                          <img
                            style={styles.imagePointersWeb}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Farea.png?alt=media&token=50a87945-0173-4080-b1ef-89756cbc47dd"
                            alt="..."
                            loading="lazy"
                          />
                          <h1 style={styles.textPointersWeb}>
                            {propertie.mtTotales} Mts Totales
                          </h1>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                {propertie.url360 !== "" ? (
                  <div style={styles.cover360Web}>
                    <iframe
                      //class="ku-embed"
                      frameborder="0"
                      allowfullscreen
                      allow="xr-spatial-tracking; gyroscope; accelerometer"
                      scrolling="no"
                      width="100%"
                      height="100%"
                      src={`${propertie.url360}`}
                    ></iframe>
                  </div>
                ) : (
                  <></>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: 0,
                    padding: "120px 30px 150px 30px",
                  }}
                >
                  <h1 style={styles.textTitleWeb2}>Queremos ayudarte!</h1>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: 0,
                    }}
                  >
                    <Link style={styles.buttonWeb2}>
                      <h1
                        style={styles.textButton2}
                        onClick={() =>
                          (window.location.href = `https://form.typeform.com/to/NhDf9pEg`)
                        }
                      >
                        Quiero saber más
                      </h1>
                    </Link>

                    <Link style={styles.buttonWeb2}>
                      <h1
                        style={styles.textButton2}
                        onClick={() =>
                          (window.location.href = `https://wa.me/5491136926957?text=Hola!%20Quisiera%20saber%20m%C3%A1s%20sobre%20las%20propiedades%20publicadas.%20Saludos`)
                        }
                      >
                        WhatsApp
                      </h1>
                    </Link>
                  </div>
                </div>

                <div
                  style={{
                    height: "8px",
                    backgroundColor: "#ff2068",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexFlow: "column",
                    margin: 0,

                    paddingTop: "50px",
                    paddingBottom: "50px",

                    backgroundColor: "#000",
                  }}
                >
                  <Link to={`/`}>
                    <img
                      style={styles.imageFinal}
                      src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Flogo.png?alt=media&token=36afac40-3748-4d69-a721-7f3d5db7d88c"
                      alt="..."
                      loading="lazy"
                    />
                  </Link>
                  <h1 style={styles.textFinal}>© 2020 Housify.</h1>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  overflow: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    //alignItems: "center",
                    flexFlow: "column",
                    margin: 0,
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "50px",
                    paddingBottom: "150px",

                    backgroundImage: `url("${propertie.cover}")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",

                    //objectFit: "cover",
                  }}
                >
                   <Link to={`/`}  style={{
                      margin:0,
                      marginBottom:50
                    }}>
                    <div
                     style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    >
                    <img
                      style={styles.imageHead}
                      src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Flogo.png?alt=media&token=36afac40-3748-4d69-a721-7f3d5db7d88c"
                      alt="..."
                      loading="lazy"
                    />
                    </div> 
                  </Link>
                  <h1 style={styles.textTitleCover}>{propertie.direction}</h1>
                  <h1 style={styles.textCityCover}>{propertie.city}</h1>
                  {/* <h1 style={styles.textPointersCover}>
                    {propertie.mtTotales} mt2 ・ {propertie.ambientes} Amb ・{" "}
                    {propertie.bathrooms} Baño
                  </h1> */}

                  <div style={styles.divTextPointersCover}>
                    <h1 style={styles.textPointersCover}>
                      {propertie.mtTotales} mt2
                    </h1>

                    <h1 style={styles.textPointersCover}>
                      {propertie.ambientes ? (
                        ` ・ ${propertie.ambientes} Amb`
                      ) : (
                        <></>
                      )}
                    </h1>

                    <h1 style={styles.textPointersCover}>
                      {propertie.bathrooms ? (
                        <>
                          {propertie.bathrooms !== "1"
                            ? ` ・ ${propertie.bathrooms} Baños`
                            : ` ・ ${propertie.bathrooms} Baño`}
                        </>
                      ) : (
                        <></>
                      )}
                    </h1>

                    <h1 style={styles.textPointersCover}>
                      {propertie.car ? (
                        <>
                          {propertie.car !== "1"
                            ? ` ・ ${propertie.car} Cocheras`
                            : ` ・ ${propertie.car} Cochera`}
                        </>
                      ) : (
                        <></>
                      )}
                    </h1>
                  </div>

                  <h1 style={styles.textPriceCover}>{propertie.price}</h1>
                </div>

                {/* <Link
                  style={styles.buttonFloat}
                  //to={() => (window.location.href = "http://domain.com")}
                >
                  <img
                    onClick={() =>
                      // (window.location.href = `https://wa.me/%2B${whatsapp}`)
                      (window.location.href = `https://wa.me/5491136926957?text=Hola!%20Quisiera%20saber%20m%C3%A1s%20sobre%20las%20propiedades%20publicadas.%20Saludos`)
                    }
                    src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fwhatsapp-logo.png?alt=media&token=577f9926-5c47-42ba-a0c5-af980d340f01"
                    height="75"
                    alt="whatsapp-icon"
                  />
                </Link> */}

                {propertie.youtubeURL !== "" ? (
                  <div style={styles.video}>
                    {/* <ReactPlayer
                // playing="true"
                loop="true"
                controls="true"
                height="250"
                width="100%"
                url={`${propertie.youtubeURL}`}
              /> */}

                    <iframe
                      width="100%"
                      height="250"
                      src={`${propertie.youtubeURL}`}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                ) : (
                  <></>
                )}

                <div style={styles.imagesContainer}>
                  {propertie.images.lenght !== 0 ? (
                    propertie.images.map((image) => (
                      <img
                        style={styles.imageCover}
                        src={image}
                        alt="..."
                        loading="lazy"
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexFlow: "column",
                    margin: 0,
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <h1 style={styles.textTitle}>Información</h1>

                  {propertie.type === "Departamento" ||
                  propertie.type === "Casa" ? (
                    <>
                      {propertie.mtTotales !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Farea.png?alt=media&token=50a87945-0173-4080-b1ef-89756cbc47dd"
                            alt="..."
                            loading="lazy"
                          />
                          <h1 style={styles.textPointers}>
                            {propertie.mtTotales} Mts Totales
                          </h1>
                        </div>
                      ) : (
                        <></>
                      )}
                      {propertie.ambientes !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Ffloor.png?alt=media&token=02314369-d406-4952-92d7-1bd62e4260dc"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.ambientes === "1" ? (
                            <h1 style={styles.textPointers}>
                              {propertie.ambientes} Ambiente
                            </h1>
                          ) : (
                            <h1 style={styles.textPointers}>
                              {propertie.ambientes} Ambientes
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {propertie.bathrooms !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fbath.png?alt=media&token=51f6a5f1-e66f-4822-bdff-fbc129c9bef6"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.bathrooms === "1" ? (
                            <h1 style={styles.textPointers}>
                              {propertie.bathrooms} Baño
                            </h1>
                          ) : (
                            <h1 style={styles.textPointers}>
                              {propertie.bathrooms} Baños
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {propertie.car !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fcar.png?alt=media&token=9b7ed17b-7b5b-47a8-9986-1f46f384719e"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.car === "1" ? (
                            <h1 style={styles.textPointers}>
                              {propertie.car} Cochera
                            </h1>
                          ) : (
                            <h1 style={styles.textPointers}>
                              {propertie.car} Cocheras
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {propertie.baulera !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fstorage.png?alt=media&token=dbb8d92c-0b45-4427-b4af-70a4ad4b9d9d"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.baulera === "1" ? (
                            <h1 style={styles.textPointers}>
                              {propertie.baulera} Baulera
                            </h1>
                          ) : (
                            <h1 style={styles.textPointers}>
                              {propertie.baulera} Bauleras
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {propertie.type === "Oficina" ? (
                    <>
                      {propertie.mtTotales !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Farea.png?alt=media&token=50a87945-0173-4080-b1ef-89756cbc47dd"
                            alt="..."
                            loading="lazy"
                          />
                          <h1 style={styles.textPointers}>
                            {propertie.mtTotales} Mts Totales
                          </h1>
                        </div>
                      ) : (
                        <></>
                      )}
                      {propertie.office !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Ffloor.png?alt=media&token=02314369-d406-4952-92d7-1bd62e4260dc"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.office === "1" ? (
                            <h1 style={styles.textPointers}>
                              {propertie.office} Oficina
                            </h1>
                          ) : (
                            <h1 style={styles.textPointers}>
                              {propertie.office} Oficinas
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}

                      {propertie.meet !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fmeet.png?alt=media&token=1b347863-17ad-43c2-8e44-9046a071373b"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.meet === "1" ? (
                            <h1 style={styles.textPointers}>
                              {propertie.meet} Sala de Reunión
                            </h1>
                          ) : (
                            <h1 style={styles.textPointers}>
                              {propertie.meet} Salas de Reuniones
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}

                      {propertie.kitchen !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fkitchen.png?alt=media&token=c2827e36-6db6-4eeb-96e5-a4459909a8ad"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.kitchen === "1" ? (
                            <h1 style={styles.textPointers}>
                              {propertie.kitchen} Cocina
                            </h1>
                          ) : (
                            <h1 style={styles.textPointers}>
                              {propertie.kitchen} Cocinas
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}

                      {propertie.bathrooms !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fbathroom.png?alt=media&token=314c6dce-7c09-4cf1-8bf3-6fd7aab8dc34"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.bathrooms === "1" ? (
                            <h1 style={styles.textPointers}>
                              {propertie.bathrooms} Baño
                            </h1>
                          ) : (
                            <h1 style={styles.textPointers}>
                              {propertie.bathrooms} Baños
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {propertie.car !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Fcar.png?alt=media&token=9b7ed17b-7b5b-47a8-9986-1f46f384719e"
                            alt="..."
                            loading="lazy"
                          />

                          {propertie.car === "1" ? (
                            <h1 style={styles.textPointers}>
                              {propertie.car} Cochera
                            </h1>
                          ) : (
                            <h1 style={styles.textPointers}>
                              {propertie.car} Cocheras
                            </h1>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {propertie.type === "Lote" || propertie.type === "Cochera" ? (
                    <>
                      {propertie.mtTotales !== "" ? (
                        <div style={styles.pointerDiv}>
                          <img
                            style={styles.imagePointers}
                            src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Farea.png?alt=media&token=50a87945-0173-4080-b1ef-89756cbc47dd"
                            alt="..."
                            loading="lazy"
                          />
                          <h1 style={styles.textPointers}>
                            {propertie.mtTotales} Mts Totales
                          </h1>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                {propertie.url360 !== "" ? (
                  <div style={styles.cover360}>
                    <iframe
                      //class="ku-embed"
                      frameborder="0"
                      allowfullscreen
                      allow="xr-spatial-tracking; gyroscope; accelerometer"
                      scrolling="no"
                      width="100%"
                      height="450"
                      src={`${propertie.url360}`}
                    ></iframe>
                  </div>
                ) : (
                  <></>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: 0,
                    padding: "60px 30px 100px 30px",
                  }}
                >
                  <h1 style={styles.textTitleWeb3}>Queremos ayudarte!</h1>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      margin: 0,
                    }}
                  >
                    <Link style={styles.buttonWeb3}>
                      <h1
                        style={styles.textButton3}
                        onClick={() =>
                          (window.location.href = `https://form.typeform.com/to/NhDf9pEg`)
                        }
                      >
                        Quiero saber más
                      </h1>
                    </Link>

                    <Link style={styles.buttonWeb3}>
                      <h1
                        style={styles.textButton3}
                        onClick={() =>
                          (window.location.href = `https://wa.me/5491136926957?text=Hola!%20Quisiera%20saber%20m%C3%A1s%20sobre%20las%20propiedades%20publicadas.%20Saludos`)
                        }
                      >
                        WhatsApp
                      </h1>
                    </Link>
                  </div>
                </div>

                <div
                  style={{
                    height: "8px",
                    backgroundColor: "#ff2068",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexFlow: "column",
                    margin: 0,

                    paddingTop: "50px",
                    paddingBottom: "50px",

                    backgroundColor: "#000",
                  }}
                >
                  <Link to={`/`}>
                    <img
                      style={styles.imageFinal}
                      src="https://firebasestorage.googleapis.com/v0/b/housify-online.appspot.com/o/General%2Flogo.png?alt=media&token=36afac40-3748-4d69-a721-7f3d5db7d88c"
                      alt="..."
                      loading="lazy"
                    />
                  </Link>

                  <h1 style={styles.textFinal}>© 2020 Housify.</h1>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div style={styles.containerFlex}>
          <Loader
            type="TailSpin"
            color="#ff2068"
            // color="#339AE7"
            height={100}
            width={100}
            timeout={10000} //3 secs
          />
        </div>
      )}
    </>
  );
};
