import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Messages from "../../components/create/Messages";
import { connect } from "react-redux";
import { saveAddMenuQuantity } from "../../../store/actions/hotelAction";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
const DB = firebase.db;

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.isAuth,
    userLogin: state.user.loginUser.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveAddMenuQuantity: (hotel) => dispatch(saveAddMenuQuantity(hotel)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

class CreateMessagesContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      title: "",
      youtube: "",
      messages: [],

      hotel: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }
  componentDidMount() {
    let churchDoc = DB.collection("church")
      .doc("02NyPwGGExwDiTMeI70m")
      .collection("messages")
      .doc("jdTBuNcp0F5TCNoV59VK")

    churchDoc.get()
      .then((messagesArray) => {
        this.setState({
          messages: messagesArray.data().messages,
        });
      })
  }

  handleSubmit(e) {
    e.preventDefault();
    //  this.props.showLoader();
    let youtubeToChange = this.state.youtube
    let youtubeChanged = youtubeToChange.replace('watch?v=', 'embed/')

    let churchDoc = DB.collection("church")
      .doc("02NyPwGGExwDiTMeI70m")
      .collection("messages")
      .doc("jdTBuNcp0F5TCNoV59VK")

    let messagesToUpdate = this.state.messages
    messagesToUpdate.unshift({
      youtube: youtubeChanged,
      title: this.state.title,
    })

    churchDoc
      .update({ messages: messagesToUpdate })
      .then((creado) => {
        this.props.hideLoader();

        this.props.history.push("/messages-admin");
        firebase.succesfullMsg("Mensaje subido!");
      });
  }

  handleInput(e) {
    let key = e.target.name;
    let input = e.target.value;
    this.setState({
      [key]: input,
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Messages submit={this.handleSubmit} input={this.handleInput} />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMessagesContainer);
