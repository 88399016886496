import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Categories from "../../../restaurant/components/views/Categories";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import { saveDownCategoryQuantity } from "../../../store/actions/hotelAction";
const DB = firebase.db;
// let local = JSON.parse(window.localStorage.getItem("persist:lovableLogin"));
// let userLS;
// if (local) {
//   userLS = JSON.parse(local.user);
// }

const mapStateToProps = (state) => {
  return {
    userLogin: state.user.loginUser.hotelId,
    rol: state.user.loginUser.rol,
    isAuth: state.user.isAuth,
    hotelInfoRedux: state.hotel.hotelInfo,
    hotelTotalMenues: state.hotel.totalMenues,
    hotelTotalCategories: state.hotel.totalCategories,
    hotelTotalProducts: state.hotel.totalProducts,
    hotelTotalDisableProducts: state.hotel.totalDisableProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveDownCategoryQuantity: (hotel) =>
      dispatch(saveDownCategoryQuantity(hotel)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

const MySwal = withReactContent(Swal);

class MenuIndividualContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombreMenu: "",
      categoryArray: [],

      hotel: {},
      hotelTotalMenues: 0,
      hotelTotalCategories: 0,
      hotelTotalProducts: 0,
      hotelTotalDisableProducts: 0,
    };
    this.handleDelete = this.handleDelete.bind(this);
  }
  menuId = this.props.match.params.id;

  componentDidMount() {
    // if (userLS.isAuth === false) {
    //   this.props.history.push("/");
    // } else {
    if (this.props.hotelInfoRedux.name) {
      this.setState({
        hotelTotalMenues: this.props.hotelTotalMenues,
        hotelTotalCategories: this.props.hotelTotalCategories,
        hotelTotalProducts: this.props.hotelTotalProducts,
        hotelTotalDisableProducts: this.props.hotelTotalDisableProducts,
      });
    }
    if (this.props.userLogin) {
      this.props.showLoader();
      let doc = DB.collection("hoteles")
        .doc(`${this.props.userLogin}`)
        .collection("menues")
        .doc(this.menuId);
      //Aca saco el nombre del menu
      doc.get().then((name) => {
        this.setState({
          nombreMenu: name.data().nameOfMenu,
          imageMenu: name.data().imageMenu,
        });
        //Aca agarro categorias (plural siendo que cada menu puede tener muchas categorias)
        //Y hago un array de categorias, y meto objetos adentro
        doc
          .collection("categories")
          .orderBy("orderCategory", "asc")
          .get()
          .then((array) => {
            array.forEach((ids) => {
              this.setState({
                categoryArray: [
                  ...this.state.categoryArray,
                  {
                    categoryId: ids.id,
                    imageCategory: ids.data().imageCategory,
                    name: ids.data().name,
                  },
                ],
              });
            });
          });
        setTimeout(() => {
          this.props.hideLoader();
        }, 500);
      });
    }
  }

  handleDelete(e, id) {
    e.preventDefault();
    let doc = DB.collection("hoteles")
      .doc(`${this.props.userLogin}`)
      .collection("menues")
      .doc(this.menuId)
      .collection("categories")
      .doc(id);
    //Refactorizar este mensaje de abajo, pasarlo a la clase Firebase. Quizas con Async Await se puede
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.value) {
        let hotelInfo = DB.collection("hoteles").doc(`${this.props.userLogin}`);
        //let hotelInfo = DB.collection("hoteles").doc("wbKs9LDjm0SpFBZnqvst");
        let restaCategories = this.state.hotelTotalCategories - 1;
        hotelInfo.update({ totalCategories: restaCategories });
        //2. agregar en Redux una cantidad mas de menu.
        this.props.saveDownCategoryQuantity(restaCategories);
        MySwal.fire(
          "Deleted!",
          `Your Category has been deleted  with it's products.`,
          "success"
        );
        doc.delete();
      }
      this.props.history.push(`/menu`);
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Categories
          nombre={this.state.nombreMenu}
          categories={this.state.categoryArray}
          menuId={this.props.match.params.id}
          deleteFunc={this.handleDelete}
          rol={this.props.rol}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuIndividualContainer);
