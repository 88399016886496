import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import News from "../../components/create/News";
import { connect } from "react-redux";
import { saveAddMenuQuantity } from "../../../store/actions/hotelAction";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
const DB = firebase.db;

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.isAuth,
    userLogin: state.user.loginUser.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
    hotelId: state.user.loginUser.hotelId,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveAddMenuQuantity: (hotel) => dispatch(saveAddMenuQuantity(hotel)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

class CreateNewsContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      title: "",
      description: "",
      image: "",
      news: [],

      hotel: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(e) {
    let key = e.target.name;
    let input = e.target.value;
    this.setState({
      [key]: input,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.showLoader();

    if (this.props.hotelId) {
      let churchDoc = DB.collection("countrys")
        .doc(`${this.props.hotelId}`)
        .collection("news")


      churchDoc.get().then((peopleBack) => {
        let peopleArray = [];
        peopleBack.forEach((people) => {
          peopleArray = people.data().news
        });

        if (peopleArray.length > 9) {
          peopleArray.unshift({
            image: this.state.image,
            title: this.state.title,
            description: this.state.description,
          })
          peopleArray.pop()
        } else {
          peopleArray.unshift({
            image: this.state.image,
            title: this.state.title,
            description: this.state.description,
          })
        }

        let churchDoc = DB.collection("countrys")
          .doc(`${this.props.hotelId}`)
          .collection("news").doc("YZE3zRM6EVhFJGpc3Fil")

        churchDoc
          .update({ news: peopleArray })
          .then((creado) => {
            this.props.hideLoader();

            this.props.history.push("/news-admin");
            firebase.succesfullMsg("Novedad creada!");
          });

      })
    }

  }

  render() {
    return (
      <div>
        <Sidebar />
        <News submit={this.handleSubmit} input={this.handleInput} />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewsContainer);
