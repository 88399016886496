import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Category from "../../../restaurant/components/edit/Category";
import { connect } from "react-redux";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
const DB = firebase.db;

const mapStateToProps = (state) => {
  return {
    userLogin: state.user.loginUser.hotelId,
    isAuth: state.user.isAuth,
  };
};

class EditCategoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      imageCategory: "",
      nameSpanish: "",
      nameEnglish: "",
      namePortuguese: "",
      nameFrench: "",
      products: [],
      withImages: true,
      orderCategory: 1,
      selectedOption: "option1",
    };
    this.handleInputs = this.handleInputs.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  menuId = this.props.match.params.id;
  categoryId = this.props.match.params.categoryId;

  componentDidMount() {
    if (this.props.userLogin) {
      this.props.dispatch(showLoader());
      let doc = DB.collection("hoteles")
        .doc(`${this.props.userLogin}`)
        .collection("menues")
        .doc(this.menuId)
        .collection("categories")
        .doc(this.categoryId);
      doc.get().then((category) => {
        if (category.data().withImages) {
          this.setState({
            name: category.data().name,
            imageCategory: category.data().imageCategory,
            nameSpanish: category.data().nameSpanish,
            nameEnglish: category.data().nameEnglish,
            namePortuguese: category.data().namePortuguese,
            nameFrench: category.data().nameFrench,
            products: category.data().products,
            withImages: category.data().withImages,
            orderCategory: category.data().orderCategory,
            selectedOption: "option1",
          });
        } else {
          this.setState({
            name: category.data().name,
            imageCategory: category.data().imageCategory,
            nameSpanish: category.data().nameSpanish,
            nameEnglish: category.data().nameEnglish,
            namePortuguese: category.data().namePortuguese,
            nameFrench: category.data().nameFrench,
            products: category.data().products,
            withImages: category.data().withImages,
            orderCategory: category.data().orderCategory,
            selectedOption: "option2",
          });
        }

        setTimeout(() => {
          this.props.dispatch(hideLoader());
        }, 500);
      });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.dispatch(showLoader());
    let doc = DB.collection("hoteles")
      .doc(`${this.props.userLogin}`)
      .collection("menues")
      .doc(this.menuId)
      .collection("categories")
      .doc(this.categoryId);
    doc
      .update({
        name: this.state.name,
        imageCategory: this.state.imageCategory,
        nameSpanish: this.state.nameSpanish,
        nameEnglish: this.state.nameEnglish,
        namePortuguese: this.state.namePortuguese,
        nameFrench: this.state.nameFrench,
        products: this.state.products,
        withImages: this.state.withImages,
        orderCategory: this.state.orderCategory,
      })
      .then((doc) => {
        this.props.dispatch(hideLoader());

        this.props.history.push(`/menu/${this.menuId}`);
        firebase.succesfullMsg("Category updated!");
      });
  }

  handleInputs(e) {
    let key = e.target.name;
    let input = e.target.value;
    this.setState({
      [key]: input,
    });
  }

  handleOptionChange(e) {
    this.setState({
      selectedOption: e.target.value,
      withImages: !this.state.withImages,
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Category
          inputs={this.handleInputs}
          submit={this.handleSubmit}
          category={this.state}
          handleOptionChange={this.handleOptionChange}
          optionSelected={this.state.selectedOption}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(EditCategoryContainer);
