import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import Configurations from "../../../restaurant/components/views/Configurations";
import { hideLoader, showLoader } from "../../../store/actions/loginAction";
import { connect } from "react-redux";

const DB = firebase.db;

let hotelDoc;
const MySwal = withReactContent(Swal);
//arreglar esto
// let local = JSON.parse(window.localStorage.getItem("persist:lovableLogin"));
// let userLS;
// if (local) {
//   userLS = JSON.parse(local.user);
// }
//arreglar esto

class ConfigurationsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotelInfo: {},
      quantityRooms: 0,
      lastRoom: 0,
    };
    this.addTableBtn = this.addTableBtn.bind(this);
    this.deleteTableBtn = this.deleteTableBtn.bind(this);
  }

  componentDidMount() {
    // if (userLS.isAuth === false) {
    //   this.props.history.push("/");
    // } else {

    if (this.props.hotelId) {
      this.props.dispatch(showLoader());

      hotelDoc = DB.collection("hoteles")
        //.doc("onUF1JBURucwWa2p9yHO");
        .doc(`${this.props.hotelId}`);
      hotelDoc.get().then((doc) => {
        this.setState({
          hotelInfo: doc.data(),
        });
        setTimeout(() => {
          this.props.dispatch(hideLoader());
        }, 500);
      });
      hotelDoc
        .collection("rooms")
        .get()
        .then((rooms) =>
          rooms.size < 10
            ? this.setState({
                quantityRooms: rooms.size,
                lastRoom: Number(`10${rooms.size}`),
              })
            : this.setState({
                quantityRooms: rooms.size,
                lastRoom: Number(`1${rooms.size}`),
              })
        );
    }
    // }
  }

  addTableBtn(e, id) {
    e.preventDefault();
    let lastRoomNumber = this.state.lastRoom;
    let newRoom = DB.collection("hoteles").doc(id).collection("rooms");
    MySwal.fire({
      title: "Agregar una Nueva Habitación?",
      text: "Por favor confirma tu acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4ADBA7",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.value) {
        MySwal.fire(
          "Habitación creada!",
          `Una nueva habitación fue agregada al panel.`,
          "success"
        );
        newRoom.add({
          clientActual: 0,
          mail: "",
          number: lastRoomNumber + 1,
          orderActual: 0,
          orderStatus: "",
          pay: false,
          secretCode: 0,
          state: "free",
          clean: false,
          noDisturb: false,
        });
      } else if (result.dismiss === "cancel") {
        this.props.history.go(0);
      }
      this.props.history.push("/dashboard");
    });
  }

  deleteTableBtn(e, id) {
    e.preventDefault();
    let lastRoom = this.state.lastRoom;
    let newRoom = DB.collection("hoteles")
      .doc(id)
      .collection("rooms")
      .where("number", "==", lastRoom);
    MySwal.fire({
      title: "¿Estás seguro que deseas eliminar una habitación?",
      text: "Por favor confirma tu acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4ADBA7",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.value) {
        MySwal.fire(
          "Habitación eliminada!",
          `La habitación número ${lastRoom} fue eliminada.`,
          "success"
        );
        newRoom.get().then((lastTable) => {
          lastTable.forEach((ultima) => {
            ultima.ref.delete();
          });
        });
      } else if (result.dismiss === "cancel") {
        this.props.history.go(0);
      }
      this.props.history.push("/dashboard");
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Configurations
          hotelInfo={this.state.hotelInfo}
          hotelId={this.props.hotelId}
          rol={this.props.rol}
          quantityRooms={this.state.quantityRooms}
          addTable={this.addTableBtn}
          deleteTable={this.deleteTableBtn}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hotelId: state.user.loginUser.hotelId,
    rol: state.user.loginUser.rol,
    isAuth: state.user.isAuth,
  };
};

export default connect(mapStateToProps)(ConfigurationsContainer);
