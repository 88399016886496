import React from "react";
import Propiedades from "../../components/views/Propiedades";
import firebase from "../../../services/firebase";

const DB = firebase.db;

class PropiedadesCliente extends React.Component {
  constructor(props) {
    super();
    this.state = {
      news: [],
      loader: true,
      date: "",
      hour: ""
    };
    this.handleClick = this.handleClick.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const day = new Date().getDay()
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday",]
    let dateGroup = days[day]
    let dateNowString = dateGroup.toString();
    const hours = new Date().getHours()
    let hoursNowString = hours.toString();

    this.setState({
      date: dateNowString,
      hour: hoursNowString,
    })

    let newsDoc = DB.collection("church")
      .doc("02NyPwGGExwDiTMeI70m")
      .collection("news")
      .doc("bu7gzK18MtT4eBfPF77g")

    newsDoc.get()
      .then((news) => {
        this.setState({
          news: news.data().news,
          loader: false,
        });
      })

  }

  handleClick(type) {
    if (type === "rightnow") {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = "https://apps.apple.com/ar/app/rightnow-media/id925747028";
      } else {
        window.location.href = "https://play.google.com/store/apps/details?id=com.rightnowmedia.rightnowmedia&hl=es_AR&gl=US";
      }
    } else {
      window.location.href = "https://www.youtube.com/user/lapuertaabierta2008"
    }
  }

  render() {
    return (
      <div>
        <Propiedades
          news={this.state.news}
          loader={this.state.loader}
          screenSize={window.screen.width}
          handleClick={this.handleClick}
          date={this.state.date}
          hour={this.state.hour}
        />
      </div>
    );
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(PropiedadesCliente);

export default PropiedadesCliente;
