import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import BagsMovements from "../../components/views/BagsMovements";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import {
    saveHotelId,
} from "../../../store/actions/hotelAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const DB = firebase.db;


const mapStateToProps = (state) => {
    return {
        hotelId: state.user.loginUser.hotelId,
        rol: state.user.loginUser.rol,
        isAuth: state.user.isAuth,
        absences: state.hotel.absences,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
        saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
    };
};

class BagsMovementsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hotel: {},
            loader: true,
            lastMoves: []
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        if (this.props.hotelId) {

            let lastMovesDoc = DB.collection("countrys")
                .doc(`${this.props.hotelId}`).collection("moveBagsHistory")

            lastMovesDoc.onSnapshot((peopleBack) => {
                peopleBack.forEach((people) => {
                    this.setState({ lastMoves: people.data().history })
                });
            })
        }
    }

    handleClick(e) {
    }

    render() {
        return (
            <div>
                <Sidebar />
                <BagsMovements
                    people={this.state.people}
                    handleClick={this.handleClick}
                    rol={this.props.rol}
                    lideresCant={this.state.lideresCant}
                    lastMoves={this.state.lastMoves}
                />
                <div>
                    <FullPageLoader />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BagsMovementsContainer);
