import React from "react";
import { Link } from "react-router-dom";

const styles = {
    container: {
        backgroundColor: "white",
        marginLeft: "250px",
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingTop: "20px",
    },
    createMenu: {
        textDecoration: "none",
        color: "#ffffff",
        backgroundColor: "#ff2068",
        borderColor: "#ff2068",
        marginTop: "20px",
        marginBottom: "10px",
    },
    textLatoBlack: {
        fontFamily: "LatoBlack",
    },
    textLato: {
        fontFamily: "Lato",
    },
    date: {
        outline: 'none'
    },
    textLatoBlack2: {
        fontFamily: "LatoBlack",
        // paddingLeft: '30px',
        margin: "40px 30px 20px"
    },
    text3: {
        margin: 0,
        display: "flex",
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
    },
};

export default ({ submit, input, state, handleDelete }) => {
    return (
        <div style={styles.container}>
            <h1 style={styles.textLatoBlack} className="font-weight-bold">Editar Bolsa</h1>

            <hr />
            <form
                onSubmit={(e) => submit(e)}
                style={{
                    margin: "30px",
                }}
            >
                <div
                    className="form-group"
                    style={{
                        paddingBottom: "20px",
                    }}
                >
                    <label
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Marca de Bolsa
              </label>
                    <input
                        onChange={(e) => input(e)}
                        name="brand"
                        type="text"
                        value={state.brand}
                        className="form-control"
                        placeholder="Marca de bolsa..."
                        required
                    />
                    <label
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Número de Socio
              </label>
                    <input
                        onChange={(e) => input(e)}
                        name="numberSocio"
                        type="text"
                        value={state.numberSocio}
                        className="form-control"
                        placeholder="Ingresar número de socio..."
                        required
                    />
                </div>
                <button
                    onClick={(e) => submit(e)}
                    className="btn btn-primary"
                    style={{
                        backgroundColor: "#FF2068",
                        borderColor: "#FF2068",
                        width: '180px',
                        //  marginRight: '20px'
                    }}
                >
                    Guardar Cambios
            </button>
            </form>
            <div
                style={{
                    marginLeft: '30px'
                }}
            >
                {/* <button
                    onClick={(e) => submit(e)}
                    className="btn btn-primary"
                    style={{
                        backgroundColor: "#FF2068",
                        borderColor: "#FF2068",
                        width: '180px',
                        marginRight: '20px'
                    }}
                >
                    Guardar Cambios
            </button> */}
                <button
                    //  type="submit"
                    onClick={() => handleDelete()}
                    className="btn btn-danger"
                    style={{
                        //  backgroundColor: "#FF2068",
                        // borderColor: "#FF2068",
                        width: '180px'
                    }}
                >
                    Eliminar
            </button>
            </div>
        </div>
    );
};
