import React from "react";

export default ({ submit, input }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        marginLeft: "250px",
        paddingBottom: "400px",
      }}
    >
      <div
        className="container"
        style={{
          paddingLeft: "35px",
          paddingRight: "35px",
          paddingTop: "20px",
        }}
      >
        <h1 className="font-weight-bold">Create Menu</h1>
        <div className="col">
          <form
            onSubmit={(e) => submit(e)}
            style={{
              padding: "40px",
              marginRight: "20px",
            }}
          >
            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <h3 className="font-weight-bold">Names</h3>

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Name
              </label>
              <input
                onChange={(e) => input(e)}
                name="nameOfMenu"
                type="text"
                className="form-control"
                placeholder="Menu name... "
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇪🇸 - Spanish Name
              </label>
              <input
                onChange={(e) => input(e)}
                name="nameOfMenuSpanish"
                type="text"
                className="form-control"
                placeholder="Menu name... "
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇺🇸 - English Name
              </label>
              <input
                onChange={(e) => input(e)}
                name="nameOfMenuEnglish"
                type="text"
                className="form-control"
                placeholder="Menu name... "
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇧🇷 - Portuguese Name
              </label>
              <input
                onChange={(e) => input(e)}
                name="nameOfMenuPortuguese"
                type="text"
                className="form-control"
                placeholder="Menu name... "
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇫🇷 - French Name
              </label>
              <input
                onChange={(e) => input(e)}
                name="nameOfMenuFrench"
                type="text"
                className="form-control"
                placeholder="Menu name... "
              />
              <h3
                style={{
                  marginTop: "30px",
                }}
                className="font-weight-bold"
              >
                Details
              </h3>
              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Image of Menu
              </label>
              <input
                onChange={(e) => input(e)}
                name="imageMenu"
                type="text"
                className="form-control"
                placeholder="URL Image"
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Hours
              </label>
              <input
                onChange={(e) => input(e)}
                name="hours"
                type="text"
                className="form-control"
                placeholder="Hours..."
                required
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                Order Number
              </label>
              <input
                onChange={(e) => input(e)}
                name="orderMenu"
                type="text"
                className="form-control"
                placeholder="1, 2, 3..."
              />
              <h3
                style={{
                  marginTop: "30px",
                }}
                className="font-weight-bold"
              >
                Aclarations
              </h3>
              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇪🇸 - Spanish Aclarations
              </label>
              <input
                onChange={(e) => input(e)}
                name="aclarationSpanish"
                type="text"
                className="form-control"
                placeholder="Tax included..."
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇺🇸 - English Aclarations
              </label>
              <input
                onChange={(e) => input(e)}
                name="aclarationEnglish"
                type="text"
                className="form-control"
                placeholder="Tax included..."
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇧🇷 - Portuguese Aclarations
              </label>
              <input
                onChange={(e) => input(e)}
                name="aclarationPortuguese"
                type="text"
                className="form-control"
                placeholder="Tax included..."
              />

              <label
                style={{
                  marginTop: "20px",
                }}
              >
                🇫🇷 - French Aclarations
              </label>
              <input
                onChange={(e) => input(e)}
                name="aclarationFrench"
                type="text"
                className="form-control"
                placeholder="Tax included..."
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              style={{
                backgroundColor: "#FF2068",
                borderColor: "#FF2068",
              }}
            >
              Create
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
