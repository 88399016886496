import React from "react";
import { withRouter } from "react-router-dom";
import LoginNoUser from "../../../client/components/views/LoginNoUser";
import { connect } from "react-redux";

class ClientLoginNoUserContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
    this.handlerChange = this.handlerChange.bind(this);
    this.handlerSubmit = this.handlerSubmit.bind(this);
  }

  handlerChange(e) {
    const key = e.target.name;
    const value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerSubmit(e) {
    e.preventDefault();
    if (
      this.state.code == "hgimontevideo" ||
      this.state.code == "Hgimontevideo" ||
      this.state.code == "HGImontevideo" ||
      this.state.code == "HGIMontevideo" ||
      this.state.code == "HGIMONTEVIDEO"
    ) {
      this.props.history.push(`/Q0bnTWW89sbvONole8ry/language`);
    }
    if (
      this.state.code == "hiltonbuenosaires" ||
      this.state.code == "Hiltonbuenosaires" ||
      this.state.code == "HiltonBuenosAires" ||
      this.state.code == "HILTONBUENOSAIRES"
    ) {
      this.props.history.push(`/fNIFexhS3r0NsJl5qRhn/language`);
    }
    if (
      this.state.code == "hiltonlimamiraflores" ||
      this.state.code == "Hiltonlimamiraflores" ||
      this.state.code == "HiltonLimaMiraflores" ||
      this.state.code == "HILTONLIMAMIRAFLORES"
    ) {
      this.props.history.push(`/TWdKMA7PTFfPPG7wdwwu/language`);
    }
  }

  render() {
    return (
      <div>
        <LoginNoUser
          handleClick={this.handleClick}
          handlerChange={this.handlerChange}
          handlerSubmit={this.handlerSubmit}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, state) => {
  return {};
};

export default withRouter(
  connect(null, mapDispatchToProps)(ClientLoginNoUserContainer)
);
