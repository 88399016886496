import React from "react";
import TakeAssistant from "../../components/views/TakeAssistant";
import firebase from "../../../services/firebase";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
    saveHotelId,
} from "../../../store/actions/hotelAction";
//import withReactContent from "sweetalert2-react-content";
const DB = firebase.db;
//const MySwal = withReactContent(Swal);


const mapStateToProps = (state) => {
    return {
        userLogin: state.user.loginUser,
        isAuth: state.user.isAuth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
        // loggeado: (firstName, lastName, rol, liderOf) => dispatch(loginUserClient(firstName, lastName, rol, liderOf)),
    };
};



class TakeAssistantContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            personsClass: [],
            loader: true,
            noPersons: [],
            arrayEachPerson: [],

            personsOk: [],
            personsNo: [],
            oneAction: false
        };
        this.handlerChange = this.handlerChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.saveHotelId("1");
    }

    componentDidUpdate() {
        if (this.state.oneAction === false) {
            if (this.props.userLogin.firstNameRedux) {


                //            console.log(this.props.userLogin)
                let arrayPerson = [];
                //    let arrayPersonNo = [];

                let personClass = DB.collection("church").doc("02NyPwGGExwDiTMeI70m").collection("people").where('class', '==', `${this.props.userLogin.liderOfRedux}`)
                //  let personClass = DB.collection("church").doc("02NyPwGGExwDiTMeI70m").collection("people").where('class', '==', `+23`)
                personClass.get().then((result) => {
                    result.forEach((resultDoc) => {

                        let objectTrasfer = {}
                        objectTrasfer = resultDoc.data()
                        objectTrasfer.id = resultDoc.id

                        arrayPerson.push(objectTrasfer)
                        //    console.log("id", resultDoc.id)
                        //           arrayPersonNo.push(`${resultDoc.data().firstName} ${resultDoc.data().lastName}`)
                    })
                    this.setState({
                        personsClass: arrayPerson,
                        //           personsNo: arrayPersonNo,
                        loader: false,
                    });
                    //       console.log("personas", this.state.personsClass)
                })

                this.setState({ oneAction: true })
            }
        }
    }


    handlerChange(singlePerson, action) {

        //  console.log(singlePerson.id)

        let noPersonsArray = this.state.noPersons

        if (action === "delete") {
            // noPersonsArray.splice(noPersonsArray.indexOf(e => e.id === singlePerson.id), 1)
            noPersonsArray.splice(noPersonsArray.findIndex(i => i.id === singlePerson.id), 1)
        } else {
            noPersonsArray.push(
                //singlePerson.id
                {
                    id: singlePerson.id,
                    fullName: `${singlePerson.firstName} ${singlePerson.lastName}`
                }

            )
        }

        //  console.log(noPersonsArray)


        // let persons = this.state.personsOk
        // let personsNoArray = this.state.personsNo

        // let nombreCompleto = `${e.firstName} ${e.lastName}`

        // if (action === "delete") {
        //     persons.splice(persons.indexOf(`${e.firstName} ${e.lastName}`), 1)
        //     personsNoArray.push(nombreCompleto)
        // } else {
        //     persons.push(nombreCompleto)
        //     personsNoArray.splice(personsNoArray.indexOf(`${e.firstName} ${e.lastName}`), 1)
        // }

        // console.log("Personas que vinieron:", persons)
        // console.log("Personas que NO vinieron:", personsNoArray)

    }


    handleClick(e) {
        //   e.preventDefault();

        Swal.fire({
            title: '¿Estás seguro que NO vinieron?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#339AE7',
            cancelButtonColor: '#EF3054',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Re seguro!',
            reverseButtons: true,
            showCloseButton: true,
        }).then((result) => {
            //  console.log(result)
            if (result.value) {

                this.setState({
                    loader: true,
                });

                let year = new Date().getFullYear()
                let month = new Date().getMonth() + 1
                const date = new Date().getDate()
                let dateAbsences = (date + "/" + month + "/" + year)

                let dateTotal = new Date().getTime()

                let absencesDoc = DB.collection("church")
                    .doc("02NyPwGGExwDiTMeI70m").collection("absences").doc();

                absencesDoc.set(
                    {
                        class: `${this.props.userLogin.liderOfRedux}`,
                        lider: `${this.props.userLogin.firstNameRedux} ${this.props.userLogin.lastNameRedux}`,
                        date: dateAbsences,
                        realDate: dateTotal,
                        //  personsOk: this.state.personsOk,
                        personsNo: this.state.noPersons,
                    }
                );


                let informationToWrite = {
                    class: `${this.props.userLogin.liderOfRedux}`,
                    lider: `${this.props.userLogin.firstNameRedux} ${this.props.userLogin.lastNameRedux}`,
                    date: dateAbsences,
                    realDate: dateTotal,
                }

                let personToWrite = this.state.noPersons

                personToWrite.map((person) => {

                    let personDirection = DB.collection("church").doc("02NyPwGGExwDiTMeI70m").collection("people").doc(`${person.id}`)

                    personDirection.get().then((result) => {


                        if (result.data().absences) {

                            this.setState({ arrayEachPerson: result.data().absences }, () => {

                                let arrayToPush = this.state.arrayEachPerson.slice()
                                arrayToPush.unshift(informationToWrite)

                                personDirection.update({
                                    absences: arrayToPush
                                })
                                Swal.fire({
                                    title: "Gracias!",
                                    text: "Se han guardado las inasistencias.",
                                    icon: "success",
                                    showCancelButton: false,
                                    confirmButtonColor: "#339AE7",
                                    confirmButtonText: "Continuar",
                                });
                                this.props.history.push("/home");
                            })

                        } else {
                            personDirection.update({
                                absences: [informationToWrite]
                            })
                            Swal.fire({
                                title: "Gracias!",
                                text: "Se han guardado las inasistencias.",
                                icon: "success",
                                showCancelButton: false,
                                confirmButtonColor: "#339AE7",
                                confirmButtonText: "Continuar",
                            });
                            this.props.history.push("/home");
                        }


                    })
                })

            }
        })


    }

    render() {
        return (
            <div>
                <TakeAssistant
                    personsClass={this.state.personsClass}
                    personsOk={this.state.personsOk}
                    noPersons={this.state.noPersons}
                    loader={this.state.loader}
                    screenSize={window.screen.width}
                    handlerChange={this.handlerChange}
                    handleClick={this.handleClick}
                />
            </div >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TakeAssistantContainer);

//export default TakeAssistantContainer;
