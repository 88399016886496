import React from "react";
import Messages from "../../components/views/Messages";
import firebase from "../../../services/firebase";
import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
const DB = firebase.db;
//const MySwal = withReactContent(Swal);

class MessagesContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      news: [],
      peopleToContact: [],
      loader: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let newsDoc = DB.collection("countrys")
      .doc("lVGwoAyzUnN1ygmvEE3T")
      .collection("activities")
      .doc("nHM4Tf095sYWYahYZYnn")

    newsDoc.get()
      .then((news) => {
        this.setState({
          news: news.data().activities,
          loader: false,
        });
      })

    // this.setState({
    //   //  wallpapers: screenshot.data().screenshots,
    //   loader: false,
    // });


  }

  render() {
    return (
      <div>
        <Messages
          news={this.state.news}
          loader={this.state.loader}
          screenSize={window.screen.width}
        />
      </div>
    );
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(PropiedadesCliente);

export default MessagesContainer;
