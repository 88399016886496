import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
//import Product from "../../../restaurant/components/edit/Configurations";
import { connect } from "react-redux";
import Configurations from "../../../restaurant/components/edit/Configurations";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
const DB = firebase.db;
// let local = JSON.parse(window.localStorage.getItem("persist:lovableLogin"));
// let userLS;
// if (local) {
//   userLS = JSON.parse(local.user);
// }

const mapStateToProps = (state) => {
  return {
    hotelId: state.user.loginUser.hotelId,
    isAuth: state.user.isAuth,
  };
};

class EditConfigurations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mail: "",
      phone: "",
      logoImage: "",
      backgroundImage: "",
      primaryColor: "",
      titleApp: "",
      subtitleApp: "",
      infoApp: "",
      whatsappNumber: "0",
      solutionWhatsapp: false,
    };
    this.handleInputs = this.handleInputs.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // if (userLS.isAuth === false) {
    //   this.props.history.push("/");
    // } else {
    if (this.props.hotelId) {
      this.props.dispatch(showLoader());
      let doc = DB.collection("hoteles").doc(`${this.props.hotelId}`);
      doc.get().then((hotelInfo) => {
        this.setState({
          name: hotelInfo.data().name,
          mail: hotelInfo.data().mail,
          phone: hotelInfo.data().phone,
          logoImage: hotelInfo.data().logoImage,
          backgroundImage: hotelInfo.data().backgroundImage,

          primaryColor: hotelInfo.data().primaryColor,
          titleApp: hotelInfo.data().titleApp,
          subtitleApp: hotelInfo.data().subtitleApp,
          infoApp: hotelInfo.data().infoApp,
          whatsappNumber: hotelInfo.data().whatsappNumber,
          solutionWhatsapp: hotelInfo.data().solutionWhatsapp,
        });
        setTimeout(() => {
          this.props.dispatch(hideLoader());
        }, 500);
      });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.dispatch(showLoader());
    let docHotel = DB.collection("hoteles").doc(`${this.props.hotelId}`);
    docHotel.update(this.state).then((doc) => {
      this.props.dispatch(hideLoader());

      this.props.history.push(`/configurations`);
      firebase.succesfullMsg("Information updated!");
    });
  }

  handleInputs(e) {
    let key = e.target.name;
    let input = e.target.value;
    this.setState({
      [key]: input,
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Configurations
          inputs={this.handleInputs}
          submit={this.handleSubmit}
          hotel={this.state}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(EditConfigurations);
