import React from "react";

const styles = {
  container: {
    marginLeft: "250px",
    display: "flex",
    flexDirection: "column",
  },
  center: {
    //flex: 3,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "center",
    padding: "40px",
  },
  title: {
    padding: "10px",
  },
  noDisturb: {
    marginTop: "20px",
    backgroundColor: "#ff2068",
    color: "white",
    padding: "20px",
    paddingLeft: "30px",
    paddingRight: "30px",
    borderRadius: "50px",
  },
  button: {
    backgroundColor: "#ff2068",
    borderColor: "#ff2068",
    marginBottom: "100px",
    width: "200px",
  },
  services: {
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "20px",
  },
  separations: {
    marginTop: "20px",
  },
  separations30: {
    marginTop: "30px",
  },
  buttonAlerts: {
    backgroundColor: "#ff2068",
    borderColor: "#ff2068",
    width: "250px",
  },
};

export default ({
  buttonClick,
  room,
  order,
  productArray,
  orderHandler,
  roomHandler,
  orderId,
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.center}>
        <h1
          style={styles.title}
          className="font-weight-bold"
        >{`Habitación ${room.number}`}</h1>
        {room.state === "busy" ? (
          <>
            <h5 className="font-weight-normal">
              Este es el resumen de la habitación.
            </h5>
            <>
              {room.noDisturb ? (
                <h5 className="font-weight-normal" style={styles.noDisturb}>
                  No molestar
                </h5>
              ) : (
                <></>
              )}
            </>
          </>
        ) : (
          <h5 className="font-weight-normal">Esta habitación esta vacía.</h5>
        )}
      </div>

      <div className="container" style={styles.services}>
        {room.state === "busy" ? (
          <div>
            {room.orderActual !== 0 ? (
              <h3 className="font-weight-bold">{`Servicio de comida ${orderId}`}</h3>
            ) : (
              <h3 className="font-weight-bold">{`Servicio de comida`}</h3>
            )}
            <div>
              {room.orderStatus !== "" && room.orderStatus !== "draft" ? (
                <div style={styles.separations}>
                  <table className="table table-bordered">
                    <thead>
                      <th>Productos</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Comentarios</th>
                    </thead>
                    <tbody>
                      {productArray
                        ? productArray.map((product, index) => {
                            return (
                              <tr key={index}>
                                <td>{product.name}</td>
                                <td>{product.quantity}</td>
                                <td>{product.price}</td>
                                <td>{product.comments}</td>
                              </tr>
                            );
                          })
                        : null}
                      <tr>
                        <td>Total</td>
                        <td></td>
                        <td></td>
                        <td>{order.totalPrice}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div style={styles.center}>
                    <h3 style={styles.separations30}>
                      Estado: <strong>{order.status}</strong>
                    </h3>

                    <div>
                      {room.orderStatus === "pending" ? (
                        <div>
                          <button
                            onClick={(e) =>
                              orderHandler(
                                e,
                                room.orderActual,
                                "accepted",
                                room.number
                              )
                            }
                            style={{
                              backgroundColor: "#2EC4B6",
                              borderColor: "#2EC4B6",
                              marginRight: "20px",
                            }}
                            className="btn btn-primary btn-lg"
                          >
                            Confirmar
                          </button>

                          <button
                            onClick={(e) =>
                              orderHandler(
                                e,
                                room.orderActual,
                                "canceled",
                                room.number
                              )
                            }
                            style={{
                              backgroundColor: "#ff2068",
                              borderColor: "#ff2068",
                            }}
                            className="btn btn-primary btn-lg"
                          >
                            Cancelar
                          </button>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <ul className="list-group" style={styles.separations}>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Sin órdenes pendientes.
                  </li>
                </ul>
              )}
            </div>

            <h3 style={styles.separations30} className="font-weight-bold">
              Alertas
            </h3>

            <ul className="list-group" style={styles.separations30}>
              {room.clean ? (
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Pedido de Limpieza
                  <button
                    onClick={(e) => roomHandler(e, room.id, "clean")}
                    style={styles.buttonAlerts}
                    className="btn btn-primary btn-lg"
                  >
                    Remover alerta
                  </button>
                </li>
              ) : (
                <></>
              )}

              {room.pay && room.clean ? (
                <></>
              ) : (
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Sin alertas pendientes.
                </li>
              )}
            </ul>
            <div style={styles.center}>
              <button
                onClick={(e) => buttonClick(e, "completed")}
                className="btn btn-primary btn-lg"
                style={styles.button}
              >
                Cerrar Habitación
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
