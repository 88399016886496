import { SET_NEW_PRODUCT, SET_EMPTY_CART } from "../reducers/constant";

const initialState = {
  cartData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_PRODUCT:
      //  return { cartData: [action.productData] };

      return { ...state, cartData: [...state.cartData, action.productData] };

    case SET_EMPTY_CART:
      return { ...state, cartData: [] };

    default:
      return state;
  }
};
