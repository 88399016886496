import React from "react";
import { Link } from "react-router-dom";

const styles = {
  container: {
    backgroundColor: "white",
    marginLeft: "250px",
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "20px",
  },
  createMenu: {
    textDecoration: "none",
    color: "#ffffff",
    backgroundColor: "#ff2068",
    borderColor: "#ff2068",
    marginTop: "20px",
    marginBottom: "10px",
  },
  textLatoBlack: {
    fontFamily: "LatoBlack",
  },
  textLato: {
    fontFamily: "Lato",
  },
  text1: {
    //margin: 0,
    fontSize: 20,
    color: "#000",
    fontFamily: "LatoBold",
  },
  text2: {
    margin: 0,
    fontSize: 18,
    color: "#000",
    fontFamily: "Lato",
  },
};

export default ({ news, handleClick, rol }) => {
  return (
    <div style={styles.container}>
      <h1 style={styles.textLatoBlack} >Novedades</h1>

      {rol === "admin" ? (
        <Link
          style={styles.createMenu}
          className="btn btn-primary"
          to="/news/create"
        >
          Crear nueva
        </Link>
      ) : (
        <></>
      )}

      <hr />

      <ul
        className="list-group"
        style={{
          marginTop: "30px",
          marginBottom: "100px"
        }}
      >
        {news ? (
          news.map((singleNew, index) => {
            return (
              <li
                key={singleNew.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <div
                  style={{
                    display: "flex",
                    flex: 12,
                    padding: '0px'
                  }}
                >
                  <Link
                    style={{
                      display: "flex",
                      flex: 3,
                      margin: '0px',
                      padding: '0px',
                      marginRight: "30px",
                    }}
                    to={`/news-admin/${index}`}
                  >
                    {singleNew.image !== "" ? (
                      <img
                        style={{
                          width: '100%',
                        }}
                        src={singleNew.image}
                        alt="news img"
                      />
                    ) : (
                      <img
                        style={{
                          width: '100%',
                        }}
                        src="https://i.postimg.cc/xC2cXrXW/image.png"
                        alt="news img"
                      />
                    )}
                  </Link>
                  <Link
                    style={{
                      display: "flex",
                      flex: 9,
                      margin: '0px',
                      padding: '0px',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                    to={`/news-admin/${index}`}
                  >
                    <h1 style={styles.text1}>{singleNew.title}</h1>
                    <h1 style={styles.text2}>{singleNew.description}</h1>
                  </Link>
                  <button
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      textDecoration: "none",
                      color: "inherit",
                      border: 'none',
                      outline: '0',
                      padding: 0,
                      alignItems: 'center',
                      margin: '0px 5px 0px 0px'
                    }}
                    onClick={() => handleClick("news", singleNew.title, singleNew.description)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                    </svg>
                  </button>
                </div>
              </li>
            );
          })
        ) : (
          <li>Sin datos.</li>
        )}
      </ul>
    </div>
  );
};
