import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import loginClientReducer from "./loginClientReducer";
import menuReducer from "./menuReducer";
import cartReducer from "./cartReducer";
import hotelReducer from "./hotelReducer";
import menuClientReducer from "./menuClientReducer";

export default combineReducers({
  user: loginReducer,
  saveLoginClient: loginClientReducer,
  menuArray: menuReducer,
  cart: cartReducer,
  hotel: hotelReducer,
  menu: menuClientReducer,
});
