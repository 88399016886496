import React from "react";

const styles = {
    container: {
        backgroundColor: "white",
        marginLeft: "250px",
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingTop: "20px",
    },
    createMenu: {
        textDecoration: "none",
        color: "#ffffff",
        backgroundColor: "#ff2068",
        borderColor: "#ff2068",
        marginTop: "20px",
        marginBottom: "10px",
    },
    textLatoBlack: {
        fontFamily: "LatoBlack",
    },
    textLato: {
        fontFamily: "Lato",
    },
};

export default ({ submit, input, inputsOfState }) => {
    return (
        <div style={styles.container}>
            <h1 style={styles.textLatoBlack} className="font-weight-bold">Editar Item</h1>
            <hr />
            <form
                onSubmit={(e) => submit(e)}
                style={{
                    margin: "30px",
                }}
            >
                <div
                    className="form-group"
                    style={{
                        paddingBottom: "20px",
                    }}
                >
                    <label
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Nombre del Item
                    </label>
                    <input
                        onChange={(e) => input(e)}
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Ingresar nombre..."
                        value={inputsOfState.name}
                        required
                    />
                    <label
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Descripción
                    </label>
                    <input
                        onChange={(e) => input(e)}
                        name="description"
                        type="text"
                        className="form-control"
                        placeholder="Ingresar descripción..."
                        value={inputsOfState.description}
                    />
                    <label
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Imagen
                    </label>
                    <input
                        onChange={(e) => input(e)}
                        name="photo"
                        type="text"
                        className="form-control"
                        placeholder="Ingresar URL..."
                        value={inputsOfState.photo}
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                        backgroundColor: "#FF2068",
                        borderColor: "#FF2068",
                    }}
                >
                    Crear Item
                </button>
            </form>
        </div>
    );
};
