import {
  SET_OUTLETS_INFO,
  SET_MENUES_INFO,
  SET_CATEGORIES_INFO,
  SET_PRODUCTS_INFO,
  SET_CATEGORIES_HISTORY,
  SET_CATEGORIES_BACKUP,
} from "../reducers/constant";

const addOutletsInfo = (menuesData) => ({
  type: SET_OUTLETS_INFO,
  menuesData,
});

const addMenuesInfo = (menuesData) => ({
  type: SET_MENUES_INFO,
  menuesData,
});

const addCategoryInfo = (categoryData) => ({
  type: SET_CATEGORIES_INFO,
  categoryData,
});

const addCategoryBackup = (categoryData) => ({
  type: SET_CATEGORIES_BACKUP,
  categoryData,
});

const addProductInfo = (productData) => ({
  type: SET_PRODUCTS_INFO,
  productData,
});

const addCategoryHistory = (categoryHistory) => ({
  type: SET_CATEGORIES_HISTORY,
  categoryHistory,
});

// const addIdTable = table => ({
//   type: SET_ID_TABLE,
//   table
// });

// const addIdRestaurant = restaurant => ({
//   type: SET_ID_RESTAURANT,
//   restaurant
// });

export const saveOutlets = (menuesInfo) => (dispatch) =>
  dispatch(addOutletsInfo(menuesInfo));

export const saveMenues = (menuesInfo) => (dispatch) =>
  dispatch(addMenuesInfo(menuesInfo));

export const saveCategories = (categoryInfo) => (dispatch) =>
  dispatch(addCategoryInfo(categoryInfo));

export const saveCategoriesBackup = (categoryInfo) => (dispatch) =>
  dispatch(addCategoryBackup(categoryInfo));

export const saveProducts = (productInfo) => (dispatch) =>
  dispatch(addProductInfo(productInfo));

export const saveCategoriesHistory = (categoryHistoryInfo) => (dispatch) =>
  dispatch(addCategoryHistory(categoryHistoryInfo));

// export const saveTable = table => dispatch => dispatch(addIdTable(table));

// export const saveRestaurant = restaurant => dispatch =>
//   dispatch(addIdRestaurant(restaurant));
