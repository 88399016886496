import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//const Background =
// "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbackground-app.png?alt=media&token=18234069-f4d0-46c7-b98e-6978e6ef68e3";

const styles = {
  topCenter: {
    flex: 4,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "center",
    // marginLeft: "30px",
    //  backgroundColor: "green",
  },

  center: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    // flexFlow: "column",
    alignItems: "center",
    // marginLeft: "30px",
    //  backgroundColor: "green",
  },

  marketingTitle: {
    margin: 0,
    marginTop: "10px",
    fontSize: 25,
    fontWeight: 400,
    textAlign: "center",
    color: "#ffffff",

    // fontSize: 55,
    fontFamily: "Lato",
    // backgroundColor: "#ff2068",
  },

  buttonsBlack: {
    flex: 7,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    //flexWrap: "wrap",
    //margin: "0 30px 0 30px",
    // backgroundColor: "white",
    // paddingTop: "10px",
    //  backgroundColor: "grey",
  },

  buttonBlack: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "70vw",
    backgroundColor: "#fff",
    borderRadius: "30px 0 0 30px",
    marginTop: "0px",
    marginBottom: "30px",
  },

  buttonsWeb: {
    flex: 7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    inherit: "none",
  },

  buttonWeb: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width: "25%",
    // height: "50%",
    width: "250px",
    height: "250px",
    // width: "20vw",
    // height: "20vw",
    backgroundColor: "#fff",
    borderRadius: "150px",
    margin: "20px",
    // marginBottom: "20px",
  },

  textButton: {
    margin: 0,
    fontSize: 25,
    //fontWeight: 900,
    textAlign: "center",
    color: "#000",
    padding: "20px",
    inherit: "none",
    fontFamily: "LatoBlack",
  },
  // textButton: {
  //   margin: 0,
  //   fontSize: 25,
  //   //fontWeight: 900,
  //   textAlign: "center",
  //   color: "#000",
  //   padding: "15px",
  //   inherit: "none",
  //    fontFamily: "LatoBlack",
  // },
  buttonFloat: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "50px",
    //backgroundColor: "green",
  },
};

export default ({
  handleClick,
  room,
  hotel,
  whatsapp,
  propsOfHotelId,
  propsOfRoomId,
  loader,
  screenSize,
}) => {
  return (
    <>
      {loader == false ? (
        <>
          {screenSize > 600 ? (<></>
          ) : (
            <div
              style={{
                position: "fixed",
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                backgroundImage: `url("https://i.postimg.cc/RhPKbM9L/splash-background.png")`,
                //backgroundImage: `url("https://i.postimg.cc/sXR4LKR8/background-myplace.png")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div style={styles.topCenter}>
                <img
                  src="https://i.postimg.cc/Sxn73zn2/logo-sandiego.png"
                  height="60"
                  alt="Logo"
                />
              </div>

              <div style={styles.center}>
                <p style={styles.marketingTitle}>¡Que bueno tenerte!</p>
              </div>

              <>
                <div style={styles.buttonsBlack}>
                  {/* <Link
                    style={styles.buttonBlack}
                    to={`/register`}
                  // onClick={() => handleClick("spanish")}
                  >
           
                    <h1 style={styles.textButton}>Registrarme</h1>
                  </Link> */}
                  <Link
                    style={styles.buttonBlack}
                    to={`/login-app`}
                  // onClick={() => handleClick("spanish")}
                  >
                    {/* <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-Panama%2Fflag-espan%CC%83a.png?alt=media&token=4374b83f-c3d8-4a92-a97c-7b8b50e1fe06"
                  height="100"
                  alt=""
                /> */}
                    <h1 style={styles.textButton}>Iniciar Sesión</h1>
                  </Link>

                  <Link
                    style={styles.buttonBlack}
                    to={`/home`}
                  // onClick={() => handleClick("spanish")}
                  >
                    {/* <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-Panama%2Fflag-espan%CC%83a.png?alt=media&token=4374b83f-c3d8-4a92-a97c-7b8b50e1fe06"
                  height="100"
                  alt=""
                /> */}
                    <h1 style={styles.textButton}>Invitado</h1>
                  </Link>

                  {/* <Link
                    style={styles.buttonBlack}
                    to={`/home`}
                    // to={`/vender`}
                    // onClick={() => handleClick("english")}
                  >

                    <h1
                      style={styles.textButton}
                  
                    >
                      Inicio
                    </h1>
                  </Link> */}

                  {/* <Link
                    style={styles.buttonBlack}
                    to={`/alquilar`}
                    //onClick={() => handleClick("english")}
                  >
                    <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-Panama%2Fflag-usa.png?alt=media&token=4562245a-8a51-4198-b298-938ad1e0ed50"
                  height="100"
                  alt=""
                />
                    <h1 style={styles.textButton}>Alquilar</h1>
                  </Link> */}
                </div>
              </>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
