import React from "react";
import Claims from "../../components/views/Claims";
import firebase from "../../../services/firebase";

const DB = firebase.db;

class ReclamosCliente extends React.Component {
    constructor(props) {
        super();
        this.state = {
            claims: [],
            loader: true,
        };
        this.handleClick = this.handleClick.bind(this);

    }

    componentDidMount() {
        window.scrollTo(0, 0);

        let claimsDoc = DB.collection("countrys")
            .doc("lVGwoAyzUnN1ygmvEE3T")
            .collection("claims")

        claimsDoc.get()
            .then((claimsTotal) => {

                let claimsArray = [];
                claimsTotal.forEach((claim) => {
                    claimsArray.push(claim.data());
                });
                this.setState({
                    claims: claimsArray,
                    loader: false,
                });
            })

    }

    handleClick(type) {
        if (type === "add") {
            //agregar accion de proveedores.
        }
    }

    render() {
        return (
            <div>
                <Claims
                    claims={this.state.claims}
                    loader={this.state.loader}
                    screenSize={window.screen.width}
                    handleClick={this.handleClick}
                />
            </div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(PropiedadesCliente);

export default ReclamosCliente;
