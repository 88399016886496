import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const Background =
  "https://i.postimg.cc/RhPKbM9L/splash-background.png";

const styles = {
  container: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    // backgroundColor:"blue",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "0 0 40px 0",

  },
  containerFlex: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  top: {
    flex: 6,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    marginLeft: "30px",
  },
  title: {
    fontSize: "45px",
    color: "#fff",
    // fontWeight: "bold",
    fontFamily: "LatoBlack",
    margin: "0"
  },
  bottom: {
    flex: 6,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "40px 0 0 0",
    overflow: "auto",
  },
  form: {
    // marginBottom:'20px',
    width: "80%",
    height: '100%',
    marginTop: '20px',

  },
  label: {
    marginTop: '20px',
    fontSize: "18px",
    color: "#000",
    // fontWeight: "bold",
    fontFamily: "LatoBold",
  },

  labelPhoto: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: '#ff2068',
    padding: '15px',

    marginTop: '30px',
    fontSize: "18px",
    color: "#fff",
    fontFamily: "LatoBold",
    borderRadius: "10px",
  },

  photo: {
    // display: 'none'
  },
  button: {
    backgroundColor: "#008477",
    borderColor: "#008477",
    fontSize: "20px",
    width: "100%",
    marginTop: "20px",
    height: "60px",
    borderRadius: "10px",
    marginBottom: '40px',
    fontFamily: "LatoBold",
    //fontWeight: "bold",
  },
};

export default ({ handlerSubmit, handlerChange, birthday, loader }) => {
  return (
    <>
      {loader === false ? (
        <>
          <div style={styles.container}>
            <div style={styles.top}>
              {/* <img
          src="https://insideone.s3-sa-east-1.amazonaws.com/logo-lovable.png"
          height="50"
          alt=""
        /> */}

              <h1 style={styles.title}>Inicia Sesión!</h1>

            </div>

            <div style={styles.bottom}>
              <form style={styles.form} onSubmit={(e) => handlerSubmit(e)}>
                <div className="form-group">



                  <label style={styles.label}>Mail</label>
                  <input
                    name="mail"
                    type="text"
                    className="form-control"
                    onChange={handlerChange}
                    placeholder="hola@mail.com"
                    required
                  />

                  <label for="password" style={styles.label}>Contraseña</label>
                  <input
                    name="password"
                    id="password"
                    type="password"
                    className="form-control"
                    onChange={handlerChange}
                    // onChange={onFileResize}
                    // style={styles.photo}
                    required
                  />

                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={styles.button}
                >
                  Iniciar Sesión
          </button>
              </form>
            </div>
          </div>
        </>) : (<div style={styles.containerFlex}>
          <Loader
            type="TailSpin"
            //color="#ff2068"
            color="#339AE7"
            height={100}
            width={100}
            timeout={10000} //3 secs
          />
        </div>)}</>
  );
};
