import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const firebaseConfig = {
  apiKey: "AIzaSyBWXB3GHzmucPaplmmliHsRoyAWLrFkRsk",
  authDomain: "connected-country.firebaseapp.com",
  databaseURL: "https://connected-country.firebaseio.com",
  projectId: "connected-country",
  storageBucket: "connected-country.appspot.com",
  messagingSenderId: "899154857327",
  appId: "1:899154857327:web:ed3019ea558ca37c4cec9a",
  measurementId: "G-XVM7M16EQH"
};

//Utility class
class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    //reference to auth api
    this.auth = app.auth();
    //access to cloud firestore
    this.db = app.firestore()
    //access to cloud functions
    //this.functionsfirebase = app.functions()
    //access to cloud storage
    this.storage = app.storage().ref()
  }


  login(email, password) {
    //Nos retorna una promesa, lo manejamos mas adelante
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut();
  }
  isInitialized() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  getCurrentUsername() {
    return this.auth.currentUser.displayName;
  }

  succesfullMsg(param) {
    MySwal.fire({
      position: "center",
      icon: "success",
      title: param,
      showConfirmButton: false,
      timer: 1500,
    });
  }
}

export default new Firebase();
