import React from "react";
import { Link } from "react-router-dom";

const styles = {
    container: {
        backgroundColor: "white",
        marginLeft: "250px",
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingTop: "20px",
    },
    createMenu: {
        textDecoration: "none",
        color: "#ffffff",
        backgroundColor: "#ff2068",
        borderColor: "#ff2068",
        marginTop: "20px",
        marginBottom: "10px",
    },
    createMenu2: {
        textDecoration: "none",
        color: "#ff2068",
        backgroundColor: "#fff",
        borderColor: "#ff2068",
        marginTop: "20px",
        marginBottom: "10px",
    },
    textBold: {
        margin: 0,
        display: "flex",
        flex: 4,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },
    text4BoldCenter: {
        margin: 0,
        display: "flex",
        flex: 4,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
        justifyContent: 'center',
        alignItems: 'center'
    },
    text2Bold: {
        margin: 0,
        display: "flex",
        flex: 2,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },
    text2BoldCenter: {
        margin: 0,
        display: "flex",
        flex: 2,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    text3Bold: {
        margin: 0,
        display: "flex",
        flex: 3,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },

    text: {
        margin: 0,
        display: "flex",
        flex: 6,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
    },
    text2: {
        margin: 0,
        display: "flex",
        flex: 6,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
        margin: 0,
    },
    divAbsences: {
        display: "flex",
        flex: 2,
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    text4: {
        margin: 0,
        display: "flex",
        flex: 4,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBlack",
        margin: 0,
    },

    text5: {
        margin: 0,
        display: "flex",
        flex: 4,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
        margin: 0,
    },

    titleAnalytic: {
        color: "#000",
        //  fontWeight: "bold",
        textAlign: "center",
        fontSize: "35px",
        fontFamily: "LatoBlack",
    },
    titleDashboard: {
        color: "#000",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "25px",
    },
    descriptionAnalytic: {
        color: "#000",
        textAlign: "center",
        // fontWeight: "400",
        marginBottom: "0px",
        fontFamily: "Lato",
    },
    textLatoBlack: {
        fontFamily: "LatoBlack",
    },
    textLato: {
        fontFamily: "Lato",
    },
    textPerson: {
        fontFamily: "Lato",
        fontSize: "18px",
    },
    textLatoBold: {
        fontFamily: "LatoBold",
        fontSize: "25px",
        margin: 0
    },

    textLatoBold2: {
        fontFamily: "LatoBold",
        fontSize: "25px",
        // margin: 0,
        margin: "25px 0px 0px 0px"
    },
    divPhoto: {
        display: "flex",
        flex: 2,
    },
    photo: {
        border: "0.1 px solid #000",
        width: '100px',
    },
    text7: {
        margin: 0,
        display: "flex",
        flex: 3,
        fontSize: 18,
        color: "#000",
        fontFamily: "Lato",
    },
    text8: {
        margin: 0,
        display: "flex",
        flex: 9,
        fontSize: 18,
        color: "#000",
        fontFamily: "LatoBold",
    },

};

export default ({ people, lideresCant, rol, handleClick, simpleBag }) => {
    //  console.log("cantidad:", people.length)
    return (
        <div style={styles.container}>
            {people.type === "bag" ? (<h1 style={styles.textLatoBlack}>Bolsa {people.brand}</h1>) : (<h1 style={styles.textLatoBlack}>Carro {people.brand}</h1>)}

            <h1 style={styles.textPerson}>{`${people.fullName} (${people.numberSocio})`}</h1>

            <hr />

            <h1 style={styles.textLatoBold}>Movimientos</h1>
            {rol === "admin" ? (
                <Link
                    style={styles.createMenu2}
                    className="btn btn-primary"
                    to={`/bags/${simpleBag}/movement`}
                >
                    Nuevo movimiento
                </Link>
            ) : (
                <></>
            )}
            <ul
                className="list-group"
                style={{
                    margin: "15px 0px 0px 0px"
                }}
            >
                {people.history && people.history.length > 0 ? (

                    people.history.map((person, index) => {
                        if (index < 3) {
                            return (
                                <li
                                    //  key={person.id}
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                >
                                    <h1 style={styles.text7}>{person.date}</h1>
                                    <h1 style={styles.text8}>{person.actionType}</h1>
                                </li>
                            )
                        }
                    })
                ) : (
                    <li
                        className="list-group-item d-flex justify-content-between align-items-center"
                    >
                        <h1 style={styles.text2}>Sin movimientos</h1>
                    </li>
                )}
            </ul>
            <h1 style={styles.textLatoBold2}>Comentarios</h1>
            {rol === "admin" ? (
                <Link
                    style={styles.createMenu2}
                    className="btn btn-primary"
                    to={`/bags/${simpleBag}/comment`}
                >
                    Nuevo comentario
                </Link>
            ) : (
                <></>
            )}
            <ul
                className="list-group"
                style={{
                    margin: "15px 0px 0px 0px"
                }}
            >
                {people.comments && people.comments.length > 0 ? (

                    people.comments.map((person, index) => {
                        if (index < 3) {
                            return (
                                <li
                                    // key={person.id}
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                >
                                    <h1 style={styles.text7}>{person.date}</h1>
                                    <h1 style={styles.text8}>{person.comments}</h1>
                                    <button
                                        style={{
                                            display: "flex",
                                            backgroundColor: "white",
                                            textDecoration: "none",
                                            color: "inherit",
                                            border: 'none',
                                            outline: '0',
                                            padding: 0,
                                            alignItems: 'center',
                                            margin: '0px 5px 0px 0px'
                                        }}
                                        onClick={() => handleClick("comments", person.comments)}
                                    //   to={`/outlets`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                                        </svg>
                                    </button>
                                </li>
                            )
                        }
                    })
                ) : (
                    <li
                        className="list-group-item d-flex justify-content-between align-items-center"
                    >
                        <h1 style={styles.text2}>Sin comentarios</h1>
                    </li>
                )}
            </ul>
            {people.items ? (<h1 style={styles.textLatoBold2}>{`Items (${people.items.length})`}</h1>) : (<h1 style={styles.textLatoBold2}>Items</h1>)}
            {rol === "admin" ? (
                <Link
                    style={styles.createMenu2}
                    className="btn btn-primary"
                    to={`/bags/${simpleBag}/item`}
                >
                    Nuevo item
                </Link>
            ) : (
                <></>
            )}
            <ul
                className="list-group"
                style={{
                    margin: "15px 0px 100px 0px"
                }}
            >
                {people.items ? (
                    people.items.map((person, index) => {
                        return (
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <Link
                                    style={{
                                        display: "flex",
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        flex: 12,
                                        margin: '0px',
                                        padding: '0px',
                                    }}
                                    to={`/bags/${simpleBag}/edit/${index}`}
                                >
                                    <div style={styles.divPhoto}>
                                        {person.photo !== "" ? (<img
                                            src={person.photo}
                                            alt="profile picture"
                                            style={styles.photo}
                                        />) : (
                                            <img
                                                style={styles.photo}
                                                src="https://i.postimg.cc/nh8pTvhc/golfstick-pink.png"
                                                alt="..."
                                            />
                                        )}
                                    </div>
                                    <h1 style={styles.text4}>{person.name}</h1>
                                    <h1 style={styles.text2}>{person.description}</h1>
                                </Link>
                                <button
                                    style={{
                                        display: "flex",
                                        backgroundColor: "white",
                                        textDecoration: "none",
                                        color: "inherit",
                                        border: 'none',
                                        outline: '0',
                                        padding: 0,
                                        alignItems: 'center',
                                        margin: '0px 5px 0px 0px'
                                    }}
                                    onClick={() => handleClick("items", person.name, person.description)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                                    </svg>
                                </button>
                            </li>
                        );
                    })
                ) : (
                    <li
                        className="list-group-item d-flex justify-content-between align-items-center"
                    >
                        <h1 style={styles.text2}>Sin items</h1>
                    </li>
                )}
            </ul>
        </div>
    );
};
