import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Product from "../../../restaurant/components/create/Product";
import { connect } from "react-redux";
import { saveAddProductQuantity } from "../../../store/actions/hotelAction";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
const DB = firebase.db;

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.isAuth,
    userLogin: state.user.loginUser.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
    hotelTotalMenues: state.hotel.totalMenues,
    hotelTotalCategories: state.hotel.totalCategories,
    hotelTotalProducts: state.hotel.totalProducts,
    hotelTotalDisableProducts: state.hotel.totalDisableProducts,
  };
};
const mapDispatchToProps = (dispatch, state) => {
  return {
    saveAddProductQuantity: (hotel) => dispatch(saveAddProductQuantity(hotel)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

class CreateProductContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nameSpanish: "",
      nameEnglish: "",
      namePortuguese: "",
      nameFrench: "",
      description: "",
      descriptionSpanish: "",
      descriptionEnglish: "",
      descriptionPortuguese: "",
      descriptionFrench: "",
      imageProduct:
        "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fdefault.png?alt=media&token=4f76adfe-5a06-4f94-a587-3553682ca2b3",
      price: "",
      stock: true,
      nameOfBuys: 100,
      orderProduct: 1,

      nameCategory: "",
      imageCategory: "",
      nameSpanishCategory: "",
      nameEnglishCategory: "",
      namePortugueseCategory: "",
      nameFrenchCategory: "",
      products: [],

      glutenFree: false,
      vegetarian: false,
      favourite: false,

      hotel: {},
      hotelTotalMenues: 0,
      hotelTotalCategories: 0,
      hotelTotalProducts: 0,
      hotelTotalDisableProducts: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputs = this.handleInputs.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }
  menuId = this.props.match.params.id;
  categoryId = this.props.match.params.categoryId;

  componentDidMount() {
    if (this.props.hotelInfoRedux.name) {
      this.setState({
        hotel: this.props.hotelInfoRedux,
        hotelTotalMenues: this.props.hotelTotalMenues,
        hotelTotalCategories: this.props.hotelTotalCategories,
        hotelTotalProducts: this.props.hotelTotalProducts,
        hotelTotalDisableProducts: this.props.hotelTotalDisableProducts,
      });

      // if (userLS.isAuth === false) this.props.history.push("/");
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.showLoader();
    let doc = DB.collection("hoteles")
      .doc(`${this.props.userLogin}`)
      .collection("menues")
      .doc(this.menuId)
      .collection("categories")
      .doc(this.categoryId);

    doc.get().then((category) => {
      this.setState({
        nameCategory: category.data().name,
        imageCategory: category.data().imageCategory,
        nameSpanishCategory: category.data().nameSpanish,
        nameEnglishCategory: category.data().nameEnglish,
        namePortugueseCategory: category.data().namePortuguese,
        nameFrenchCategory: category.data().nameFrench,
        products: category.data().products,
      });

      let productsState = this.state.products;
      //hacer un push del producto nuevo
      productsState.push({
        name: this.state.name,
        nameSpanish: this.state.nameSpanish,
        nameEnglish: this.state.nameEnglish,
        namePortuguese: this.state.namePortuguese,
        nameFrench: this.state.nameFrench,
        description: this.state.description,
        descriptionSpanish: this.state.descriptionSpanish,
        descriptionEnglish: this.state.descriptionEnglish,
        descriptionPortuguese: this.state.descriptionPortuguese,
        descriptionFrench: this.state.descriptionFrench,
        imageProduct: this.state.imageProduct,
        price: this.state.price,
        stock: this.state.stock,
        nameOfBuys: this.state.nameOfBuys,
        orderProduct: this.state.orderProduct,
        glutenFree: this.state.glutenFree,
        vegetarian: this.state.vegetarian,
        favourite: this.state.favourite,
      });
      this.setState({ products: productsState });

      //actualizar categoria
      doc
        .update({
          name: this.state.nameCategory,
          imageCategory: this.state.imageCategory,
          nameSpanish: this.state.nameSpanishCategory,
          nameEnglish: this.state.nameEnglishCategory,
          namePortuguese: this.state.namePortugueseCategory,
          nameFrench: this.state.nameFrenchCategory,
          products: this.state.products,
        })
        .then(() => {
          this.props.hideLoader();

          this.props.history.push(`/menu/${this.menuId}/${this.categoryId}`);
          firebase.succesfullMsg("Product successfully added!");

          //1. setear en la DB una cantidad mas de menu.
          let hotelInfo = DB.collection("hoteles").doc(
            `${this.props.userLogin}`
          );
          //let hotelInfo = DB.collection("hoteles").doc("wbKs9LDjm0SpFBZnqvst");
          let sumaProducts = this.state.hotelTotalProducts + 1;
          hotelInfo.update({ totalProducts: sumaProducts });
          //2. agregar en Redux una cantidad mas de menu.
          this.props.saveAddProductQuantity(sumaProducts);
        });
    });

    // let doc = DB.collection("hoteles")
    //   .doc(`${this.props.userLogin}`)
    //   .collection("menues")
    //   .doc(this.menuId)
    //   .collection("categories")
    //   .doc(this.categoryId)
    //   .collection("products")
    //   .doc();
    // doc.set({
    //   name: this.state.name,
    //   nameSpanish: this.state.nameSpanish,
    //   nameEnglish: this.state.nameEnglish,
    //   namePortuguese: this.state.namePortuguese,
    //   nameFrench: this.state.nameFrench,
    //   description: this.state.description,
    //   descriptionSpanish: this.state.descriptionSpanish,
    //   descriptionEnglish: this.state.descriptionEnglish,
    //   descriptionPortuguese: this.state.descriptionPortuguese,
    //   descriptionFrench: this.state.descriptionFrench,
    //   imageProduct: this.state.imageProduct,
    //   price: this.state.price,
    //   stock: this.state.stock,
    //   nameOfBuys: this.state.nameOfBuys,
    // });
  }

  handleInputs(e) {
    let key = e.target.name;
    let input = e.target.value;

    if (
      (key === "name" && input.length < 30) ||
      (key === "nameSpanish" && input.length < 30) ||
      (key === "nameEnglish" && input.length < 30) ||
      (key === "namePortuguese" && input.length < 30) ||
      (key === "nameFrench" && input.length < 30) ||
      // (key === "description" && input.length < 125) ||
      // (key === "descriptionSpanish" && input.length < 125) ||
      // (key === "descriptionEnglish" && input.length < 125) ||
      // (key === "descriptionPortuguese" && input.length < 125) ||
      // (key === "descriptionFrench" && input.length < 125)

      (key === "description" && input.length < 145) ||
      (key === "descriptionSpanish" && input.length < 145) ||
      (key === "descriptionEnglish" && input.length < 145) ||
      (key === "descriptionPortuguese" && input.length < 145) ||
      (key === "descriptionFrench" && input.length < 145)
    ) {
      console.log(input);
      this.setState({
        [key]: input,
      });
    }

    if (key === "imageProduct" || key === "price" || key === "orderProduct") {
      this.setState({
        [key]: input,
      });
    }
  }

  handleOptionChange(e) {
    if (e.target.name === "glutenFree") {
      this.setState({
        glutenFree: !this.state.glutenFree,
      });
    }

    if (e.target.name === "vegetarian") {
      this.setState({
        vegetarian: !this.state.vegetarian,
      });
    }
    if (e.target.name === "favourite") {
      this.setState({
        favourite: !this.state.favourite,
      });
    }
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Product
          submit={this.handleSubmit}
          inputs={this.handleInputs}
          valueState={this.state}
          handleOptionChange={this.handleOptionChange}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProductContainer);
