import React from "react";
import HomeGolf from "../../components/views/HomeGolf";
import Axios from "axios";
import firebase from "../../../services/firebase";
import { connect } from "react-redux";

const request = require('postman-request');
const DB = firebase.db;

const mapStateToProps = (state) => {
    return {
        userLogin: state.user.loginUser,
        isAuth: state.user.isAuth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // loggeado: (firstName, lastName, rol, liderOf) => dispatch(loginUserClient(firstName, lastName, rol, liderOf)),
    };
};

class HomeGolfCliente extends React.Component {
    constructor(props) {
        super();
        this.state = {
            date: "",
            hour: "",
            loader: true,
            oneAction: false,
            user: {},
            news: []
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        let newsDoc = DB.collection("countrys")
            .doc("lVGwoAyzUnN1ygmvEE3T")
            .collection("news")
            .doc("YZE3zRM6EVhFJGpc3Fil")

        newsDoc.get()
            .then((news) => {
                this.setState({
                    news: news.data().news,
                    loader: false,
                });
            })

        // const day = new Date().getDay()
        // const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday",]
        // let dateGroup = days[day]
        // let dateNowString = dateGroup.toString();
        // const hours = new Date().getHours()
        // let hoursNowString = hours.toString();

        // this.setState({
        //     date: dateNowString,
        //     hour: hoursNowString,
        //     loader: false,
        // })
    }


    componentDidUpdate() {
        // let newsDoc = DB.collection("countrys")
        //     .doc("lVGwoAyzUnN1ygmvEE3T")
        //     .collection("news")
        //     .doc("YZE3zRM6EVhFJGpc3Fil")

        // newsDoc.get()
        //     .then((news) => {
        //         this.setState({
        //             news: news.data().news,
        //             loader: false,
        //         });
        //     })


        // if (this.state.oneAction === false) {
        //     if (this.props.userLogin.firstNameRedux) {
        //         this.setState({ user: this.props.userLogin, oneAction: true })


        //     }
        // }
    }

    handleClick(e) {


    }

    render() {
        return (
            <div>
                <HomeGolf
                    news={this.state.news}
                    loader={this.state.loader}
                    screenSize={window.screen.width}
                    handleClick={this.handleClick}
                    date={this.state.date}
                    hour={this.state.hour}
                    user={this.state.user}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeGolfCliente);

//export default HomeCliente;
