import React, { Component } from "react";
import GIF from "../../../assets/images/loader.gif";
//import styles from "../../../assets/css/Styles.module.css";
import { connect } from "react-redux";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const styles = {
  containerFlex: {
    marginLeft: "250px",
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
};

class FullPageLoader extends Component {
  state = {};
  render() {
    const { loading } = this.props;
    if (!loading) return null;
    return (
      <div style={styles.containerFlex}>
        <Loader
          type="TailSpin"
          color="#ff2068"
          height={100}
          width={100}
          timeout={10000} //3 secs
        />
      </div>

      // <div className={styles.loaderContainer}>
      //   <div className={styles.loader}>
      //     <img src={GIF} alt="loading Gif" />
      //   </div>
      // </div>
    );
  }
}
const mapStateToProps = (state) => ({ loading: state.user.loader });
export default connect(mapStateToProps)(FullPageLoader);
