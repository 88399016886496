import React from "react";
import { Link } from "react-router-dom";

export default ({ handlerSubmit, handlerChange }) => {
  return (
    <div>
      <div
        className="row align-items-center"
        style={{
          backgroundColor: "#ffffff",
          margin: "0px",
        }}
      >
        <div
          className="col"
          style={{
            width: "100%",
            margin: 0,
            padding: 0,
            height: "100vh",
            backgroundImage: `url(${"https://i.postimg.cc/YSwMT0xd/background-mygolf.jpg"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <img
            src="https://i.postimg.cc/9M91vgg5/Artboard-1.png"
            alt="..."
            //  height="22"
            style={{
              height: "10%",
              width: "auto",
              position: "relative",
              left: "10%",
              top: "80%",
            }}
          />
        </div>
        <div
          className="col"
          style={{
            padding: "0px",
            margin: "0px",
          }}
        >
          <form
            onSubmit={(e) => handlerSubmit(e)}
            style={{
              padding: "40px",
              paddingRight: "40px",
              // marginRight: "20px",
            }}
          >
            <div className="form-group">
              <h1
                className="font-weight-bold"
                style={{
                  paddingBottom: "20px",
                }}
              >
                Inicio de Sesión
              </h1>
              <label>Corre electrónico</label>
              <input
                name="email"
                type="email"
                className="form-control"
                id="inputEmail"
                aria-describedby="emailHelp"
                onChange={handlerChange}
              />
              <small id="emailHelp" className="form-text text-muted">
                No compartiremos su información.
              </small>
            </div>
            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Contraseña</label>
              <input
                name="password"
                type="password"
                className="form-control"
                id="inputPassword"
                onChange={handlerChange}
              />
            </div>
            <button
              onClick={(e) => handlerSubmit(e)}
              type="submit"
              className="btn btn-primary"
              style={{
                backgroundColor: "#FF2068",
                borderColor: "#FF2068",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              Iniciar
            </button>

            {/* <Link
              style={{
                marginLeft: "20px",
              }}
              to="/recover"
            >
              Recuperar contraseña
            </Link> */}
          </form>
        </div>
      </div>
      <div></div>
    </div>
  );
};
