import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Menues from "../../../restaurant/components/views/Menues";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { getMenu, deleteMenu } from "../../../store/actions/menuActions";
import { saveDownMenuQuantity } from "../../../store/actions/hotelAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const DB = firebase.db;

// let local = JSON.parse(window.localStorage.getItem("persist:lovableLogin"));
// let userLS;
// if (local) {
//   userLS = JSON.parse(local.user);
// }
const mapStateToProps = (state) => {
  return {
    hotelId: state.user.loginUser.hotelId,
    rol: state.user.loginUser.rol,
    menuArray: state.menuArray.menuArray,
    isAuth: state.user.isAuth,
    userLogin: state.user.loginUser.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
    hotelTotalMenues: state.hotel.totalMenues,
    hotelTotalCategories: state.hotel.totalCategories,
    hotelTotalProducts: state.hotel.totalProducts,
    hotelTotalDisableProducts: state.hotel.totalDisableProducts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    buscandoMenu: (hotelId) => dispatch(getMenu(hotelId)),
    saveDownMenuQuantity: (hotel) => dispatch(saveDownMenuQuantity(hotel)),
    eliminar: (hotelId, id, history) =>
      dispatch(deleteMenu(hotelId, id, history)),
  };
};

class MenuContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: {},
      hotelTotalMenues: 0,
      hotelTotalCategories: 0,
      hotelTotalProducts: 0,
      hotelTotalDisableProducts: 0,
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    //this.props.buscandoMenu(userLS.loginUser.hotelId);
    //   if (userLS.isAuth) {
    if (this.props.hotelId) {
      this.props.buscandoMenu(this.props.hotelId);
    }
    if (this.props.hotelInfoRedux.name) {
      this.setState({
        hotelTotalMenues: this.props.hotelTotalMenues,
        hotelTotalCategories: this.props.hotelTotalCategories,
        hotelTotalProducts: this.props.hotelTotalProducts,
        hotelTotalDisableProducts: this.props.hotelTotalDisableProducts,
      });
    }
    //  }
    // else {
    //   this.props.history.push("/");
    // }
  }

  handleDelete(e, id) {
    e.preventDefault();

    // this.props.eliminar(this.props.hotelId, id, this.props.history);

    let doc = DB.collection("hoteles")
      .doc(`${this.props.userLogin}`)
      .collection("menues")
      .doc(id);
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.value) {
        let hotelInfo = DB.collection("hoteles").doc(`${this.props.userLogin}`);
        //let hotelInfo = DB.collection("hoteles").doc("wbKs9LDjm0SpFBZnqvst");
        let restaMenues = this.state.hotelTotalMenues - 1;
        hotelInfo.update({ totalMenues: restaMenues });
        //2. agregar en Redux una cantidad mas de menu.
        this.props.saveDownMenuQuantity(restaMenues);
        MySwal.fire("Deleted!", `Menu has been deleted.`, "success");
        doc.delete();
        this.props.history.push(`/dashboard`);
      }
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Menues
          menuObject={this.props.menuArray}
          deleteFunc={this.handleDelete}
          rol={this.props.rol}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
