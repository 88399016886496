import React from "react";
import GolfBalls from "../../components/views/GolfBalls";
import firebase from "../../../services/firebase";
import Swal from "sweetalert2";
import Axios from "axios";

const DB = firebase.db;

class GolfBallsCliente extends React.Component {
    constructor(props) {
        super();
        this.state = {
            claims: [],
            loader: true,
        };
        this.handleClick = this.handleClick.bind(this);

    }

    componentDidMount() {
        window.scrollTo(0, 0);

        let claimsDoc = DB.collection("countrys")
            .doc("lVGwoAyzUnN1ygmvEE3T")
            .collection("claims")

        claimsDoc.get()
            .then((claimsTotal) => {

                let claimsArray = [];
                claimsTotal.forEach((claim) => {
                    claimsArray.push(claim.data());
                });
                this.setState({
                    claims: claimsArray,
                    loader: false,
                });
            })

    }

    handleClick(type) {
        if (type === "add") {

            Swal.fire({
                title: `¿Retirar ahora?`,
                text: `Retirar un canasto de 25 pelotas de golf.`,
                grow: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: `Retirar`,
                cancelButtonText: `Cancelar`,
                confirmButtonColor: `#008477`,
                reverseButtons: true,
                showCloseButton: true,
                //iconColor: "#ff2068",
                inputAttributes: {
                    autocapitalize: 'off'
                },
            }).then((result) => {
                if (result.value) {


                    // fetch('http://192.168.0.171:8081/zeroconf/info', {
                    //     method: 'POST',
                    //     headers: {
                    //         Accept: 'application/json',
                    //         'Content-Type': 'application/json',
                    //     },
                    //     body: JSON.stringify({
                    //         deviceid: '10011937b4',
                    //         data: '{}',
                    //     }),
                    // });




                    // Axios({
                    //     // headers: { "Access-Control-Allow-Origin": "*" },
                    //     headers: {
                    //         //     'Access-Control-Allow-Origin': '*',
                    //         'Content-Type': 'application/json',
                    //     },

                    //     method: "post",
                    //     data: dataToSent,
                    //     url: "http://192.168.0.171:8081/zeroconf/switch"
                    // })
                    //     .then(res => console.log(res))
                    //     .catch(err => console.error(err))



                    // var myHeaders = new Headers();
                    // myHeaders.append("Content-Type", "application/json");

                    // var raw = JSON.stringify({ "deviceid": "10011937b4", "data": { "switch": "on" } });

                    // var requestOptions = {
                    //     method: 'POST',
                    //     headers: myHeaders,
                    //     body: raw,
                    //     redirect: 'follow'
                    // };

                    // fetch("http://192.168.0.171:8081/zeroconf/switch", requestOptions)
                    //     .then(response => response.text())
                    //     .then(result => console.log(result))
                    //     .catch(error => console.log('error', error));


                    // let config = {
                    //     headers: {
                    //         //   'Access-Control-Allow-Origin': '*',
                    //         // 'Content-Length': 35,
                    //         'Content-Type': 'application/json'
                    //     },
                    //     responseType: 'text'
                    // };


                    // let dataToSent = { "deviceid": "10011937b4", "data": { "switch": "on" } }


                    // Axios.post('http://192.168.0.160:8081/zeroconf/info', dataToSent, config)
                    //     .then(function (response) {
                    //         console.log('saved successfully')
                    //     })
                    //     .catch(function (error) {
                    //         console.log(error);
                    //     });


                    // var myHeaders = new Headers();
                    // myHeaders.append("Content-Type", "application/json");

                    // var raw = JSON.stringify({ "deviceid": "10011937b4", "data": { "switch": "on" } });

                    // var requestOptions = {
                    //     method: 'POST',
                    //     headers: myHeaders,
                    //     body: raw,
                    //     redirect: 'follow'
                    // };

                    // fetch("http://192.168.0.160:8081/zeroconf/switch", requestOptions)
                    //     .then(response => response.text())
                    //     .then(result => console.log(result))
                    //     .catch(error => console.log('error', error));





                    //                         fetch('http://192.168.0.160:8081/zeroconf/switch', {
                    //   method: 'POST',
                    //   headers: {
                    //     Accept: 'application/json',
                    //     'Content-Type': 'application/json'
                    //   },
                    //   body: JSON.stringify({
                    //     firstParam: 'yourValue',
                    //     secondParam: 'yourOtherValue'
                    //   })
                    // });
                    // Axios.post("https://cors-anywhere.herokuapp.com/http://192.168.0.171:8081/zeroconf/switch", { dataToSent })
                    //     .then(res => {
                    //         console.log(res);
                    //         console.log(res.data);
                    //     })



                    // Axios.post(
                    //     `http://192.168.0.171:8081/zeroconf/switch`,
                    //     {
                    //         deviceid: '10011937b4',
                    //         data: { switch: 'on' }
                    //     }
                    // )
                    //     .then((res) => {
                    //         console.log(res.data);
                    //         console.log(res)
                    //     })







                }
            })

        }
    }

    render() {
        return (
            <div>
                <GolfBalls
                    claims={this.state.claims}
                    loader={this.state.loader}
                    screenSize={window.screen.width}
                    handleClick={this.handleClick}
                />
            </div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(PropiedadesCliente);

export default GolfBallsCliente;
