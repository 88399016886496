import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//const Background =
//  "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Falmuerzo-cover.png?alt=media&token=720f0451-55d2-481b-a401-282fd2d34fcf";

const styles = {
  container: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor:'#339AE7',
   // flex:"12",
  },
  containerFlex: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },


  upScreen: {
    display: "flex",
    flex: 10,
    flexDirection: "column",
    backgroundColor: "white",
    overflow: "auto",
    borderRadius: "0 0 25px 0",
  },


  head: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    paddingLeft: "30px",
    backgroundColor: "white",
    borderBottom: "3px solid white",
    
    // margin: "30px",
  },

  information: {
      display: "flex",
    flex: 10,
    height:"400px",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column",
   // backgroundColor: "blue",
  },

  headWeb: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    padding: "50px",
    paddingBottom: "0px",
    backgroundColor: "white",
    borderBottom: "3px solid white",
  },
  title: {
    margin: 0,
    marginTop: 30,
    fontSize: 30,
   // fontWeight: "bold",
    color: "#000",
   // fontSize: 65,
   // fontFamily: "SimpleJoys",
   fontFamily: "LatoBlack",
  },

  infoLarge: {
    margin: 20,
    fontSize: 25,
   // fontWeight: "bold",
    color: "#000",
   // fontSize: 65,
   // fontFamily: "SimpleJoys",
   fontFamily: "LatoBlack",
  },

  infoMedium: {
    margin: 15,
    fontSize: 18,
    fontWeight: 400,
    color: "grey",
    //fontSize: 65,
   // fontFamily: "LoewMedium",
    fontFamily: "Lato",
  },


  titleWeb: {
    margin: 0,
    fontSize: 40,
   // fontWeight: "bold",
    color: "#000",
    fontFamily: "LatoBlack",
    //fontSize: 65,
    //fontFamily: "SimpleJoys",
  },
  subtitle: {
    margin: 0,
    marginRight: 20,
    marginTop: "5px",
    marginBottom: "20px",
    fontSize: 14,
    fontWeight: 400,
    color: "grey",
    //fontSize: 65,
   // fontFamily: "LoewMedium",
    fontFamily: "Lato",
  },


  subtitleWeb: {
    margin: 0,
    marginTop: "10px",
    marginBottom: "10px",
    fontSize: 18,
    //fontWeight: 400,
    color: "grey",
    //fontSize: 65,
    //fontFamily: "LoewMedium",
    fontFamily: "Lato",
  },
  propertiesContainer: {
    flex: 10,
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "white",
    overflow: "auto",
  },

  propertiesContainerWeb: {
    flex: 10,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "white",
    overflow: "auto",
    padding: "20px",
  },

  propertie: {
    display: "flex",
    flex: 8,
    flexFlow: "column",
    margin: 0,
    width: "100%",
    borderRadius: "25px",
  },

  propertieWeb: {
    display: "flex",
    flex: 12,
    flexFlow: "column",
    margin: 0,
    width: "100%",
    borderRadius: "25px",
  },

  cover: {
    display: "flex",
    flex: 4,
    margin: 0,
  // maxHeight: "140px",
   height: "140px",
  },

  coverWeb: {
    display: "flex",
    flex: 4,
    margin: 0,
     maxHeight: "300px",
  },

  imageCover: {
    width: "100%",
    borderRadius: "15px",
    objectFit: "cover",
  },

  imageCoverWeb: {
    width: "100%",
   // height:"auto",
    borderRadius: "20px",
    objectFit: "cover",
  },

  imagePointersWeb: { margin: 0, height: "25px" },

  imagePointers: { margin: 0, height: "15px" },

  info: {
    display: "flex",
    flex: 5,
    margin: 0,
    width: "100%",
    backgroundColor: "white",
    borderRadius: "25px",
    padding: "5px",
  },

  left: {
    display: "flex",
    flex: 8,
    flexFlow: "column",
    margin: 0,
  },

  right: {
    display: "flex",
    flex: 4,
    flexFlow: "column",
    margin: 0,
    padding: "5px",
    justifyContent: "center",
    alignItems: "flex-end",
  },

  rightWeb: {
    display: "flex",
    flex: 4,
    flexFlow: "column",
    margin: 0,
    padding: "5px",
    justifyContent: "center",
    alignItems: "flex-end",
  },



  numbers: {
    flex: 12,
    display: "flex",
    margin: 0,
    fontSize: 11,
   // fontWeight: "bold",
    color: "#000",
    padding: "5px",
    paddingTop: "15px",
     //fontFamily: "LoewMedium",
     fontFamily: "LatoBlack",
  },

  leftNumbersWeb: {
    flex: 12,
    display: "flex",
    alignItems: "center",
    margin: 0,
    padding: "15px 0 15px 5px",
  },

  leftNumbers: {
    flex: 12,
    display: "flex",
    alignItems: "center",
    margin: 0,
    padding: "5px 0 5px 5px",
  },

  numbersWeb: {
    margin: 0,
    fontSize: 18,
   // fontWeight: "bold",
    color: "#000",
    padding: "0 10px",
    marginRight: "20px",
    //fontFamily: "LoewMedium",
    fontFamily: "Lato",
  },

  numbersCell: {
    margin: 0,
    fontSize: 16,
    //fontWeight: "bold",
    color: "#000",
    padding: "0 10px",
    marginRight: "5px",
     //fontFamily: "LoewMedium",
     fontFamily: "Lato",
  },

  news: {
    flex: 12,
    display: "flex",
    margin: 0,
    fontSize: 20,
   // color: "grey",
    padding: "5px",
    paddingTop: "10px",
    paddingBottom: "15px",
    // fontFamily: "LoewMedium",
    fontFamily: "LatoBold",
  },

  directionWeb: {
    flex: 12,
    display: "flex",
    margin: 0,
    fontSize: 16,
    color: "grey",
    padding: "5px",
    paddingTop: "5px",
    paddingBottom: "15px",
   // fontFamily: "LoewMedium",
    fontFamily: "Lato",
  },

  price: {
    margin: 0,
    fontSize: 15,
    //fontWeight: "bold",
    color: "#000",
       // fontFamily: "LoewMedium",
       fontFamily: "LatoBlack",

  },

  priceWeb: {
    margin: 0,
    fontSize: 22,
   // fontWeight: "bold",
    color: "#000",
    // fontFamily: "LoewMedium",
    fontFamily: "LatoBold",
  },

  buttonFloat: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "50px",
  },

  downScreen: {
    display: "flex",
    flex:1.5,
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor:'rgba(180,180,180)',
    backgroundColor:'white',
    width:"100%",
  },

  buttonsBox: {
    display: "flex",
    flex:12,
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor:'#339AE7',
   // backgroundColor:'#000',
    height:"100%",
    width:"100%",
    borderRadius: "25px 0 0 0",
  },


  textBotones:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 18,
     color: "#000",
     fontFamily: "LatoBold",
     margin:'0'
  },


  imageFinal: { margin: 0, height: "35px" },
  textFinal: {
    margin: 0,
    fontSize: 14,
    color: "#fff",
    paddingTop: "15px",
  },
  link: {
    //padding: "20px",
  },
  icons: {
    color:'#fff'
  },

  video: {
    display: "flex",
   // margin: "30px 0 10px 0",
    height: "200px",
    width: "100%",
  },

  videoIframe: {
    borderRadius: "8px",},


    buttons: {
        flex: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        //margin: "0 30px 0 30px",
        backgroundColor: "white",
        paddingTop: "10px",
        height:'100%'
      },

      button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    
        padding: "45px",
        paddingTop: "5%",
        paddingBottom: "5%",
        // paddingTop: "22px",
        // paddingBottom: "22px",
    
        width: "44.5vw",
        height:'160px',
        
        backgroundColor: "#339AE7",
        // backgroundColor: "white",
        borderRadius: "100%",
        margin: "5px",
        marginTop: "0px",
        marginBottom: "10px",
        border:"none",
        // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
      },
      textButton: {
        margin: 0,
       //marginRight: 20,
        //marginTop: "5px",
        //marginBottom: "20px",
        fontSize: 20,
       // fontWeight: 400,
        color: "white",
        //fontSize: 65,
       // fontFamily: "LoewMedium",
        fontFamily: "Lato",
      },

      mercadopago: {
        flex: 12,
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        margin: 0,
        fontSize: 18,
        color: "#fff",
        padding: "15px 30px",
        // fontFamily: "LoewMedium",
        fontFamily: "LatoBold",
      },
};

export default ({ messages, loader, screenSize }) => {
  return (
    <>
      {loader == false ? (
        <>
          {screenSize > 800 ? (
            <>
             
            </>
          ) : (
            
            <div style={styles.container}>
                    <div style={styles.upScreen}>
                      <div>
              <div style={styles.head}>
                <h1 style={styles.title}>Ofrendar</h1>
                <h6 style={styles.subtitle}>
                Podés hacerlo de las siguientes maneras.
                </h6>
              </div>


              <div style={styles.information}>
                <h1 style={styles.infoLarge}>Transferencia</h1>
                <h6 style={styles.infoMedium}>
                ALIAS: iglesia.lpa
                </h6>
                <hr />
                <h1 style={styles.infoLarge}>MercadoPago</h1>
                
                
                <Link
                         // key={singleNew.id}
                          style={{
                            display: "flex",
                            //height: "231px",
                           // width: "90%",
                            justifyContent: 'center',
                            alignItems: "center",
                            borderRadius: "25px",
                            margin: "20px",
                            backgroundColor: "#339AE7",
                            textDecoration: "none",
                            color: "inherit",
                          }}
                          // to={`/news/${singleNew.id}/`}
                        //   onClick={() => handleClick("youtube")}
                        >
                          <h4 style={styles.mercadopago}>
                                  Ofrendar por MercadoPago
                                </h4>
                        </Link>

                
              </div>
              
     
              </div>
              </div>
                    <div style={styles.downScreen}>

                    <div style={styles.buttonsBox}>
                    {/* <h4 style={styles.textBotones}>
                                Botones
                                </h4> */}


                                <Link
              className="nav-link active"
              style={styles.link}
              to="/news"
            >
             <svg xmlns="http://www.w3.org/2000/svg"  style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
</svg>
            </Link>

            <Link
              className="nav-link active"
              style={styles.link}
              to="/messages"
            >
             <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
</svg>
            </Link>
            <Link
              className="nav-link active"
              style={styles.link}
              to="/actions"
            >
          
<svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
  <path d="M13.498.795l.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z"/>
</svg>

            </Link>
            <Link
              className="nav-link active"
              style={styles.link}
              to="/ministries"
            >
             <svg xmlns="http://www.w3.org/2000/svg" style={styles.icons} width="18" height="20" fill="currentColor" class="bi bi-grid" viewBox="0 0 16 16">
  <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
</svg>
            </Link>

            <Link
              className="nav-link active"
              style={styles.link}
              to="/profile"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20"   style={styles.icons} fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
</svg>
            </Link>
            </div>
                    </div>
              </div>
          )}
        </>
      ) : (
        <div style={styles.containerFlex}>
          <Loader
            type="TailSpin"
            //color="#ff2068"
             color="#339AE7"
            height={100}
            width={100}
            timeout={10000} //3 secs
          />
        </div>
      )}
    </>
  );
};
