import React from "react";
import Restaurants from "../../components/views/Restaurants";
import firebase from "../../../services/firebase";

const DB = firebase.db;

class RestaurantsCliente extends React.Component {
    constructor(props) {
        super();
        this.state = {
            restaurants: [],
            loader: true,
        };
        this.handleClick = this.handleClick.bind(this);

    }

    componentDidMount() {
        window.scrollTo(0, 0);

        let restaurantsDoc = DB.collection("countrys")
            .doc("lVGwoAyzUnN1ygmvEE3T")
            .collection("restaurants")
            .doc("yNoH6ab7UzfmG0nAOCdj")

        restaurantsDoc.get()
            .then((restaurant) => {
                this.setState({
                    restaurants: restaurant.data().restaurants,
                    loader: false,
                });
            })

    }

    handleClick(type) {
        if (type === "add") {
            //agregar accion de proveedores.
        }
    }

    render() {
        return (
            <div>
                <Restaurants
                    restaurants={this.state.restaurants}
                    loader={this.state.loader}
                    screenSize={window.screen.width}
                    handleClick={this.handleClick}
                />
            </div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(PropiedadesCliente);

export default RestaurantsCliente;
