import React from "react";
import { Link } from "react-router-dom";

let restaurantURL;

const styles = {
  container: {
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "20px",
  },
  chatContainer: {
    // backgroundColor: "green",
    height: "80vh",
    borderRadius: "10px",
    display: "flex",
    // border: "solid 1px black",
    boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },
  chatSessions: {
    flex: 4,
    display: "flex",
    backgroundColor: "white",
    borderRadius: "10px 0 0 10px",
    flexDirection: "column",
    borderRight: "solid 1px rgba(90,90,90,0.1)",
    // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },

  chatSession: {
    //height: "100px",
    margin: "10px",
    marginBottom: "0px",
    display: "flex",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
    flexDirection: "column",
    border: "none",
  },

  chatSessionName: {
    margin: 0,
    marginTop: "20px",
    marginLeft: "20px",
    display: "flex",
    flex: "1",
    //  backgroundColor: "green",
    fontSize: 8,
    fontWeight: "bold",
    // textAlign: "center",
    color: "black",
  },

  chatSessionText: {
    margin: 0,
    marginTop: "10px",
    marginLeft: "20px",
    marginRight: "20px",
    display: "flex",
    flex: "2",
    //backgroundColor: "blue",
    fontSize: 14,
    fontWeight: "400",
    // textAlign: "center",
    color: "black",
  },

  chatSessionHour: {
    margin: 10,
    marginLeft: "20px",
    marginBottom: "20px",
    display: "flex",
    flex: "1",
    // backgroundColor: "yellow",
    fontSize: 12,
    fontWeight: 400,
    // textAlign: "center",
    color: "grey",
  },

  chatReceived: {
    // height: "100px",
    padding: "20px",
    maxWidth: "60%",
    margin: "0px",
    marginLeft: "0px",
    marginTop: "10px",
    display: "flex",
    alignSelf: "flex-start",
    backgroundColor: "white",
    borderRadius: "0 20px 20px 0",
    boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },

  chatSent: {
    // height: "100px",
    padding: "20px",
    maxWidth: "60%",
    margin: "00px",
    marginRight: "0px",
    marginTop: "10px",
    display: "flex",
    alignSelf: "flex-end",
    backgroundColor: "#ff2068",
    color: "white",
    borderRadius: "20px 0 0 20px",
    boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },

  chatMessages: {
    flex: 8,
    display: "flex",
    //backgroundColor: "#fff",
    borderRadius: "0 10px 10px 0",
    flexDirection: "column",
  },
  chatMessagesAll: {
    paddingBottom: "10px",
    flex: 11,
    display: "flex",
    // backgroundColor: "#fff",
    borderRadius: "0 10px 10px 0",
    flexDirection: "column",
    overflow: "auto",
  },
  text: {
    flex: 1,
    display: "flex",
    borderRadius: "0 10px 10px 0",
    backgroundColor: "white",
    flexDirection: "row",
    borderTop: "solid 0.2px rgb(240,240,240)",
    //justifyContent: "flex-start",
    alignItems: "center",
    //  height: "100%",
  },

  input: {
    flex: 10,
    display: "flex",
    //backgroundColor: "red",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    border: " 0px ",
  },
  textChat: {
    margin: "15px",
    fontSize: "16px",
    color: "black",
  },
  button: {
    flex: 2,
    display: "flex",
    borderRadius: "10px",
    backgroundColor: "#ff2068",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    border: " 0px ",
  },
  textButton: {
    fontSize: "16px",
    color: "black",
  },
};

export default ({ rooms, handlerSubmit }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        marginLeft: "250px",
        marginBottom: "30px",
      }}
    >
      <div className="container" style={styles.container}>
        <h1 className="font-weight-bold">Chats</h1>

        <hr />

        <div style={styles.chatContainer}>
          <div style={styles.chatSessions}>
            {rooms.length ? (
              rooms.map((room) => {
                if (room.chats.length) {
                  return (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={styles.chatSession}
                      onSubmit={(e) => handlerSubmit(e)}
                    >
                      <h6 style={styles.chatSessionName}>
                        {room.chats[room.chats.length - 1].owner}
                      </h6>

                      {room.chats[room.chats.length - 1].message.length > 40 ? (
                        <h6 style={styles.chatSessionText}>
                          {room.chats[room.chats.length - 1].message.slice(
                            0,
                            39
                          ) + "..."}
                        </h6>
                      ) : (
                        <h6 style={styles.chatSessionText}>
                          {room.chats[room.chats.length - 1].message}
                        </h6>
                      )}

                      <h6 style={styles.chatSessionHour}>10pm</h6>
                    </button>
                  );
                }
              })
            ) : (
              <></>
            )}
          </div>

          <div style={styles.chatMessages}>
            <div style={styles.chatMessagesAll}>
              {rooms.length ? (
                rooms.map((message) => {
                  if (message.chats[0].owner === "hotel") {
                    return (
                      <div style={styles.chatSent}>
                        {message.chats[0].message}
                      </div>
                    );
                  } else {
                  }

                  return (
                    <div style={styles.chatReceived}>
                      {message.chats[0].message}
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
            <div style={styles.text}>
              <input
                style={styles.input}
                name="numberOfRoom"
                type="text"
                className="form-control"
                id="numberOfRoom"
                placeholder="Escribinos..."
                //onChange={handlerChange}
              />

              <button
                type="submit"
                className="btn btn-primary"
                style={styles.button}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
