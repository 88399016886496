import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import { connect } from "react-redux";
import Category from "../../../restaurant/components/create/Category";
import { saveAddCategoryQuantity } from "../../../store/actions/hotelAction";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
const DB = firebase.db;

const mapStateToProps = (state) => {
  return {
    userLogin: state.user.loginUser.hotelId,
    isAuth: state.user.isAuth,
    hotelInfoRedux: state.hotel.hotelInfo,
    hotelTotalMenues: state.hotel.totalMenues,
    hotelTotalCategories: state.hotel.totalCategories,
    hotelTotalProducts: state.hotel.totalProducts,
    hotelTotalDisableProducts: state.hotel.totalDisableProducts,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveAddCategoryQuantity: (hotel) =>
      dispatch(saveAddCategoryQuantity(hotel)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

class CreateMenuContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      imageCategory: "",
      nameSpanish: "",
      nameEnglish: "",
      namePortuguese: "",
      nameFrench: "",
      selectedOption: "option1",
      withImages: true,
      orderCategory: 1,
      hotel: {},
      hotelTotalMenues: 0,
      hotelTotalCategories: 0,
      hotelTotalProducts: 0,
      hotelTotalDisableProducts: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputs = this.handleInputs.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }
  menuId = this.props.match.params.id;

  componentDidMount() {
    if (this.props.hotelInfoRedux.name) {
      this.setState({
        hotel: this.props.hotelInfoRedux,
        hotelTotalMenues: this.props.hotelTotalMenues,
        hotelTotalCategories: this.props.hotelTotalCategories,
        hotelTotalProducts: this.props.hotelTotalProducts,
        hotelTotalDisableProducts: this.props.hotelTotalDisableProducts,
      });
    }
    // if (userLS.isAuth === false) this.props.history.push("/");
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.showLoader();
    let doc = DB.collection("hoteles")
      .doc(`${this.props.userLogin}`)
      .collection("menues")
      .doc(this.menuId)
      .collection("categories")
      .doc();
    doc
      .set({
        name: this.state.name,
        imageCategory: this.state.imageCategory,
        nameSpanish: this.state.nameSpanish,
        nameEnglish: this.state.nameEnglish,
        namePortuguese: this.state.namePortuguese,
        nameFrench: this.state.nameFrench,
        products: [],
        withImages: this.state.withImages,
        orderCategory: this.state.orderCategory,
      })
      .then((doc) => {
        this.props.hideLoader();

        this.props.history.push(`/menu/${this.menuId}`);
        firebase.succesfullMsg("Category succesfully created!");
      });

    //1. setear en la DB una cantidad mas de menu.
    let hotelInfo = DB.collection("hoteles").doc(`${this.props.userLogin}`);
    //let hotelInfo = DB.collection("hoteles").doc("wbKs9LDjm0SpFBZnqvst");
    let sumaCategories = this.state.hotelTotalCategories + 1;
    hotelInfo.update({ totalCategories: sumaCategories });
    //2. agregar en Redux una cantidad mas de menu.
    this.props.saveAddCategoryQuantity(sumaCategories);
  }
  handleInputs(e) {
    let key = e.target.name;
    let input = e.target.value;
    this.setState({
      [key]: input,
    });
  }

  handleOptionChange(e) {
    this.setState({
      selectedOption: e.target.value,
      withImages: !this.state.withImages,
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Category
          submit={this.handleSubmit}
          inputs={this.handleInputs}
          handleOptionChange={this.handleOptionChange}
          optionSelected={this.state.selectedOption}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMenuContainer);
