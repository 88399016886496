import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import ItemsBags from "../../components/edit/ItemsBags";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
const DB = firebase.db;

const mapStateToProps = (state) => {
    return {
        isAuth: state.user.isAuth,
        userLogin: state.user.loginUser.hotelId,
        hotelInfoRedux: state.hotel.hotelInfo,
        hotelId: state.user.loginUser.hotelId,
    };
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
    };
};

class CreateMoveCommentItemContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            name: "",
            description: "",
            photo: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.showLoader();
        if (this.props.hotelId) {
            let simpleBagDoc = DB.collection("countrys")
                .doc(`${this.props.hotelId}`)
                .collection("golfbags").doc(this.props.match.params.idBag)

            simpleBagDoc.get().then((singleBag) => {
                this.setState({
                    name: singleBag.data().items[this.props.match.params.idItem].name,
                    description: singleBag.data().items[this.props.match.params.idItem].description,
                    photo: singleBag.data().items[this.props.match.params.idItem].photo,
                }, () => this.props.hideLoader());
            })
        }
    }

    handleInput(e) {
        let key = e.target.name;
        let input = e.target.value;
        this.setState({
            [key]: input,
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.showLoader();

        if (this.props.hotelId) {
            let singleBagDoc = DB.collection("countrys")
                .doc(`${this.props.hotelId}`)
                .collection("golfbags").doc(this.props.match.params.idBag)

            singleBagDoc.get().then((peopleBack) => {
                let itemsArray = peopleBack.data().items

                itemsArray[this.props.match.params.idItem] = {
                    name: this.state.name,
                    description: this.state.description,
                    photo: this.state.photo,
                }

                let singleBagDocUpdate = DB.collection("countrys")
                    .doc(`${this.props.hotelId}`)
                    .collection("golfbags").doc(this.props.match.params.idBag)
                singleBagDocUpdate
                    .update({ items: itemsArray })
                    .then((creado) => {
                        this.props.hideLoader();
                        this.props.history.push(`/bags/${this.props.match.params.idBag}`);
                        firebase.succesfullMsg("Item modificado!");
                    });
            })
        }
    }

    render() {
        return (
            <div>
                <Sidebar />
                <ItemsBags
                    submit={this.handleSubmit}
                    input={this.handleInput}
                    inputsOfState={this.state}
                />
                <div>
                    <FullPageLoader />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMoveCommentItemContainer);
