import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Outlets from "../../../restaurant/components/views/Outlets";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const DB = firebase.db;

// let local = JSON.parse(window.localStorage.getItem("persist:lovableLogin"));
// let userLS;
// if (local) {
//   userLS = JSON.parse(local.user);
// }
const mapStateToProps = (state) => {
  return {
    hotelId: state.user.loginUser.hotelId,
    rol: state.user.loginUser.rol,
    isAuth: state.user.isAuth,
    userLogin: state.user.loginUser.hotelId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

class OutletsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: {},
      outlets: [],
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    if (this.props.hotelId) {
      let array = [];
      this.props.showLoader();
      let docOutlets = DB.collection("hoteles")
        .doc(this.props.hotelId)
        .collection("outlets");
      //  .orderBy("orderMenu", "asc");
      docOutlets.get().then((outlets) => {
        outlets.forEach((outlet) => {
          array.push({
            name: outlet.data().name,
            id: outlet.id,
            imageOutlet: outlet.data().imageOutlet,
          });
        });
        this.setState({ outlets: array });
      });
      setTimeout(() => {
        this.props.hideLoader();
      }, 500);
    }
  }

  handleDelete(e, id) {
    e.preventDefault();
    let doc = DB.collection("hoteles")
      .doc(`${this.props.userLogin}`)
      .collection("outlets")
      .doc(id);
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.value) {
        MySwal.fire("Deleted!", `Outlet has been deleted.`, "success");
        doc.delete();
        this.props.history.push(`/dashboard`);
      }
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Outlets
          outlets={this.state.outlets}
          deleteFunc={this.handleDelete}
          rol={this.props.rol}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutletsContainer);
