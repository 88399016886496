import React from "react";
import { Link } from "react-router-dom";
const QRCode = require("qrcode.react");

let restaurantURL;

export default ({
  hotelId,
  hotelInfo,
  quantityRooms,
  addTable,
  deleteTable,
  rol,
}) => {
  if (hotelInfo.qrMask !== "") {
    restaurantURL =
      //"https://lovable-qr.firebaseapp.com/" + hotelId + "/tables";
      "https://" +
      hotelInfo.qrMask +
      ".lovable.solutions/" +
      hotelId +
      "/language/";
  } else {
    restaurantURL =
      "https://hotels.lovable.solutions/" + hotelId + "/language/";
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        marginLeft: "250px",
        marginBottom: "30px",
      }}
    >
      <div
        className="container"
        style={{
          paddingLeft: "35px",
          paddingRight: "35px",
          paddingTop: "20px",
        }}
      >
        <h1 className="font-weight-bold">Configurations</h1>
        {rol === "admin" ? (
          <Link
            style={{
              textDecoration: "none",
              color: "#ffffff",
              backgroundColor: "#ff2068",
              borderColor: "#ff2068",
              marginTop: "20px",
              marginBottom: "10px",
            }}
            className="btn btn-primary"
            to="/configuration/edit"
          >
            Edit Information
          </Link>
        ) : (
          <></>
        )}

        <hr />
        <ul
          className="list-group"
          style={{
            marginTop: "30px",
          }}
        >
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Name:</span>
            <span>{hotelInfo.name}</span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Email:</span>
            <span>{hotelInfo.mail}</span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Phone:</span>
            <span>{hotelInfo.phone}</span>
          </li>

          {hotelInfo.solutionWhatsapp === true ? (
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <span>WhatsApp Number:</span>
              <span>{hotelInfo.whatsappNumber}</span>
            </li>
          ) : (
            <></>
          )}
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Direction:</span>
            <span>{hotelInfo.direction}</span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Password:</span>
            <span>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  backgroundColor: "#000000",
                  borderColor: "#000000",
                }}
                className="btn btn-primary"
                to="/configurations/resetpassword"
              >
                New Password
              </Link>
            </span>
          </li>
        </ul>
        <h3
          style={{
            marginTop: "30px",
          }}
          className="font-weight-bold"
        >
          Texts
        </h3>
        <ul
          className="list-group"
          style={{
            marginTop: "30px",
          }}
        >
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Title App:</span>
            <span>{hotelInfo.titleApp}</span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Subtitle App:</span>
            <span>{hotelInfo.subtitleApp}</span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Marketing Info:</span>
            <span>{hotelInfo.infoApp}</span>
          </li>
        </ul>
        <h3
          style={{
            marginTop: "30px",
          }}
          className="font-weight-bold"
        >
          Design
        </h3>
        <ul
          className="list-group"
          style={{
            marginTop: "30px",
          }}
        >
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Primary Colour:</span>
            <span>{hotelInfo.primaryColor}</span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Logo:</span>
            <img
              style={{
                maxWidth: "100px",
                backgroundColor: "#fff",
              }}
              src={hotelInfo.logoImage}
              alt="category img"
            />
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Background Image:</span>
            <img
              style={{
                maxWidth: "100px",
              }}
              src={hotelInfo.backgroundImage}
              alt="category img"
            />
          </li>
        </ul>

        <h3
          style={{
            marginTop: "30px",
          }}
          className="font-weight-bold"
        >
          QR Code
        </h3>
        <QRCode size="256" includeMargin="true" value={restaurantURL} />
      </div>
    </div>
  );
};
