import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Bags from "../../components/create/Bags";
import { connect } from "react-redux";
import { saveAddMenuQuantity } from "../../../store/actions/hotelAction";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
const DB = firebase.db;

const mapStateToProps = (state) => {
    return {
        isAuth: state.user.isAuth,
        userLogin: state.user.loginUser.hotelId,
        hotelInfoRedux: state.hotel.hotelInfo,
        hotelId: state.user.loginUser.hotelId,
    };
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        saveAddMenuQuantity: (hotel) => dispatch(saveAddMenuQuantity(hotel)),
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
    };
};

class CreateBagsContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            active: true,
            bags: true,
            birthday: "",
            comments: [],
            contactSince: "",
            firstName: "",
            lastName: "",
            mail: "",
            numberSocio: "",
            password: "",
            phone: "",
            rol: "owner",

            brand: "",
            history: [],
            owner: "",
            lastMove: "",
            type: "bag",
            items: [
                {
                    description: "",
                    name: "Driver",
                    photo: ""
                },
                {
                    description: "",
                    name: "Madera 3",
                    photo: ""
                },
                {
                    description: "",
                    name: "Híbrido",
                    photo: ""
                },
                {
                    description: "",
                    name: "Hierro 4",
                    photo: ""
                },
                {
                    description: "",
                    name: "Hierro 5",
                    photo: ""
                },
                {
                    description: "",
                    name: "Hierro 6",
                    photo: ""
                },
                {
                    description: "",
                    name: "Hierro 7",
                    photo: ""
                },
                {
                    description: "",
                    name: "Hierro 8",
                    photo: ""
                },
                {
                    description: "",
                    name: "Hierro 9",
                    photo: ""
                },
                {
                    description: "",
                    name: "Hierro P",
                    photo: ""
                },
                {
                    description: "",
                    name: "Hierro A",
                    photo: ""
                },
                {
                    description: "",
                    name: "Wedge 1",
                    photo: ""
                },
                {
                    description: "",
                    name: "Wedge 2",
                    photo: ""
                },
                {
                    description: "",
                    name: "Putter",
                    photo: ""
                },
            ],

        };
        this.handleInput = this.handleInput.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInput(e) {
        let key = e.target.name;
        let input = e.target.value;
        this.setState({
            [key]: input,
        });
    }

    handleClick(info1) {
        let arrayOfItems = this.state.items;
        let found = arrayOfItems.findIndex(
            (element) =>
                element.name === info1
        );
        if (found !== -1) {
            arrayOfItems.splice(found, 1);
            this.setState({ items: arrayOfItems })
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.showLoader();

        let year = new Date().getFullYear()
        let month = new Date().getMonth() + 1
        const date = new Date().getDate()
        let hours = new Date().getHours()
        let minutes = new Date().getMinutes()
        let contactSinceInfo = (date + "/" + month + "/" + year + " " + hours + ":" + minutes)
        this.setState({ contactSince: contactSinceInfo })

        if (this.props.hotelId) {
            DB.collection("countrys")
                .doc(`${this.props.hotelId}`)
                .collection("people").add({
                    active: true,
                    bags: true,
                    birthday: "",
                    comments: [],
                    contactSince: this.state.contactSince,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    mail: this.state.mail,
                    numberSocio: this.state.numberSocio,
                    password: "",
                    phone: this.state.phone,
                    rol: "owner",
                }).then((docRef) => {
                    DB.collection("countrys")
                        .doc(`${this.props.hotelId}`)
                        .collection("golfbags").add({
                            active: true,
                            brand: this.state.brand,
                            comments: [],
                            fullName: `${this.state.firstName} ${this.state.lastName}`,
                            history: [],
                            items: this.state.items,
                            lastMove: "",
                            numberSocio: this.state.numberSocio,
                            owner: docRef.id,
                            type: "bag",
                        }).then((creado) => {
                            this.props.hideLoader();
                            this.props.history.push("/bags");
                            firebase.succesfullMsg("Bolsa creada!");
                        });
                })
        }
    }

    render() {
        return (
            <div>
                <Sidebar />
                <Bags
                    submit={this.handleSubmit}
                    input={this.handleInput}
                    stateItems={this.state.items}
                    handleClick={this.handleClick}
                />
                <div>
                    <FullPageLoader />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBagsContainer);
