import React from "react";
//import { Link } from "react-router-dom";

const styles = {
  container: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    // backgroundImage: `url(${Background})`,
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    backgroundColor: "white",
  },
  top: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    paddingLeft: "30px",
    backgroundColor: "white",
    borderRadius: "0 0 0 60px",
    //backgroundColor: "green",
    borderBottom: "1px solid rgb(220,220,220,0.9)",
  },
  //   logoSection: {
  //     flex: 2,
  //     display: "flex",
  //     justifyContent: "center",
  //     flexFlow: "column",
  //     alignItems: "center",
  //     backgroundColor: "white",
  //     borderRadius: "40px 40px 0 0",

  //     border: "2px solid white",
  //     // paddingBottom: "50px",
  //   },
  chat: {
    flex: 8,
    //display: "flex",
    padding: "20px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    //justifyContent: "flex-start",
    //alignItems: "flex-start",
    flexWrap: "wrap",
    //margin: "0 30px 0 30px",
    backgroundColor: "white",

    overflow: "auto",
  },
  roomTitle: {
    margin: 0,
    fontSize: 45,
    fontWeight: "bold",
    textAlign: "center",
    color: "#000",
  },
  roomSubtitle: {
    margin: 0,
    marginTop: "10px",
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
    color: "#000",
  },
  chatCustomer: {
    margin: 0,
    marginLeft: "50px",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "1.4",
    textAlign: "left",

    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#ff2068",
    color: "white",
    borderRadius: "30px 0 0 30px",
    padding: "20px",
  },
  chatHotel: {
    //margin: 20,
    marginTop: "10px",
    marginRight: "50px",
    fontSize: 16,
    lineHeight: "1.4",
    fontWeight: 400,
    textAlign: "left",
    backgroundColor: "grey",
    color: "white",
    borderRadius: "0 30px 30px 0",
    padding: "20px",
  },

  textSection: {
    flex: 1,
    display: "flex",
  },
  input: {
    flex: 9,
    borderRadius: "0px",
    borderColor: "#ffffff",
    color: "black",
    //  display: "flex",
  },

  button: {
    flex: 3,
    backgroundColor: "#FF2068",
    borderColor: "#FF2068",
    borderRadius: "40px 0 0 40px",
    fontWeight: "400",
    fontSize: 12,
  },
};

export default ({
  handleClick,
  room,
  hotel,
  propsOfHotelId,
  propsOfRoomId,
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.top}>
        {/* {restaurant.backgroundImage ? (
          <img src={restaurant.logoImage} height="50" alt="Logo" />
        ) : (
          <img
            src="https://insideone.s3-sa-east-1.amazonaws.com/logo-lovable.png"
            height="50"
            alt="Logo"
          />
        )} */}
        <h1 style={styles.roomTitle}>Mensajes</h1>
        <h6 style={styles.roomSubtitle}>Para ayudarte con tus consultas.</h6>
      </div>
      <div style={styles.chat}>
        <h1 style={styles.chatCustomer}>
          Hola! Qué restaurantes hay para comer?
        </h1>
        <h6 style={styles.chatHotel}>
          Hola Alex! Podés visitar nuestro Resturante Gourmet o nuestra
          cafetería. Avisanos si queres reservar.
        </h6>
        <h1 style={styles.chatCustomer}>Gracias!</h1>
        <h6 style={styles.chatHotel}>Estamos para ayudarte.</h6>
        <h1 style={styles.chatCustomer}>
          Me gustaría reservar. A qué hora puedo?
        </h1>
        <h6 style={styles.chatHotel}>A las 20:30?</h6>
      </div>
      <form
        style={styles.textSection}
        //style={styles.form}
        // onSubmit={(e) => handlerSubmit(e)}
      >
        <input
          name="numberOfRoom"
          type="text"
          className="form-control"
          id="numberOfRoom"
          style={styles.input}
          placeholder="Escribinos..."
          //onChange={handlerChange}
        />

        <button type="submit" className="btn btn-primary" style={styles.button}>
          ENVIAR
        </button>
      </form>
    </div>
  );
};
