import React from "react";
import firebase from "../../../services/firebase";
import { connect } from "react-redux";
import {
    saveHotelId,
} from "../../../store/actions/hotelAction";
import ScreenBags from "../../components/views/ScreenBags";
const DB = firebase.db;

const mapStateToProps = (state) => {
    return {
        hotelId: state.user.loginUser.hotelId,
        rol: state.user.loginUser.rol,
        name: state.user.loginUser.name,
        isAuth: state.user.isAuth,
        userLogin: state.user.loginUser.hotelId,
        rooms: state.hotel.rooms,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
    };
};


class ScreenBagsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            actualBag: {},
            items: []
        };
    }

    componentDidMount() {
        if (this.props.hotelId) {
            let newsDoc = DB.collection("countrys").doc(`${this.props.hotelId}`).collection("moveBags").where('status', '==', `pending`)
            //.orderBy('realDate', 'desc')
            newsDoc.onSnapshot((peopleBack) => {
                this.setState({ actualBag: {} })
                peopleBack.forEach((people) => {
                    // console.log(people.data())
                    this.setState({ actualBag: people.data() }, () => {
                        let itemsDoc = DB.collection("countrys").doc(`${this.props.hotelId}`).collection("golfbags").doc(`${this.state.actualBag.idBag}`)
                        itemsDoc.get().then((person) => {
                            this.setState({ items: person.data().items });
                        });
                    })
                });
            })
        }
    }

    render() {
        return (
            <ScreenBags
                loader={this.state.loader}
                name={this.props.name}
                news={this.state.actualBag}
                items={this.state.items}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenBagsContainer);
