import {
  SET_OUTLETS_INFO,
  SET_MENUES_INFO,
  SET_CATEGORIES_INFO,
  SET_PRODUCTS_INFO,
  SET_CATEGORIES_HISTORY,
  SET_CATEGORIES_BACKUP,
} from "../reducers/constant";

const initialState = {
  outlets: [],
  menues: [],
  categories: [],
  categoriesBackup: [],
  products: [],
  categoriesHistory: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_OUTLETS_INFO:
      return { ...state, outlets: action.menuesData };
    case SET_MENUES_INFO:
      return { ...state, menues: action.menuesData };
    case SET_CATEGORIES_INFO:
      // return { ...state, categories: action.categoryData };
      return {
        ...state,
        categories: [...state.categories, action.categoryData],
      };

    case SET_CATEGORIES_BACKUP:
      // return { ...state, categories: action.categoryData };
      return {
        ...state,
        categoriesBackup: [...state.categoriesBackup, action.categoryData],
      };
    case SET_PRODUCTS_INFO:
      //return { ...state, products: action.productData };
      return {
        ...state,
        products: [...state.products, action.productData],
      };
    case SET_CATEGORIES_HISTORY:
      // return { ...state, categoriesHistory: action.categoryHistory };
      return {
        ...state,
        categoriesHistory: [...state.categoriesHistory, action.categoryHistory],
      };
    default:
      return state;
  }
};
