import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const styles = {
    container: {
        backgroundColor: "white",
        marginLeft: "250px",
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingTop: "20px",
    },
    containerFlex: {
        marginLeft: "250px",
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        justifyContent: "center",
        alignItems: "center",
    },
    titleAnalytic: {
        color: "#000",
        //fontWeight: "bold",
        textAlign: "center",
        fontSize: "35px",
        fontFamily: "LatoBlack",
    },
    titleDashboard: {
        color: "#000",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "25px",
        margin: 0,
    },
    descriptionAnalytic: {
        color: "#000",
        textAlign: "center",
        //fontWeight: "400",
        marginBottom: "0px",
        fontFamily: "Lato",
    },
    shadow: {
        boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
    },
    textLatoBlack: {
        fontFamily: "LatoBlack",
        margin: 0,
        fontSize: 55,
        color: "#008477"
    },
    textLato: {
        fontFamily: "Lato",
    },
    buttonScreen: {
        backgroundColor: "#fff",
        border: "1px #ff2068 solid",
        padding: "8px 30px 8px 65px",
        color: "#ff2068",
        borderRadius: "25px",
        margin: 10,
        fontSize: 18,
        fontFamily: "Lato",
        width: '250px',
        display: "flex",
        alignItems: "center",
        // justifyContent: 'center'
    },
    icons: {
        marginRight: "20px",
    },
    propertiesContainer: {
        display: "flex",
        flexFlow: "column",
        flexWrap: 'wrap',
        justifyContent: "flex-start",
        alignItems: "flex-start",
        backgroundColor: "#fff",
        //   overflow: "auto",
        width: '50%',
        height: '70vh'
    },
    imageCover: {
        display: "flex",
        margin: 0,
        padding: "0px 15px 0px 0px",
        height: "50px",
        objectFit: "cover",
    },
    infoTitle: {
        display: "flex",
        width: "100%",
        margin: "0px 0px 25px 0px",
        flexDirection: "column"
    },
    newsTitle2: {
        margin: 0,
        fontSize: 20,
        padding: "10px 0px 0px 0px",
        fontFamily: "LatoBold",
        //  width: "40%",
    },
    newsDescription2: {
        margin: 0,
        fontSize: 18,
        padding: "10px 0px",
        fontFamily: "Lato",
        //   width: "40%",
    },
    info: {
        display: "flex",
        width: "100%",
        margin: 0,
        flexDirection: "column"
    },
    newsTitle: {
        margin: 0,
        fontSize: 20,
        padding: "0px 15px 0px 15px",
        fontFamily: "LatoBold",
        //  width: "40%",
    },
    newsDescription: {
        margin: 0,
        fontSize: 16,
        padding: "10px 15px 0px 15px",
        fontFamily: "Lato",
        //   width: "40%",
    },
};

export default ({ news, items
}) => {
    return (
        <>
            {news.brand ? (<div>
                <div
                    className="row align-items-center"
                    style={{
                        backgroundColor: "#ffffff",
                        margin: "0px",
                    }}
                >
                    <div
                        className="col"
                        style={{
                            padding: "0px",
                            margin: "0px",
                        }}
                    >
                        <div
                            style={{
                                padding: "50px 80px 0px 80px",
                                display: "flex",
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                //  flex: '12',
                                height: "100vh"
                            }}
                        >
                            {news.type === "bag" ? (<h1 style={styles.textLatoBlack}>Bolsa {news.brand}</h1>) : (<h1 style={styles.textLatoBlack}>Carro {news.brand}</h1>)}

                            <div style={styles.infoTitle}>
                                <h4 style={styles.newsTitle2}>
                                    {`${news.fullName} (${news.numberSocio})`}
                                </h4>
                                <h4 style={styles.newsDescription2}>
                                    {`${items.length} palos`}
                                </h4>
                            </div>

                            <div style={styles.propertiesContainer}>
                                {items
                                    ? items.map((singleNew) => (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    width: "100%",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "25px",
                                                    margin: "10px 0px",
                                                    textDecoration: "none",
                                                    color: "inherit",
                                                }}
                                            >
                                                {singleNew.photo !== "" ? (<img
                                                    style={styles.imageCover}
                                                    src={singleNew.photo}
                                                    alt="..."
                                                />) : (
                                                    <img
                                                        style={styles.imageCover}
                                                        src="https://i.postimg.cc/ZqdN2Pr7/golfstick-green.png"
                                                        alt="..."
                                                    />
                                                )}
                                                <div style={styles.info}>
                                                    <h4 style={styles.newsTitle}>
                                                        {`${singleNew.name}`}
                                                    </h4>
                                                    <h4 style={styles.newsDescription}>
                                                        {`${singleNew.description}`}
                                                    </h4>
                                                </div>
                                            </div>

                                        </>
                                    ))
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div
                        className="col"
                        style={{
                            width: "100%",
                            //height: "1020px",
                            margin: 0,
                            padding: 0,
                            height: "100vh",
                            backgroundImage: `url(${"https://i.postimg.cc/YSwMT0xd/background-mygolf.jpg"})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}
                    >
                        <img
                            src="https://i.postimg.cc/9M91vgg5/Artboard-1.png"
                            alt="..."
                            //  height="22"
                            style={{
                                height: "10%",
                                width: "auto",
                                position: "relative",
                                left: "10%",
                                top: "80%",
                            }}
                        />
                    </div>
                </div>
            </div>) : (
                <div
                    style={{
                        width: "100%",
                        margin: 0,
                        padding: 0,
                        height: "100vh",
                        backgroundImage: `url(${"https://i.postimg.cc/B6b3YNWk/prueba-1.jpg"})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <img
                        src="https://i.postimg.cc/9M91vgg5/Artboard-1.png"
                        alt="..."
                        style={{
                            height: "10%",
                            width: "auto",
                            position: "relative",
                            left: "5%",
                            top: "80%",
                        }}
                    />
                </div>
            )}
        </>
    );
};
