import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import News from "../../components/edit/News";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const DB = firebase.db;
const MySwal = withReactContent(Swal);

const mapStateToProps = (state) => {
    return {
        isAuth: state.user.isAuth,
        userLogin: state.user.loginUser.hotelId,
        hotelInfoRedux: state.hotel.hotelInfo,
        hotelId: state.user.loginUser.hotelId,
    };
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
    };
};

class EditNewsContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            title: "",
            description: "",
            image: "",

            newsArray: [],
            idNewsArray: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.showLoader();

        if (this.props.hotelId) {
            let churchDoc = DB.collection("countrys")
                .doc(`${this.props.hotelId}`)
                .collection("news")

            churchDoc.get().then((peopleBack) => {
                let peopleArray = [];
                peopleBack.forEach((people) => {
                    peopleArray = people.data().news
                    this.setState({ idNewsArray: people.id })
                });
                this.setState({
                    newsArray: peopleArray,
                    title: peopleArray[this.props.match.params.idNew].title,
                    description: peopleArray[this.props.match.params.idNew].description,
                    image: peopleArray[this.props.match.params.idNew].image,
                }, () => this.props.hideLoader());
            })
        }
    }

    handleInput(e) {
        let key = e.target.name;
        let input = e.target.value;
        this.setState({
            [key]: input,
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.showLoader();

        if (this.props.hotelId) {
            let newsDoc = DB.collection("countrys")
                .doc(`${this.props.hotelId}`)
                .collection("news").doc(this.state.idNewsArray)

            let newsArrayFromState = this.state.newsArray

            newsArrayFromState[this.props.match.params.idNew] = {
                title: this.state.title,
                description: this.state.description,
                image: this.state.image,
            }
            //    this.state({newsArray:newsArrayFromState})
            newsDoc.update({ news: newsArrayFromState })
                .then((actualizado) => {
                    this.props.hideLoader()
                    this.props.history.push(`/news-admin`);
                    firebase.succesfullMsg("Novedad actualizada!");
                });
        }
    }

    handleDelete(e) {
        MySwal.fire({
            title: "¿Estás seguro?",
            text: "Esta acción no se puede revertir.",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                if (this.props.hotelId) {
                    let newsDoc = DB.collection("countrys")
                        .doc(`${this.props.hotelId}`)
                        .collection("news").doc(this.state.idNewsArray)

                    let newsArrayFromState = this.state.newsArray
                    newsArrayFromState.splice(this.props.match.params.idNew, 1);

                    newsDoc.update({ news: newsArrayFromState })
                        .then((actualizado) => {
                            this.props.hideLoader()
                            this.props.history.push(`/news-admin`);
                            firebase.succesfullMsg("Novedad eliminada!");
                        });
                }
            }
        });
    }

    render() {
        return (
            <div>
                <Sidebar />
                <News submit={this.handleSubmit} input={this.handleInput} state={this.state} handleDelete={this.handleDelete} />
                <div>
                    <FullPageLoader />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditNewsContainer);
