import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//const Background =
// "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbackground-app.png?alt=media&token=18234069-f4d0-46c7-b98e-6978e6ef68e3";

const styles = {
  topCenter: {
    flex: 4,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "center",
    // marginLeft: "30px",
    //  backgroundColor: "green",
  },

  center: {
    flex: 6,
    display: "flex",
    justifyContent: "center",
    // flexFlow: "column",
    alignItems: "center",
    // marginLeft: "30px",
    //  backgroundColor: "green",
  },

  marketingTitle: {
    margin: 40,

    fontSize: 45,
    fontWeight: 400,
    textAlign: "center",
    color: "#ffffff",

    // fontSize: 55,
    fontFamily: "LatoBold",
    // backgroundColor: "#ff2068",
  },

  buttonsBlack: {
    flex: 6,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    //flexWrap: "wrap",
    //margin: "0 30px 0 30px",
    backgroundColor: "#fff",
    borderRadius: "50px 50px 0 0",
    // paddingTop: "10px",
    //  backgroundColor: "grey",
  },

  buttonBlack: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "70vw",
    backgroundColor: "#fff",
    borderRadius: "30px 0 0 30px",
    marginTop: "0px",
    marginBottom: "30px",
  },

  buttonsWeb: {
    flex: 7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    inherit: "none",
  },

  buttonWeb: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width: "25%",
    // height: "50%",
    width: "250px",
    height: "250px",
    // width: "20vw",
    // height: "20vw",
    backgroundColor: "#fff",
    borderRadius: "150px",
    margin: "20px",
    // marginBottom: "20px",
  },

  textTitle: {
    margin: 0,
    fontSize: 38,
    //fontWeight: 900,
    textAlign: "center",
    color: "#000",
    padding: "15px",
    inherit: "none",
    fontFamily: "LatoBlack",
  },
  textSubtitle: {
    margin: 0,
    fontSize: 18,
    //fontWeight: 900,
    textAlign: "center",
    color: "#000",
    paddingTop: "10px",
    paddingBottom: "20px",
    inherit: "none",
    fontFamily: "Lato",
  },
  // textButton: {
  //   margin: 0,
  //   fontSize: 25,
  //   //fontWeight: 900,
  //   textAlign: "center",
  //   color: "#000",
  //   padding: "15px",
  //   inherit: "none",
  //    fontFamily: "LatoBlack",
  // },
  buttonFloat: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "50px",
    //backgroundColor: "green",
  },
  confetti: {
    flex: 12,
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
    width: "265px",
    margin: 0,
    fontSize: 18,
    color: "#fff",
    padding: "20px 40px",
    // fontFamily: "LoewMedium",
    fontFamily: "LatoBold",
  },
};

export default ({
  handleClick,
  loader,
  screenSize,
  confetti
}) => {
  return (
    <>
      {loader == false ? (
        <>
          {screenSize > 600 ? (<></>
          ) : (
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  top: 0,
                  left: 0,
                  right: 0,
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  backgroundImage: `url("https://i.postimg.cc/GpvGjQs8/splash-background-01.jpg")`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                {/* <div style={styles.topCenter}>
                <img
                  src="https://i.postimg.cc/4dbvX5fk/logo-ipa.png"
                  height="75"
                  alt="Logo"
                />
              </div> */}

                <div style={styles.center}>
                  <p style={styles.marketingTitle}>Algo nuevo está llegando!</p>
                </div>

                <>
                  <div style={styles.buttonsBlack}>
                    <h1 style={styles.textTitle}>¡Excelente!</h1>
                    <h1 style={styles.textSubtitle}>Ya has creado tu usuario.</h1>

                    {confetti === false ? (<Link
                      // key={singleNew.id}
                      style={{
                        display: "flex",
                        //height: "231px",
                        // width: "90%",
                        justifyContent: 'center',
                        alignItems: "center",
                        borderRadius: "25px",
                        margin: "20px",
                        backgroundColor: "#339AE7",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      // to={`/news/${singleNew.id}/`}
                      onClick={() => handleClick()}
                    >
                      <h4 style={styles.confetti}>
                        A festejar  😊 🎉
                                </h4>
                    </Link>) : (<Link
                      // key={singleNew.id}
                      style={{
                        display: "flex",
                        //height: "231px",
                        // width: "90%",
                        justifyContent: 'center',
                        alignItems: "center",
                        borderRadius: "25px",
                        margin: "20px",
                        backgroundColor: "#339AE7",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      // to={`/news/${singleNew.id}/`}
                      onClick={() => handleClick()}
                    >
                      <h4 style={styles.confetti}>
                        Frenar confetti 😔
                                </h4>
                    </Link>)}




                  </div>
                </>
              </div>
            )}
        </>
      ) : (
          <></>
        )}
    </>
  );
};
